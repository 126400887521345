import {Component} from '@angular/core';
import {DataSourceTable} from "@components/new-table/data-sources/data-source-table";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {InsuranceService} from "@services/data-source-tables/insurances/insurance.service";
import {InsuranceTableSettings} from "@/table-settings/insurances/insurance-table-settings";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {DialogNewInsuranceComponent} from "@pages/insurances/dialog-new-insurance/dialog-new-insurance.component";
import {InsuranceModel} from "@/models/insurances/insurance.model";

@Component({
  selector: 'app-insurances-page-list',
  templateUrl: './insurances-page-list.component.html',
  styleUrls: ['./insurances-page-list.component.scss']
})
export class InsurancesPageListComponent {
  displayedColumns = InsuranceTableSettings.getColumnsSetting()
  dataSource = new DataSourceTable(this.insuranceService);
  reloadTrigger$ = new Subject<any>();
  private dialogNewInsurance: MatDialogRef<DialogNewInsuranceComponent>;

  constructor(private insuranceService: InsuranceService, private router: Router, private toastr: ToastrService, private dialog: MatDialog) {
  }

  btnDetailsClickFunction({id}: TablePracticeModel) {
    this.router.navigate([`/insurances/detail-insurance/${id}`])
  }

  btnDeleteFunction({id: id}: RentalPracticeModel) {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deleteInsurance => {
      if (deleteInsurance) {
        this.insuranceService.deleteInsurance(id).subscribe({
          next: () => {
            this.reloadTrigger$.next(1);
            this.toastr.success('Assicurazione cancellata correttamente!');
          },
          error: (error) => {
            this.toastr.error(`Errore durante la cancellazione dell'assicurazione.`);

            LoggerService.error('deleteInsurance failed!')
            LoggerService.error(error);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questa assicurazione?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellato l'assicurazione non sarà più possibile recuperarla."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }

  newInsurance() {
    this.dialogNewInsurance = this.dialog.open(DialogNewInsuranceComponent, {
      data: {insurance: new InsuranceModel(), dialogRef: this.dialogNewInsurance},
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.dialogNewInsurance.afterClosed().subscribe({
      next: () => {
        this.reloadTrigger$.next();
      }
    });
  }
}
