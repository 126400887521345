<h1 mat-dialog-title class="text-dark text-xl">Modifica Permuta</h1>
<div mat-dialog-content class="w-full">
  <h2 mat-dialog-title class="text-dark text-lg mb-2">
    {{ data.pratica.tipoPratica }} -
    {{ data.pratica.codicePratica }} -
    {{ data.pratica.cliente }} -
    {{ data.pratica.venditore?.codiceVenditore ?? 'Codice Venditore non configurato' }} -
    {{ data.pratica.venditore?.descrizione ?? 'Descrzione non configurata' }}
  </h2>
  <app-exchange-form [data]="data"></app-exchange-form>
</div>
