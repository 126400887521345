import {Component, inject} from '@angular/core';
import {ImportFlowService} from "@services/imports/import-flow.service";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {VhmReportDataSource} from "@components/new-table/data-sources/vhm-report.dataSource";
import {VhmReportSettingsTable} from "@/table-settings/imports/vhm-report-settings-table";

@Component({
  selector: 'app-vhm-report-table',
  templateUrl: './vhm-report-table.component.html',
  styleUrls: ['./vhm-report-table.component.scss']
})
export class VhmReportTableComponent {

  displayedColumns: TableColumnsSetting<any>

  dataSource = new VhmReportDataSource(this.importFlowService);

  toastr = inject(ToastrService);
  dialog = inject(MatDialog);

  reloadTrigger$ = new Subject<any>();

  constructor(private importFlowService: ImportFlowService) {
    this.displayedColumns = VhmReportSettingsTable.getColumnsSetting();
  }
}
