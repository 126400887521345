import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PracticeModel} from "@/models/practice.model";
import {PracticesService} from "@services/practices.service";
import {DialogRef} from "@angular/cdk/dialog";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-dialog-migrate-practice-component',
  templateUrl: './dialog-migrate-practice.component.html',
})
export class DialogMigratePracticeComponent {
  practiceId: string = '';
  selectedPractice: PracticeModel | null = null;
  practiceNotFound: boolean = false;
  protected loadingMigration: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { practice: PracticeModel, dialogRef: DialogRef },
    private dialogRef: MatDialogRef<DialogMigratePracticeComponent>,
    protected practiceService: PracticesService,
    protected toastr: ToastrService
  ) {
  }

  searchPractice() {
    if (!this.data.practice.marca.tipoVeicolo && !this.practiceId) return;
    
    this.practiceService.getPracticeByPracticeId(this.data.practice.marca.tipoVeicolo, this.practiceId).subscribe({
      next: (practice) => {
        this.selectedPractice = practice;
        this.practiceNotFound = false;
      },
      error: () => {
        this.selectedPractice = null;
        this.practiceNotFound = true;
      }
    });
  }

  migratePractice() {
    if (!this.selectedPractice) return;
    this.loadingMigration = true;

    this.practiceService.migratePractice(Number(this.data.practice.id), this.selectedPractice.id).subscribe({
      next: () => {
        this.toastr.success('Pratica migrata!');

        this.loadingMigration = false;
        this.closePopup();
      },
      error: () => {
        this.toastr.success('Migrazione dati fallita!');

        this.loadingMigration = false
      }
    });
  }

  closePopup() {
    this.dialogRef.close();
  }
}
