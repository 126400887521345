import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import moment from "moment";
import {Moment} from "moment/moment";
import {MatDatepicker} from "@angular/material/datepicker";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {YEAR_MONTH_FORMATS} from "@/date-picker-formats";
import {DateControl} from "@components/dynamic-form/date-control";

@Component({
  selector: 'app-year-month-date-picker-input',
  templateUrl: './year-month-date-picker-input.component.html',
  styleUrls: ['./year-month-date-picker-input.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: YEAR_MONTH_FORMATS},
  ],
})
export class YearMonthDatePickerInputComponent extends InputFieldComponent implements OnInit {
  @Input() meta!: DateControl
  @Input() form!: FormGroup
  @Input() max: any;
  @Input() indexRow: number = null
  @Input() keyformArrayName: any
  today: Date = new Date();
  protected control: AbstractControl<any>;

  constructor(private dateAdapter: DateAdapter<Date>) {
    super();
  }

  get controlArray() {
    return this.form.controls[this.keyformArrayName] as FormArray;
  }

  ngOnInit(): void {
    this.setControl();

    const date = this.form.controls[this.meta.key].value;
    if (date) {
      this.form.controls[this.meta.key].setValue(moment(date));
    }
  }

  setMonthAndYear(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.form.controls[this.meta.key].value || moment();
    ctrlValue.year(normalizedMonth.year());
    ctrlValue.month(normalizedMonth.month());
    this.form.controls[this.meta.key].setValue(ctrlValue);

    console.log('CLOSE DATE PICKER')
    datepicker.close();
  }
}
