import {Component, Input, OnChanges} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {UsedVehicleService} from "@services/used-vehicle.service";
import {ToastrService} from "ngx-toastr";
import {UsedVehicleModel} from "@/models/usedVehicleModel";
import {UsedVehicleFormSetting} from "@/form-settings/used-vehicle-form-setting";
import {OptionSelect} from "@/models/option-select.model";
import {BrandModel} from "@/models/tables/brand.model";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {DynamicFormSubmitInterface} from "@components/dynamic-form/dynamic-form-submit-interface";
import {Subject} from "rxjs";

@Component({
  selector: 'app-used-vehicle-form',
  templateUrl: '../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../components/dynamic-form/dynamic-form.component.scss']
})
export class UsedVehicleFormComponent extends DynamicFormComponent implements DynamicFormPopUpInterface, DynamicFormSubmitInterface, OnChanges {
  @Input() isNew: boolean;
  @Input() closeDialogEvent: Subject<any>;
  @Input() refreshUsedVehicleTable: Subject<any>;

  protected brands: OptionSelect[] = [];
  private usedVehicleId: any;

  constructor(
    private route: ActivatedRoute,
    protected toastr: ToastrService,
    protected router: Router,
    protected userService: UserService,
    protected usedVehicleService: UsedVehicleService,
  ) {
    super(toastr, userService)
    this.data = new UsedVehicleModel()
    this.primaryButton = `Salva veicolo usato`
    this.setupMeta()

    this.route.params.subscribe((params: Params) => {
      this.usedVehicleId = params['id'];
    })

    this.getBrands()

    if (this.usedVehicleId != null) {
      this.usedVehicleService.getUsedVehicleById(this.usedVehicleId).subscribe({
        next: (usedVehicle) => {
          this.data = <UsedVehicleModel>usedVehicle

          this.refreshForm()
        },
        error: (error) => {
          LoggerService.error('getUsedVehicleById failed!');
          LoggerService.error(error);
        },
        complete: () => LoggerService.log('Request done'),
      });
    }

    Registry.getInstance().set('UsedVehicleFormComponent', this)
  }

  setupMeta() {
    this.meta = UsedVehicleFormSetting
      .setIsNew(this.isNew)
      .setBrands(this.brands)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  onSubmit() {
    this.submitButtonLoading = true
    let usedVehicle = <UsedVehicleModel>this.form.getRawValue()

    this.usedVehicleService.saveUsedVehicle(usedVehicle).subscribe({
      next: (response) => {
        this.toastr.success('Veicolo Usato salvato correttamente!');

        this.refreshUsedVehicleTable?.next(1)
        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('saveUsedVehicle failed!');
        LoggerService.error(error);

        this.toastr.error('Errore nel salvataggio!');

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
          this.submitButtonLoading = false
        }, 1000)
      },
    })
  }

  bottomButtonAction(): void {
    this.closeDialogEvent?.next(1)
  }

  private getBrands() {
    this.usedVehicleService.getBrands().subscribe((brands: BrandModel[]) => {
      this.brands = []

      for (let brand of brands) {
        this.brands.push({
          'key': brand,
          'value': brand.codiceMarca + ' - ' + brand.descrizioneMarca
        })
      }

      this.refreshForm()
    })
  }

}
