export enum ControlType {
  repeater = 'repeater',
  date = 'date',
  dateTime = 'dateTime',
  dualList = 'dual-list',
  textInput = 'textinput',
  textArea = 'textArea',
  autocomplete = 'autocomplete',
  slideToggle = 'slide-toggle',
  password = 'password',
  dropdown = 'dropdown',
  hidden = 'hidden',
  number = 'number',
  multiSelect = 'multiselect',
  uploadFile = 'uploadFile',
  telephone = 'telephone',
  email = 'email',
  currency = 'currency',
  yearDate = 'yearDate',
  monthDate = 'monthDate',
  monthYearDate = 'monthYearDate',
  autocompleteRentalPractice = 'autocomplete-rental-practice'
}
