class FileInsuranceModel {
  dateCreated: string;
  creator: string;
  id: number;
  length: number;
  name: string;
  relativePath: string;
}

export class InsuranceModel {
  id: number;
  rc: string;
  ifk: string;
  pai: string;
  compagnia: string;
  numeroPolizza: string;
  dataInizio: string;
  dataFine: string;
  stato: string;
  tipoAssicurazione: string;
  file: FileInsuranceModel;
}
