import {TableColumnsSetting} from "@/interfaces/table-setting";
import {VhmProFormaModel} from "@/models/imports/vhm-pro-forma-model";

export class VhmReportSettingsTable {

  public static getColumnsSetting(): TableColumnsSetting<keyof VhmProFormaModel> {
    return [
      {
        name: "id",
        text: "Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "importId",
        text: "Import Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "cliente",
        text: "Cliente",
        filter: {
          type: "input",
        }
      },
      {
        name: "numeroOrdine",
        text: "Num. Ordine",
        filter: {
          type: "number",
        }
      },
      {
        name: "numeroContratto",
        text: "Num. Contratto",
        filter: {
          type: "number",
        }
      },
      {
        name: "canale",
        text: "Canale",
        filter: {
          type: "input",
        }
      },
      {
        name: "telaio",
        text: "Telaio",
        filter: {
          type: "input",
        }
      },
      {
        name: "marca",
        text: "Marca",
        filter: {
          type: "input",
        }
      },
      {
        name: "modello",
        text: "Modello",
        filter: {
          type: "input",
        }
      },
      {
        name: "dataImmatricolazione",
        type: "datetime",
        text: "Data immatricolazione",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "dataConsegna",
        type: "datetime",
        text: "Data consegna",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "dataCaricamentoCoc",
        type: "datetime",
        text: "Data caricamento COC",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "nomeRitirante",
        text: "Nome ritirante",
        filter: {
          type: "input",
        }
      },
      {
        name: "cognomeRitirante",
        text: "Cognome ritirante",
        filter: {
          type: "input",
        }
      },
      {
        name: "cfRitirante",
        text: "Cod. Fisc. Ritirante",
        filter: {
          type: "input",
        }
      },
      {
        name: "createdAt",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "updatedAt",
        type: "datetime",
        text: "modificato il",
        filter: {
          type: 'dateRange'
        }
      }
    ];
  }
}
