import {CustomColumnModelInterface, TableColumnsSetting} from "@/interfaces/table-setting";
import {InsuranceModel} from "@/models/insurances/insurance.model";
import {AssociationsInsuranceRentPracticeModel} from "@/models/insurances/associations-insurance-rent-practice-model";

export class ConnectionsInsuranceRentPractice {
  public static getColumnsSetting(): TableColumnsSetting<keyof InsuranceModel> {
    return [
      this.getCustomColumnViewDetail(),
      this.getCustomColumnModifyAssociation(),
      this.getCustomColumnPause(),
      this.getCustomColumnActive(),
      {
        name: "stato",
        text: 'Stato',
      },
      {
        name: "tipoPratica",
        text: 'Tipo Pratica',
      },
      {
        name: "codicePratica",
        text: 'Codice Pratica',
      },
      {
        name: "targa",
        text: 'Targa',
      },
      {
        name: "numApplicazione",
        text: "Num. applicazione"
      },
      {
        name: "denominazioneUtilizzatore",
        text: "Utilizzatore"
      },
      {
        name: "dataInizio",
        text: 'Data Inizio',
        type: "date"
      },
      {
        name: "dataFine",
        text: 'Data Fine',
        type: "date"
      },
      {
        name: "premioNetto",
        text: 'Premio Netto',
        type: "currency"
      }
    ];
  }

  private static getCustomColumnPause(): CustomColumnModelInterface {
    return {
      name: "btnPause",
      index: 2,
      customButton: true,
      unsortable: true,
      condition: (element: AssociationsInsuranceRentPracticeModel) => element.stato === 'ATTIVA'
    };
  }

  private static getCustomColumnActive(): CustomColumnModelInterface {
    return {
      name: "btnActive",
      index: 3,
      customButton: true,
      unsortable: true,
      condition: (element: AssociationsInsuranceRentPracticeModel) => element.stato === 'SOSPESA' || element.stato === 'BOZZA'
    };
  }

  private static getCustomColumnViewDetail(): CustomColumnModelInterface {
    return {
      name: "btnViewDetail",
      index: 1,
      customButton: true,
      unsortable: true,
      condition: (element: AssociationsInsuranceRentPracticeModel) => true
    };
  }

  private static getCustomColumnModifyAssociation() {
    return {
      name: "btnModifyAssociation",
      index: 0,
      customButton: true,
      unsortable: true,
      condition: (element: AssociationsInsuranceRentPracticeModel) => true
    };
  }
}
