import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {InsuranceModel} from "@/models/insurances/insurance.model";

@Component({
  selector: 'app-dialog-new-insurance',
  templateUrl: './dialog-new-insurance.component.html',
})
export class DialogNewInsuranceComponent {
  protected newInsurance: InsuranceModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<DialogNewInsuranceComponent>
  ) {
    this.newInsurance = data.insurance;
  }
}
