import {Component, OnInit} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";
import {Registry} from "@/registry.service";
import {BrandModel} from "@/models/tables/brand.model";

@Component({
  selector: 'app-auto-complete-brand',
  templateUrl: './auto-complete-brand.component.html',
  styleUrls: ['./auto-complete-brand.component.scss']
})
export class AutoCompleteBrandComponent extends AutoCompleteComponent implements OnInit {

  displayFn(selectedoption: any): string {
    return selectedoption && selectedoption.descrizioneMarca ?
      selectedoption.descrizioneMarca :
      '';
  }

  getValueFormatted(model): string {
    return model && model.descrizioneMarca ? model.descrizioneMarca : ''
  }

  ngOnInit() {
    super.ngOnInit()

    const nameClass = `AutoCompleteBrandComponent-${this.keyformArrayName}-${this.indexRow}`

    Registry.getInstance().set(nameClass, this)
  }

  protected getValue(model: BrandModel): string {
    return model.descrizioneMarca ?? '';
  }
}
