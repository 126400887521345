<button (click)="btnClicked.emit($event);actionClick()"
        [disabled]="disabled || loading"
        [matTooltipPosition]="'above'"
        [matTooltip]="toolTip"
        [ngClass]="additionalClass"
        [ngStyle]="{'padding': smallBtn ? 'p-1' : null, 'min-width': smallBtn ? 'min-content' : null}"
        [type]="type"
        class="btn btn-{{ color }}"
>
  <ng-content></ng-content>
  <fa-icon *ngIf="showIcon" [icon]="faIcon"></fa-icon>
  {{ content }}
  <span
    *ngIf="loading"
    class="spinner-border spinner-border-sm ml-2"
    inert
    role="status"
  ></span>
</button>
