import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {FormSetting} from "@/form-settings/form-setting";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {
  StateMovementInsuranceEnum
} from "@pages/rental-management/rent-practice-detail/rental-insurance-list/state-movement-insurance-enum";

export class InsuranceFormMeta extends FormSetting {
  private static rows: ControlBase[] = [];
  private static isNewForm: boolean = false;
  private static typeInsurance: string;
  private static formInstance: DynamicFormComponent;

  public static setFormInstance(formInstance: DynamicFormComponent) {
    this.formInstance = formInstance;

    return this;
  }

  public static setTypeInsurance(typeInsurance: string) {
    this.typeInsurance = typeInsurance;

    return this;
  }

  public static setIsNewForm(isNewForm: boolean) {
    this.isNewForm = isNewForm;

    return this;
  }

  public static getMeta(): Array<any> {
    this.rows = [];

    this.addRowId();
    this.addRowTipoAssicurazione();
    this.addRowCompagnia();
    this.addRowNumeroPolizza();
    this.addRowDataInizio();
    this.addRowDataFine();
    this.addRowRc();
    this.addRowIfk();
    this.addRowPai();
    this.addRowStato();

    if (this.typeInsurance === 'TARGA_PROVA') {
      this.addTargaProva();
    }

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowRc() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'rc';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'RC';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowIfk() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'ifk';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'IFK';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowPai() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'pai';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'PAI';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowCompagnia() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'compagnia';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Compagnia';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowNumeroPolizza() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'numeroPolizza';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Numero Polizza';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataInizio() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataInizio';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Inizio';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataFine() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataFine';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Fine';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowStato() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'stato';
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.label = 'Stato';
    inputsComponent.class = "w-4/12";
    inputsComponent.options = [
      {key: StateMovementInsuranceEnum.ATTIVA, value: 'ATTIVA'},
      {key: StateMovementInsuranceEnum.ANNULLATA, value: 'ANNULLATA'},
      {key: StateMovementInsuranceEnum.SOSPESA, value: 'SOSPESA'},
    ];
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowTipoAssicurazione() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'tipoAssicurazione';
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.label = 'Tipo Assicurazione';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = !this.isNewForm;
    inputsComponent.options = [
      {key: 'MULTIPLA', value: 'MULTIPLA'},
      {key: 'SINGOLA', value: 'SINGOLA'},
      {key: 'TARGA_PROVA', value: 'TARGA_PROVA'},
    ];
    inputsComponent.required = true;
    inputsComponent.formComponent = this.formInstance;
    inputsComponent.customAction = 'typeInsuranceChanged';

    this.rows.push(inputsComponent);
  }

  private static addRowId() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'id';
    inputsComponent.disabled = true;
    inputsComponent.controlType = ControlType.textInput
    inputsComponent.label = 'ID';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }


  private static addTargaProva() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'targaProva';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Targa Prova';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }
}
