import {ApiService} from "@services/api.service";
import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {PracticePageResp} from "@/models/practice.model";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {catchError, map} from "rxjs/operators";

export interface TableDataApiParams {
  sort?: keyof TablePracticeModel | '';
  orderBy?: 'DESC' | 'ASC';
  page?: number;
  size?: number;
}

type TempCustomPracticeResp = Omit<PracticePageResp, 'content'> & {
  content: TablePracticeModel[];
}

@Injectable({
  providedIn: 'root'
})
export class ImportFlowService extends ApiService {

  getCrossF7Data(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/crossF7/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getCrossF7DeletedData(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/crossF7Deleted/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getCrossF8Data(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/crossF8/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getVwfsDashboardData(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/vwfsDashboard/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getVwfsCommissionData(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/vwfsCommission/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getVwbankCommissionData(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/vwbankCommission/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getLoyalty(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/loyalty/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getImportRegistry(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/importRegistry/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getVhmProforma(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/vhmproforma/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getVhmReport(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomPracticeResp> {
    return this.http.get<any>(this.baseUrl + `/importflow/vhmreport/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }
}
