import {CustomColumnModelInterface, TableColumnsSetting} from "@/interfaces/table-setting";
import {RentalClientModel} from "@/models/rentals/rental-client-model";

export class RentalClientsTableSettings {
  public static getColumnsSetting(): TableColumnsSetting<keyof RentalClientModel> {
    return [
      this.getColumnSelectClient(),
      {
        name: "numeroBp",
        text: 'BP',
        filter: {
          type: "input"
        }
      },
      {
        name: "codiceFiscale",
        text: 'Codice Fiscale',
        filter: {
          type: "input"
        }
      },
      {
        name: "piva",
        text: 'P. IVA',
        filter: {
          type: "input"
        }
      },
      {
        name: "localita",
        text: 'Località',
        filter: {
          type: "input"
        }
      },
      {
        name: "indirizzo",
        text: 'Indirizzo',
        filter: {
          type: "input"
        }
      },
      {
        name: "civico",
        text: 'Civico'
      },
      {
        name: "nome1",
        text: 'Nome 1',
        filter: {
          type: "input"
        }
      },
      {
        name: "nome2",
        text: 'Nome 2',
        filter: {
          type: "input"
        }
      },
      {
        name: "nome3",
        text: 'Nome 3',
        filter: {
          type: "input"
        }
      },
      {
        name: "consensoAssicurazione",
        text: 'Consenso Assicurazione'
      },
      {
        name: "consensoIndigSoddClientela",
        text: 'Consenso Indig Sodd Clientela'
      },
      {
        name: "consensoMarketing",
        text: 'Consenso Marketing'
      },
      {
        name: "consensoMarketingTerzeParti",
        text: 'Consenso Marketing Terze Parti'
      },
      {
        name: "consensoProfilazione",
        text: 'Consenso Profilazione'
      }
    ];
  }

  private static getColumnSelectClient(): CustomColumnModelInterface {
    return {
      name: "btnSelectClient",
      index: 0,
      customButton: true,
      unsortable: true,
      condition: function (row) {
        return true;
      },
    }
  }
}
