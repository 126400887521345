import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {CashMovementsForm} from "@/form-settings/cash-movements-form";
import {OptionSelect} from "@/models/option-select.model";
import {Registry} from "@/registry.service";
import {CashMovementsService} from "@services/cash-movements.service";
import {BankPaymentModel, CashMovementsModel} from "@/models/cash-movements.model";
import {LoggerService} from "@services/logger.service";
import {combineLatest, Subject} from "rxjs";
import {getCurrentUserRoles} from "@utils-functions";
import {Role} from "@/models/role";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {HttpErrorResponse} from "@angular/common/http";
import moment from "moment";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-new-cash-movements-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class NewCashMovementsFormComponent extends DynamicFormComponent implements OnInit, OnDestroy {
  @Input() departmentSelected: Subject<any>;

  // These arrays can be reused, so you don't keep repeating object literals:
  private readonly defaultTypePayments: OptionSelect[] = [
    {key: 'CONTANTI', value: 'Contanti'},
    {key: 'ASSEGNO', value: 'Assegno'},
    {key: 'POS', value: 'POS'},
    {key: 'BONIFICO', value: 'Bonifico'},
  ];
  private readonly depositTypePayments: OptionSelect[] = [
    {key: 'CONTANTI', value: 'Contanti'},
    {key: 'ASSEGNO', value: 'Assegno'}
  ];
  private readonly exitTypePayments: OptionSelect[] = [
    {key: 'CONTANTI', value: 'Contanti'}
  ];

  protected typeMovementSelected: string = 'ENTRATA';
  private departments: OptionSelect[] = [];
  private movementTypes: OptionSelect[] = [];
  private department: string;
  private actionAfterSave: "saveDuplicate" | "saveNew" | "save" = "save";
  private movementId: any;
  private isAccountingUser = false;
  private isNewMovement = false;
  private isAdminUser = false;
  private departmentUpdating: string;
  private banks: BankPaymentModel[] = [];
  // Used for unsubscribing from streams when the component is destroyed
  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected userService: UserService,
    protected cashMovementsService: CashMovementsService,
    protected toastr: ToastrService,
    public dialog: MatDialog
  ) {
    super(toastr, userService);
  }

  // Keep the current set of typePayments in one place
  private _typePayments: OptionSelect[] = [];

  get typePayments(): OptionSelect[] {
    return this._typePayments;
  }

  set typePayments(value: OptionSelect[]) {
    this._typePayments = [...value]; // ensure a fresh array
  }

  ngOnInit(): void {
    this.isAccountingUser = getCurrentUserRoles().includes(Role.Accounting);
    this.isAdminUser = getCurrentUserRoles().includes(Role.Admin);

    // Combine requests for initial data so we only call refreshForm once
    combineLatest([
      this.cashMovementsService.getDepartments(),
      this.cashMovementsService.getMovementTypes(),
      this.cashMovementsService.getBankAccounts(),
      this.route.params
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([departments, movementTypes, banks, params]: [string[], string[], BankPaymentModel[], Params]) => {
        // Build department options
        this.departments = departments.map(dep => ({key: dep, value: dep}));
        // Build movement type options
        this.movementTypes = movementTypes.map(mt => ({key: mt, value: mt}));
        // Store banks
        this.banks = banks;

        // Check if we're dealing with a new movement or an existing one
        if (!params['id']) {
          // New
          this.isNewMovement = true;
          this.secondButton = 'Salva e nuovo';
          this.thirdButton = 'Salva e duplica';
          this.department = departments[0]; // default to first
        } else {
          // Existing
          this.isNewMovement = false;
          this.movementId = params['id'];
        }

        // If it’s an existing movement, load it
        if (!this.isNewMovement) {
          this.loadDataMovement();
        } else {
          // For new movement
          // Just set default typePayments, etc.
          this.typePayments = this.defaultTypePayments;
          this.refreshFormData();
        }
      });

    Registry.getInstance().set('NewCashMovementsFormComponent', this);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // -----------------------------------------
  // Event Handlers
  // -----------------------------------------
  typePaymentChange(typePayment: string) {
    if (this.form.controls['tipoMovimento'].getRawValue() === 'ENTRATA') {
      if (typePayment === 'ASSEGNO') {
        this.form.controls['banca'].enable();
        this.form.controls['numeroAssegno'].enable();
      } else {
        this.form.controls['banca'].disable();
        this.form.controls['numeroAssegno'].disable();
      }
    }
    this.updateDescrizioneMovimento();
  }

  departmentChanged(department: string) {
    LoggerService.log('departmentChanged', department);

    if (!this.isNewMovement) {
      // attempt to change department on existing movement
      this.departmentUpdating = department;
      this.confirmChangeDepartment();
    } else {
      this.setDepartmentAndPayments(department);
    }
  }

  typeMovementChanged(typeMovement: string) {
    if (this.form.controls['reparto'].value === 'BACKOFFICE') {
      this.typeMovementSelected = typeMovement;
      this.updateFieldsTypeMovement(
        this.form.controls['reparto'].value,
        typeMovement
      );
      this.form.controls['tipoMovimento'].setValue(typeMovement);
    }
    this.updateDescrizioneMovimento();
  }

  // -----------------------------------------
  // Form Submission
  // -----------------------------------------
  onSubmit() {
    const cashMovement = this.form.getRawValue() as CashMovementsModel;
    this.cashMovementsService.saveCashMovements(cashMovement).subscribe({
      next: (response) => {
        const cashMovementId = response.id;
        this.toastr.success('Movimento di cassa salvato correttamente!');

        switch (this.actionAfterSave) {
          case "saveNew":
            this.actionAfterSave = 'save';
            window.location.reload();
            break;
          case "saveDuplicate":
            this.actionAfterSave = 'save';
            this.form.controls['importo'].setValue('');
            // Adjust date, etc.
            const value = this.form.get('dataMovimento').value;
            if (typeof value === 'object') {
              // Doing date logic here if needed
              const momentDate = moment(value);
              momentDate.subtract(1, 'd');
            }
            break;
          case "save":
          default:
            this.actionAfterSave = 'save';
            this.router.navigate([`cash-management/cash-movements/details/${cashMovementId}`]);
            break;
        }
      },
      error: (error) => {
        LoggerService.error('saveCashMovements failed!');
        LoggerService.error(error);
        this.manageErrorMessages(error);
        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false);
        }, 1000);
      },
      complete: () => {
        LoggerService.log('Request done');
        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false);
        }, 1000);
      },
    });
  }

  setupMeta(): void {
    this.meta = CashMovementsForm
      .setClassToCall('NewCashMovementsFormComponent')
      .set('departments', this.departments)
      .set('movementTypes', this.movementTypes)
      .set('isNewMovement', this.isNewMovement)
      .set('department', this.department)
      .set('typePayments', this.typePayments)
      .set('isAccountingUser', this.isAccountingUser)
      .set('isAdminUser', this.isAdminUser)
      .set('banksAccount', this.banks)
      .set('typeMovementSelected', this.typeMovementSelected)
      .getMeta();
  }

  protected secondButtonAction() {
    this.actionAfterSave = "saveNew";
    this.onSubmitForm();
  }

  // -----------------------------------------
  // Private / Helper Methods

  protected thirdButtonAction() {
    this.actionAfterSave = "saveDuplicate";
    this.onSubmitForm();
  }

  protected manageErrorMessages(error: HttpErrorResponse) {
    const errorKey = error.error.key ?? '';
    switch (errorKey) {
      case "CASH_FLOW_LOCKED":
        this.toastr.error(
          `Non è possibile salvare questo movimento in quanto è stato fatto un fondo cassa in data seguente.`
        );
        break;
      default:
        this.toastr.error(`Errore durante il salvataggio del movimento.`);
        break;
    }
  }

  // -----------------------------------------
  private setDepartmentAndPayments(department: string) {
    this.department = department;
    // Example of more specialized logic:
    // By default, we start with all typePayments
    this.typePayments = [...this.defaultTypePayments];
    if (department === 'OFFICINA') {
      this.typePayments.push({key: 'CONTO_MEKKANO', value: 'Conto Mekkano'});
    }
    this.refreshFormData();
    this.form.controls['reparto'].setValue(department);
    this.updateDescrizioneMovimento();
    this.typeMovementChanged(this.form.controls['tipoMovimento'].value)
  }

  private confirmChangeDepartment(): void {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });
    this.setupPopup(dialogRef);

    dialogRef.afterClosed().subscribe(changeDepartment => {
      const oldDepartment = this.department;
      if (!changeDepartment) {
        this.department = oldDepartment;
      } else {
        this.department = this.departmentUpdating;
      }
      this.refreshFormData();
      this.form.controls['reparto'].setValue(this.department);
    });
  }

  private updateFieldsTypeMovement(dept: string, typeMovement: string) {
    switch (typeMovement) {
      case 'VERSAMENTO':
      case 'FONDO_CASSA':
        this.typePayments = [...this.depositTypePayments];
        break;
      case 'USCITA':
        this.typePayments = [...this.exitTypePayments];
        break;
      case 'ENTRATA':
        // Start with the default
        this.typePayments = [...this.defaultTypePayments];
        if (dept === 'OFFICINA') {
          this.typePayments.push({key: 'CONTO_MEKKANO', value: 'Conto Mekkano'});
        }
        break;
      default:
        this.typePayments = [...this.defaultTypePayments];
        break;
    }

    //refresh form
    this.refreshFormData();

    // Then update the form controls
    if (typeMovement === 'VERSAMENTO') {
      this.form.controls['bancaVersamento'].enable();
      this.form.controls['descrizioneMovimento'].disable();
    } else if (typeMovement === 'USCITA') {
      this.form.controls['descrizioneMovimento'].enable();
    }
  }

  private updateDescrizioneMovimento() {
    const cashMovement = this.form.getRawValue() as CashMovementsModel;
    if(cashMovement.reparto === 'BACKOFFICE') {
      if (cashMovement.tipoMovimento === 'VERSAMENTO' && cashMovement.tipoPagamento) {
        const bancaVersamento = cashMovement.bancaVersamento?.descrizione ?? '';
        const text = cashMovement.tipoPagamento + " " + bancaVersamento;
        this.form.controls['descrizioneMovimento'].setValue("Versamento " + text.toLowerCase());
      } else {
        this.form.controls['descrizioneMovimento'].setValue(null);
      }
    }
  }

  private loadDataMovement() {
    this.cashMovementsService.getMovementsById(this.movementId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (cashMovement) => {
          this.data = cashMovement as CashMovementsModel;
          this.department = this.data.reparto;
          this.primaryButton = `Salva movimento`;
          this.typeMovementSelected = this.data.tipoMovimento;

          this.refreshFormData();
          this.updateFieldsTypeMovement(
            this.department,
            this.data.tipoMovimento
          );
          this.typePaymentChange(this.data.tipoPagamento);
        },
        error: (error) => {
          LoggerService.error('getMovementById failed!');
          LoggerService.error(error);
        },
        complete: () => LoggerService.log('Request done'),
      });
  }

  private refreshFormData() {
    // Single function to rebuild the meta and refresh the dynamic form
    this.setupMeta();
    this.refreshForm();
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di cambiare reparto?';
    dialogRef.componentInstance.content = "Attenzione una volta cambiato il reparto, verranno aggiornati anche i campi sottostanti.";
    dialogRef.componentInstance.firstCTA = "Si modifica";
    dialogRef.componentInstance.secondCTA = "Annulla operazione";
    dialogRef.componentInstance.closeResultFirst = true;
    dialogRef.componentInstance.closeResultSecond = false;
  }
}
