import {Injectable} from '@angular/core';
import {ApiService} from "@services/api.service";
import {of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {HttpHeaders} from "@angular/common/http";
import {CashFoundModel} from "@/models/cash-management/cash-found-model";

export interface TableDataApiParams {
  page?: number;
  size?: number;
  year?: number;
  month?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CashMovementsAnalisisService extends ApiService {

  getDataForTable(
    params: TableDataApiParams = {
      page: 1,
      size: 30,
    }
  ) {
    return this.http.get<any>(this.baseUrl + `/cashFlowAnalisis/page`, {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content
      })),
      catchError(() => of(null))
    );
  }

  getAnalysisDashboard() {
    return this.http.get(this.baseUrl + `/cashFlowAnalisis/dashboard`).pipe(map((response: any) => {
      LoggerService.log(response, 'getAnalysisDashboard')
      return response;
    })).toPromise();
  }

  downloadExportExcel(dateExport: string): any {
    return this.http.get(this.baseUrl + `/cashFlowAnalisis/exportXlsx/${dateExport}/`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }

  createCashFound(cashFound: CashFoundModel) {
    return this.http.post(this.baseUrl + '/cashFlowAnalisis/createFondoCassa', cashFound).pipe(map((response: any) => {
      LoggerService.log(response, 'createUser')
      return response;
    }));
  }
}
