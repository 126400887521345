<mat-label class="label-form">{{ meta.label }}:</mat-label>
<div *ngIf="!meta.hidden" [formGroup]="form" [ngClass]="meta.disabled ? 'disabled' : ''" class="upload-div">
  <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName">
    <div [formGroupName]="indexRow" class="file-upload-container"
         style="position: relative;">
      <input (change)="customActionInput($event, indexRow)"
             [accept]="meta.accept"
             [formControlName]="meta.key"
             [multiple]="meta.multiple"
             class="file-input"
             type="file">
      <p class="file-name">
        <fa-icon [icon]="iconType"></fa-icon>
        {{ meta.inputUploadText ?? this.textFile ?? 'Trascina qui più documenti' }}
      </p>
    </div>
  </div>
  <div *ngIf="indexRow === null" class="file-upload-container"
       style="position: relative;">
    <input (change)="customActionInput($event, meta.customAction )"
           [accept]="meta.accept"
           [formControlName]="meta.key"
           [multiple]="meta.multiple"
           class="file-input"
           type="file">
    <p class="file-name">
      <fa-icon [icon]="iconType" class="block text-2xl"></fa-icon>
      {{ meta.inputUploadText ?? this.textFile ?? 'Trascina qui un documento' }}
    </p>
  </div>
  <app-box-errors [control]="control" [meta]="meta" [submitted]="submitted"></app-box-errors>
</div>
