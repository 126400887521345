import {Component, OnInit} from '@angular/core';
import {DataSourceTable} from "@components/new-table/data-sources/data-source-table";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {RentalInsuranceService} from "@services/data-source-tables/rentals/rental-insurance.service";
import {RentalInsuranceTableSettings} from "@/table-settings/rentals/rental-insurance-table-settings";
import {
  DialogFormRentalInsuranceComponent
} from "@pages/rental-management/rent-practice-detail/rental-insurance-list/dialog-form-rental-insurance/dialog-form-rental-insurance.component";
import {faBuildingUser, faEye, faPause, faToggleOn, faWarning, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {RentalInsuranceModel} from "@/models/rentals/rental-insurance-model";
import {CustomButtonInterface} from "@components/new-table/custom-button-interface";
import {AssociationsInsuranceRentPracticeModel} from "@/models/insurances/associations-insurance-rent-practice-model";
import {LinkInsuranceVehicleDataModel} from "@/models/rentals/link-insurance-vehicle-data-model";
import {
  StateMovementInsuranceEnum
} from "@pages/rental-management/rent-practice-detail/rental-insurance-list/state-movement-insurance-enum";
import {DialogLoadingComponent} from "@components/dialog-loading/dialog-loading.component";

@Component({
  selector: 'app-rental-insurance-list',
  templateUrl: './rental-insurance-list.component.html',
})
export class RentalInsuranceListComponent implements OnInit {
  displayedColumns = RentalInsuranceTableSettings.getColumnsSetting()
  dataSource = new DataSourceTable(this.rentalInsuranceService);
  reloadTrigger$ = new Subject<any>();
  id: any;
  iconWarning: IconDefinition = faWarning;
  customButtons: CustomButtonInterface[];
  protected readonly iconAddClient = faBuildingUser;
  protected showButtonAddInsurance: boolean = true;
  protected dialogInsurance: MatDialogRef<DialogFormRentalInsuranceComponent, any>;
  protected insuranceExpiring: boolean = false;
  private dialogLoading: MatDialogRef<DialogLoadingComponent, any>;

  constructor(private rentalInsuranceService: RentalInsuranceService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private dialog: MatDialog) {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
    this.customButtons = [
      {
        btnCustomToolTip: 'Dettaglio Assicurazione',
        btnCustomFaIcon: faEye,
        btnCustomColor: 'primary',
        btnCustomShowIcon: true,
        btnCustomClickEvent: (element: AssociationsInsuranceRentPracticeModel) => this.viewInsuranceDetail(element),
      },
      {
        btnCustomToolTip: 'Sospendi Assicurazione',
        btnCustomFaIcon: faPause,
        btnCustomColor: 'warning',
        btnCustomShowIcon: true,
        btnCustomClickEvent: (element: AssociationsInsuranceRentPracticeModel) => this.suspendInsurance(element.rentalPractice.id, element.insurance.id, element),
      },
      {
        btnCustomToolTip: 'Attiva Assicurazione',
        btnCustomFaIcon: faToggleOn,
        btnCustomColor: 'success',
        btnCustomShowIcon: true,
        btnCustomClickEvent: (element: AssociationsInsuranceRentPracticeModel) => this.activeInsurance(element.rentalPractice.id, element.insurance.id, element),
      }
    ];

    this.dataSource.getElements$.subscribe({
      next: (value: any[]) => {
        if (value) {
          if (value.some(item => item.status === 'ATTIVO')) {
            this.showButtonAddInsurance = false;
          }

          if (value.some(item => item.status === 'ATTIVO' && new Date(item.dataFine) < new Date(new Date().setDate(new Date().getDate() + (7 - new Date().getDay()))))) {
            this.insuranceExpiring = true;
          }
        }
      }
    });
  }

  btnDetailsClickFunction(insurance: any) {
    this.dialogInsurance = this.dialog.open(DialogFormRentalInsuranceComponent, {
      data: {
        insurance,
        dialogRef: this.dialogInsurance,
        id: this.id
      },
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.dialogInsurance.afterClosed().subscribe({
      next: () => {
        this.reloadTrigger$.next();
      }
    });
  }

  ngOnInit(): void {
  }

  addRentInsurance() {
    const insurance = new RentalInsuranceModel();

    this.dialogInsurance = this.dialog.open(DialogFormRentalInsuranceComponent, {
      data: {insurance, dialogRef: this.dialogInsurance, id: this.id},
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.dialogInsurance.afterClosed().subscribe({
      next: () => {
        this.reloadTrigger$.next();
      }
    });
  }

  btnDeleteClickFunction($event: any) {
    this.rentalInsuranceService.deleteRentalInsurance(this.id, $event.id).subscribe({
      next: () => {
        this.toastr.success('Assicurazione noleggio eliminata correttamente!');
        this.reloadTrigger$.next();
      },
      error: (error) => {
        console.error('deleteRentalInsurance failed!');
        console.error(error);
        this.toastr.error('Errore nell\'eliminazione!');
      }
    });
  }

  private suspendInsurance(rentalPracticeId: number, insuranceId: number, associationInsurance: AssociationsInsuranceRentPracticeModel) {
    const linkData = {} as LinkInsuranceVehicleDataModel;
    linkData.dataInizio = associationInsurance.dataInizio;
    linkData.dataFine = associationInsurance.dataFine;
    linkData.premioNetto = associationInsurance.premioNetto;
    linkData.stato = StateMovementInsuranceEnum.SOSPESA;
    this.dialogLoading = this.dialog.open(DialogLoadingComponent, {
      data: 'Sospensione in corso...',
      disableClose: true,
    });

    this.rentalInsuranceService.suspendInsurance(rentalPracticeId, insuranceId, linkData).subscribe({
      next: () => {
        this.dialogLoading.close();
        this.toastr.success('Assicurazione sospesa correttamente!');
        this.reloadTrigger$.next();
      }
    });
  }

  private activeInsurance(rentalPracticeId: number, insuranceId: number, associationInsurance: AssociationsInsuranceRentPracticeModel) {
    const linkData = {} as LinkInsuranceVehicleDataModel;
    linkData.dataInizio = associationInsurance.dataInizio;
    linkData.dataFine = associationInsurance.dataFine;
    linkData.premioNetto = associationInsurance.premioNetto;
    linkData.stato = StateMovementInsuranceEnum.ATTIVA;
    this.dialogLoading = this.dialog.open(DialogLoadingComponent, {
      data: 'Attivazione in corso...',
      disableClose: true,
    });
    
    this.rentalInsuranceService.activeInsurance(rentalPracticeId, insuranceId, linkData).subscribe({
      next: () => {
        this.dialogLoading.close();
        this.toastr.success('Assicurazione attivata correttamente!');
        this.reloadTrigger$.next();
      }
    });
  }

  private viewInsuranceDetail(insurance: AssociationsInsuranceRentPracticeModel) {
    this.router.navigate([`insurances/detail-insurance/${insurance.insurance.id}`])
  }
}
