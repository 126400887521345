<h1 class="text-dark text-xl" mat-dialog-title>Migrazione Dati</h1>
<div class="w-full" mat-dialog-content>
  <h2 class="text-dark text-lg mb-2" mat-dialog-title>
    Migrazione dati da pratica: {{ data.practice.codicePratica }} - {{ data.practice.tipoPratica }}
  </h2>

  <form (ngSubmit)="searchPractice()">
    <mat-form-field class="w-full">
      <mat-label>Inserisci ID Pratica</mat-label>
      <input [(ngModel)]="practiceId" class="text-white" matInput name="practiceId" required>
    </mat-form-field>
    <app-button class="mt-4" type="submit">Cerca Pratica</app-button>
  </form>

  <div *ngIf="practiceNotFound" class="text-red-400 mt-2">
    Nessuna pratica trovata!
  </div>

  <mat-card *ngIf="selectedPractice" class="mt-4 bg-gray-800 text-white p-4 rounded-lg shadow-lg mb-3">
    <mat-card-title class="text-lg font-bold text-green-400">Pratica Trovata!</mat-card-title>
    <mat-card-content>
      <p><strong>ID:</strong> {{ selectedPractice.id }}</p>
      <p><strong>Tipo:</strong> {{ selectedPractice.tipoPratica ?? 'N/A' }}</p>
      <p><strong>Stato:</strong> {{ selectedPractice.statoPratica ?? 'N/A' }}</p>
      <p><strong>Cliente:</strong> {{ selectedPractice.cliente ?? 'N/A' }}</p>
    </mat-card-content>
  </mat-card>

  <app-button (click)="closePopup()" class="float-right mr-0">Annulla</app-button>
  <app-button (click)="migratePractice()" *ngIf="selectedPractice" [loading]="loadingMigration" class="mt-4"
              color="secondary">
    Migra Pratica
  </app-button>
</div>
