import {Component} from '@angular/core';
import {
  faBuildingUser,
  faCircleInfo,
  faFile,
  faFilePdf,
  faUndo,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "@/interfaces/tab";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {LoggerService} from "@services/logger.service";
import {RentalPracticeService} from "@services/data-source-tables/rentals/rental-practice.service";
import {ActivatedRoute, Params} from "@angular/router";
import {Subject} from "rxjs";
import {saveAs} from "file-saver";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";
import {
  DialogFormRentalMovementComponent
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/dialog-form-rental-movement/dialog-form-rental-movement.component";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {MatDialog} from "@angular/material/dialog";
import {RentalClientsService} from "@services/data-source-tables/rentals/rental-clients.service";
import {
  DialogFormReturnRentalMovementComponentComponent
} from "@pages/rental-management/rent-practice-detail/dialog-form-return-rental-movement-component/dialog-form-return-rental-movement-component.component";
import {InsuranceService} from "@services/data-source-tables/insurances/insurance.service";

@Component({
  selector: 'app-rent-practice-detail',
  templateUrl: './rent-practice-detail.component.html',
  styleUrls: ['./rent-practice-detail.component.scss']
})
export class RentPracticeDetailComponent {
  data: RentalPracticeModel
  tabs: Array<Tab> = []
  protected refreshRentalPracticeEvent$: Subject<any> = new Subject<any>();
  protected closeDialogRentalMovementEvent$: Subject<any> = new Subject<any>();
  protected rentalInProgress: boolean = true;
  protected readonly faFile: IconDefinition = faFile;
  protected readonly iconAddClient: IconDefinition = faBuildingUser;
  protected loading: boolean = false;
  protected id: number;
  protected readonly iconFaUndo: IconDefinition = faUndo;
  protected readonly iconPdf: IconDefinition = faFilePdf;
  private closeDialogRentalReturnMovementEvent$: Subject<any> = new Subject<any>();

  protected readonly faFilePdf = faFilePdf;

  async refreshPractice() {
    LoggerService.log('refreshPractice')

    await this.rentalPracticeService.getRentalPractice(this.id).then(practice => {
      LoggerService.log(practice, 'getRentalPractice')

      this.data = <RentalPracticeModel>practice;
      this.rentalInProgress = this.data.activeRental !== null;
      this.data.categoriaVeicolo = this.data.marca?.categoriaVeicolo ?? 'ASSENTE';
    });

    return 1;
  }

  getMarca() {
    return this.data.marca?.categoriaVeicolo + ' - ' + this.data.marca?.descrizioneMarca
  }

  downloadPdfVehiclePickUp() {
    this.loading = true

    this.rentalMovementService.downloadPdfVehiclePickUp(this.data.activeRental.id).subscribe({
      next: (res) => {
        saveAs(res, 'ritiro-veicolo-' + this.data.activeRental.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  downloadPdfRentalB2b() {
    this.loading = true

    this.rentalMovementService.downloadPdfRentalB2b(this.data.activeRental.id).subscribe({
      next: (res) => {
        saveAs(res, 'contratto-noleggio-' + this.data.activeRental.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  newRentalMovement() {
    let dialogRef = this.dialog.open(DialogFormRentalMovementComponent, {
      data: {
        movement: new RentalMovementModel(),
        rentalClientsService: this.rentalClientsService,
        practiceId: this.id,
        closeDialogRentalMovementEvent$: this.closeDialogRentalMovementEvent$,
        refreshRentalPracticeEvent$: this.refreshRentalPracticeEvent$,
        isNew: true
      },
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.closeDialogRentalMovementEvent$.subscribe(() => {
      dialogRef.close();
    });
  }

  returnVehicle() {
    let dialogRef = this.dialog.open(DialogFormReturnRentalMovementComponentComponent, {
      data: {
        rentalMovement: this.data.activeRental,
        rentalClientsService: this.rentalClientsService,
        practiceId: this.id,
        closeDialogTrigger$: this.closeDialogRentalReturnMovementEvent$,
        refreshRentalPracticeEvent$: this.refreshRentalPracticeEvent$,
      },
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.closeDialogRentalReturnMovementEvent$.subscribe(() => {
      dialogRef.close();
    });
  }

  constructor(private rentalPracticeService: RentalPracticeService, private route: ActivatedRoute, private rentalMovementService: RentalMovementService, private dialog: MatDialog, protected rentalClientsService: RentalClientsService, private insuranceService: InsuranceService) {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
    this.tabs = [
      {
        'title': 'Anagrafica Noleggio VWL',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Noleggio in corso',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Movimenti noleggio',
        'faIcon': faCircleInfo
      },
      {
        'title': 'Assicurazioni',
        'faIcon': faCircleInfo
      },
    ]
    this.data = {} as RentalPracticeModel;

    this.refreshPractice()

    this.refreshRentalPracticeEvent$.subscribe(() => {
      this.refreshPractice()
    });
  }

  downloadFile() {
    this.insuranceService.downloadInsuranceFile(this.data.activeInsurance.id).subscribe({
      next: (response) => {
        const insuranceId = this.data.activeInsurance.numeroPolizza;
        const company = this.data.activeInsurance.compagnia;

        saveAs(response, `Polizza-${insuranceId}_${company}.pdf`);
      }
    });
  }
}
