import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {DynamicFormSubmitInterface} from "@components/dynamic-form/dynamic-form-submit-interface";
import {RentalInsuranceModel} from "@/models/rentals/rental-insurance-model";
import {RentalInsuranceService} from "@services/data-source-tables/rentals/rental-insurance.service";
import {RentalInsuranceFormSetting} from "@/form-settings/rentals/rental-insurance-form-setting";

@Component({
  selector: 'app-rental-insurance-form',
  templateUrl: '../../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class RentalInsuranceFormComponent extends DynamicFormComponent implements DynamicFormPopUpInterface, DynamicFormSubmitInterface, OnInit {
  @Output() closeDialogEvent = new EventEmitter<void>();
  @Input() practiceRentalId: number;
  private isNew: boolean = true;

  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected userService: UserService,
    protected rentalInsuranceService: RentalInsuranceService,
  ) {
    super(toastr, userService)
    this.data = new RentalInsuranceModel()
    this.primaryButton = `Salva assicurazione`
    this.submitTopButton = false;
    this.secondaryBottomButton = `Annulla`;

    this.setupMeta()
  }


  ngOnInit() {
    if (this.data.length > 0) {
      this.isNew = false

      this.setupMeta()
    }
  }

  setupMeta() {
    this.meta = RentalInsuranceFormSetting
      .setIsNew(this.isNew)
      .getMeta()
  }

  onSubmit() {
    this.submitButtonLoading = true;

    let rentalInsuranceModel = <RentalInsuranceModel>this.form.getRawValue()

    this.rentalInsuranceService.updateRentalInsurance(this.practiceRentalId, rentalInsuranceModel).subscribe({
      next: (response) => {
        this.toastr.success('Rinnovo assicurazione effettuato con successo!');

        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('updateRentalInsurance failed!');
        LoggerService.error(error);

        this.toastr.error('Errore nel salvataggio!');

        setTimeout(() => {
          this.submitButtonLoading = false;
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          this.bottomButtonAction();
        }, 1000)
      },
    })
  }

  bottomButtonAction(): void {
    this.closeDialogEvent.emit();
    this.submitButtonLoading = false;
  }

}
