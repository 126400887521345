import {Component} from '@angular/core';
import {Location} from '@angular/common'
import {ButtonComponent} from "@components/button/button.component";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-back-button',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss']
})
export class BackButtonComponent extends ButtonComponent {

  backText: string = 'Indietro'

  constructor(protected router: Router, protected toastr: ToastrService, private location: Location) {
    super(router, toastr);

    this.content = this.backText
    this.faIcon = faAngleLeft
    this.showIcon = true
    this.additionalClass = 'btn btn-primary ml-0 w-full mb-3 md:w-auto btn-back mr-3'
  }

  actionClick() {
    this.location.back()
  }
}
