<div class="table-container">
  <div *ngIf="insuranceExpiring" class="w-full bg-yellow-100 p-2 mb-4 flex items-center">
    <fa-icon [icon]="iconWarning" class="mr-2 icon_warning"></fa-icon>
    <span
      class="text-yellow-800">Assicurazione in scadenza! Controllare i dettagli per rinnovare o attivarne una nuova.</span>
  </div>
  <app-new-table
    (btnDeleteClickEvent)="btnDeleteClickFunction($event)"
    (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
    [additionalFilters]="{id: id}"
    [customButtons]="customButtons"
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns"
    [hideFilterHeaders]="true"
    [initialSort]="{active: 'id',direction: 'asc'}"
    [reloadTrigger$]="reloadTrigger$"
    [showPaginate]="true"
  ></app-new-table>
</div>
