import {TableColumnsSetting} from "@/interfaces/table-setting";
import {VhmProFormaModel} from "@/models/imports/vhm-pro-forma-model";

export class VhmProFormaSettingsTable {

  public static getColumnsSetting(): TableColumnsSetting<keyof VhmProFormaModel> {
    return [
      {
        name: "id",
        text: "Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "importId",
        text: "Import Id",
        filter: {
          type: "input",
        }
      },
      {
        name: "cliente",
        text: "Cliente",
        filter: {
          type: "input",
        }
      },
      {
        name: "riferimentoContratto",
        text: "Rif. Contratto",
        filter: {
          type: "number",
        }
      },
      {
        name: "sigla",
        text: "Sigla",
        filter: {
          type: "input",
        }
      },
      {
        name: "venditore",
        text: "Venditore",
        filter: {
          type: "input",
        }
      },
      {
        name: "taf",
        text: "Taf",
        filter: {
          type: "input",
        }
      },
      {
        name: "base",
        text: "Base"
      },
      {
        name: "percentuale",
        text: "Percentuale"
      },
      {
        name: "importo",
        text: "Importo"
      },
      {
        name: "meseFtProForma",
        text: "Mese Ft Pro Forma",
        filter: {
          type: "input",
        }
      },
      {
        name: "createdAt",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "updatedAt",
        type: "datetime",
        text: "modificato il",
        filter: {
          type: 'dateRange'
        }
      }
    ];
  }
}
