import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {OptionSelect} from "@/models/option-select.model";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {DynamicFormSubmitInterface} from "@components/dynamic-form/dynamic-form-submit-interface";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";
import {RentalMovementFormSetting} from "@/form-settings/rentals/rental-movement-form-setting";
import {Subject} from "rxjs";
import {
  DialogSelectClientRentComponent
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/dialog-form-rental-movement/dialog-select-client-rent/dialog-select-client-rent.component";
import {MatDialog} from "@angular/material/dialog";
import {RentalClientModel} from "@/models/rentals/rental-client-model";
import {RentalClientsService} from "@services/data-source-tables/rentals/rental-clients.service";
import {
  DialogSelectCompanyRentComponent
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/dialog-form-rental-movement/dialog-select-company-rent/dialog-select-company-rent.component";

@Component({
  selector: 'app-rental-movement-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class RentalMovementFormComponent extends DynamicFormComponent implements DynamicFormPopUpInterface, DynamicFormSubmitInterface, OnInit {
  @Input() practiceId: number;
  @Input() data: RentalMovementModel
  @Input() refreshRentalPracticeEvent$: Subject<any>;
  @Input() closeDialogRentalMovementEvent$: Subject<any>;
  @Input() closeButton: string;
  protected closeSelectClientEvent$: EventEmitter<RentalClientModel> = new EventEmitter<RentalClientModel>();
  protected brands: OptionSelect[] = [];
  protected closeSelectCompanyEvent$: EventEmitter<RentalClientModel> = new EventEmitter<RentalClientModel>();
  private isNew: boolean = true;
  private stateIsTerminated: boolean = false;

  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected userService: UserService,
    protected rentalMovementService: RentalMovementService,
    protected dialogRef: MatDialog,
    protected rentalClientsService: RentalClientsService,
  ) {
    super(toastr, userService)
    this.primaryButton = `Salva noleggio`
    this.secondaryBottomButton = this.closeButton ?? null
    this.thirdButton = `Seleziona utilizzatore`
    this.secondButton = `Seleziona società`
    this.setupMeta()

    Registry.getInstance().set('RentalMovementFormComponent', this)
  }

  ngOnInit() {
    this.isNew = this.data.id === undefined;
    this.stateIsTerminated = this.data.stato === 'TERMINATO';

    this.refreshForm()
  }

  setupMeta() {
    this.meta = RentalMovementFormSetting
      .setPracticeIsClosed(this.stateIsTerminated)
      .setIsNew(this.isNew)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  onSubmit() {
    this.submitButtonLoading = true

    let rentalMovementModel = <RentalMovementModel>this.form.getRawValue()

    this.rentalMovementService.updateRentalMovement(this.practiceId, rentalMovementModel).subscribe({
      next: (response) => {
        this.toastr.success('Noleggio salvato correttamente!');

        this.refreshRentalPracticeEvent$.next()
        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('updateRentalMovement failed!');
        LoggerService.error(error);

        if (error.error.key === 'CONFLICT') {
          this.toastr.error(`Errore! è già presente un noleggio in corso per questa pratica.`);
        } else {
          this.toastr.error(`Errore durante il salvataggio del noleggio.`);
        }

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
          this.submitButtonLoading = false
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)

          if (this.closeDialogRentalMovementEvent$) {
            this.closeDialogRentalMovementEvent$.next()
          }
          this.submitButtonLoading = false
        }, 1000)
      },
    })
  }

  bottomButtonAction(): void {
    if (this.closeDialogRentalMovementEvent$) {
      this.closeDialogRentalMovementEvent$.next()
    }
  }

  protected thirdButtonAction() {
    Registry.getInstance().set('closeSelectClientEvent$', this.closeSelectClientEvent$);

    const dialogSelectClient = this.dialogRef.open(DialogSelectClientRentComponent, {
      data: {
        service: this.rentalClientsService,
      },
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    Registry.getInstance().get('closeSelectClientEvent$').subscribe((client) => {
      this.form.get('bpUtilizzatore').setValue(client.numeroBp);
      this.form.get('denominazioneUtilizzatore').setValue(client.nome1 === null ? '' : (client.nome1.trim()));
      this.form.get('denominazioneUtilizzatore').setValue(this.form.get('denominazioneUtilizzatore').value.trim() + ' ');
      this.form.get('denominazioneUtilizzatore').setValue(this.form.get('denominazioneUtilizzatore').value + (client.nome2 === null ? '' : (client.nome2.trim() + ' ')));
      this.form.get('denominazioneUtilizzatore').setValue(this.form.get('denominazioneUtilizzatore').value.trim() + ' ');
      this.form.get('denominazioneUtilizzatore').setValue(this.form.get('denominazioneUtilizzatore').value + (client.nome3 === null ? '' : client.nome3.trim()));
      this.form.get('denominazioneUtilizzatore').setValue(this.form.get('denominazioneUtilizzatore').value.trim());
      this.form.get('codiceFiscaleUtilizzatore').setValue(client.codiceFiscale);
      this.form.get('cittaUtilizzatore').setValue(client.localita);
      this.form.get('indirizzoUtilizzatore').setValue(client.indirizzo === null ? '' : (client.indirizzo.trim()));
      this.form.get('indirizzoUtilizzatore').setValue(this.form.get('indirizzoUtilizzatore').value.trim() + ' ');
      this.form.get('indirizzoUtilizzatore').setValue(this.form.get('indirizzoUtilizzatore').value + (client.civico === null ? '' : (client.civico.trim())));
      this.form.get('consensoIndigSoddClientelaUtilizzatore').setValue(client.consensoIndigSoddClientela);
      this.form.get('consensoMarketingUtilizzatore').setValue(client.consensoMarketing);
      this.form.get('consensoMarketingTerzePartiUtilizzatore').setValue(client.consensoMarketingTerzeParti);
      this.form.get('consensoProfilazioneUtilizzatore').setValue(client.consensoProfilazione);
      this.form.get('consensoAssicurazioneUtilizzatore').setValue(client.consensoAssicurazione);

      dialogSelectClient.close()
    });
  }

  protected secondButtonAction() {
    Registry.getInstance().set('closeSelectCompanyEvent$', this.closeSelectCompanyEvent$);

    const dialogSelectCompany = this.dialogRef.open(DialogSelectCompanyRentComponent, {
      data: {
        service: this.rentalClientsService,
      },
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    Registry.getInstance().get('closeSelectCompanyEvent$').subscribe((client) => {
      this.form.get('bpSocieta').setValue(client.numeroBp);
      this.form.get('societa').setValue(client.nome1 === null ? '' : (client.nome1.trim()));
      this.form.get('societa').setValue(this.form.get('societa').value.trim() + ' ');
      this.form.get('societa').setValue(this.form.get('societa').value + (client.nome2 === null ? '' : (client.nome2.trim() + ' ')));
      this.form.get('societa').setValue(this.form.get('societa').value.trim() + ' ');
      this.form.get('societa').setValue(this.form.get('societa').value + (client.nome3 === null ? '' : client.nome3.trim()));
      this.form.get('societa').setValue(this.form.get('societa').value.trim());
      this.form.get('pivaSocieta').setValue(client.piva);
      this.form.get('codiceFiscaleSocieta').setValue(client.codiceFiscale);
      this.form.get('cittaSocieta').setValue(client.localita);
      this.form.get('indirizzoSocieta').setValue(client.indirizzo === null ? '' : (client.indirizzo.trim()));
      this.form.get('indirizzoSocieta').setValue(this.form.get('indirizzoSocieta').value.trim() + ' ');
      this.form.get('indirizzoSocieta').setValue(this.form.get('indirizzoSocieta').value + (client.civico === null ? '' : (client.civico.trim())));
      this.form.get('consensoIndigSoddClientelaSocieta').setValue(client.consensoIndigSoddClientela);
      this.form.get('consensoMarketingSocieta').setValue(client.consensoMarketing);
      this.form.get('consensoMarketingTerzePartiSocieta').setValue(client.consensoMarketingTerzeParti);
      this.form.get('consensoProfilazioneSocieta').setValue(client.consensoProfilazione);
      this.form.get('consensoAssicurazioneSocieta').setValue(client.consensoAssicurazione);
      dialogSelectCompany.close()
    });
  }
}
