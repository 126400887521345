import {OptionSelect} from "@/models/option-select.model";
import {BankPaymentModel} from "@/models/cash-movements.model";
import {ControlType} from "@components/dynamic-form/control-type";

export class MetaBackOffice {
  private static rows: any[] = [];
  private static classToCall: string;
  private static department: string;
  private static departments: OptionSelect[] = [];
  private static movementTypes: OptionSelect[] = [];
  private static isAccountingUser: boolean = false;
  private static isNewMovement: boolean = false;
  private static isAdminUser: boolean = false;
  private static typePayments: OptionSelect[] = [];
  private static banksAccount: BankPaymentModel[] = [];
  private static typeMovementSelected: string = "";

  public static getMeta(
    classToCall: string,
    department: string,
    departments: OptionSelect[] = [],
    movementTypes: OptionSelect[] = [],
    isAccountingUser: boolean = false,
    isNewMovement: boolean = false,
    isAdminUser: boolean = false,
    typePayments: OptionSelect[] = [],
    banksAccount: BankPaymentModel[] = [],
    typeMovementSelected: string = ""
  ) {
    this.rows = [];
    this.classToCall = classToCall;
    this.department = department;
    this.departments = departments;
    this.movementTypes = movementTypes;
    this.isAccountingUser = isAccountingUser;
    this.isNewMovement = isNewMovement;
    this.isAdminUser = isAdminUser;
    this.typePayments = typePayments;
    this.banksAccount = banksAccount;
    this.typeMovementSelected = typeMovementSelected;

    this.addRowDepartment();
    this.addRowMovementType();
    this.addRowMovementDate();
    this.addRowImporto();
    this.addRowTipoPagamento();
    this.addDynamicRowsByMovementType();
    this.addRowCreator();
    this.addRowId();

    return [{rows: this.rows, order: 1}];
  }

  private static addRowDepartment() {
    this.rows.push({
      classToUse: this.classToCall,
      key: "reparto",
      controlType: ControlType.dropdown,
      label: "Reparto",
      order: 1,
      default: this.department,
      options: this.departments,
      class: "lg:w-4/12",
      customAction: "departmentChanged",
      required: true,
      disabled: !this.isAdminUser && !this.isAccountingUser && !this.isNewMovement,
    });
  }

  private static addRowMovementType() {
    this.rows.push({
      classToUse: this.classToCall,
      key: "tipoMovimento",
      controlType: ControlType.dropdown,
      label: "Tipo Movimento",
      order: 2,
      default: "ENTRATA",
      class: "lg:w-4/12",
      required: true,
      customAction: "typeMovementChanged",
      options: this.movementTypes,
    });
  }

  private static addRowMovementDate() {
    this.rows.push({
      classToUse: this.classToCall,
      key: "dataMovimento",
      controlType: ControlType.date,
      label: "Data",
      default: new Date(),
      maxDate: !this.isAdminUser && !this.isAccountingUser,
      order: 3,
      class: "lg:w-4/12",
      required: true,
    });
  }

  private static addDynamicRowsByMovementType() {
    switch (this.typeMovementSelected) {
      case "ENTRATA":
        this.rows.push(...this.getFieldsTypePaymentEntry());
        break;
      case "USCITA":
        this.rows.push(...this.getFieldsTypePaymentOutput());
        break;
      case "VERSAMENTO":
        this.rows.push(...this.getFieldsTypePaymentPayment());
        break;
    }

    console.log(this.rows);
  }

  private static addRowCreator() {
    this.rows.push({
      classToUse: this.classToCall,
      key: "creator",
      controlType: this.isNewMovement ? ControlType.hidden : ControlType.textInput,
      label: "Utente",
      order: 4,
      class: "w-2/12",
      disabled: true,
    });
  }

  private static addRowId() {
    this.rows.push({
      classToUse: this.classToCall,
      key: "id",
      controlType: this.isNewMovement ? ControlType.hidden : ControlType.textInput,
      label: "Id",
      order: 5,
      class: "w-2/12",
      disabled: true,
    });
  }

  private static getFieldsTypePaymentEntry() {
    return [
      {
        classToUse: this.classToCall,
        key: "abbuono",
        controlType: ControlType.textInput,
        label: "Abbuono",
        order: 8,
        class: "lg:w-4/12",
      },
      {
        classToUse: this.classToCall,
        key: "numeroAssegno",
        controlType: ControlType.textInput,
        label: "Numero assegno",
        order: 8,
        class: "lg:w-4/12",
        disabled: true,
      },
      {
        classToUse: this.classToCall,
        key: "banca",
        controlType: ControlType.textInput,
        label: "Banca",
        order: 9,
        class: "lg:w-4/12",
        disabled: true,
      },
      {
        classToUse: this.classToCall,
        key: "numeroContrattoFinanziamento",
        controlType: ControlType.textInput,
        label: "Numero Contratto Finanziamento",
        order: 9,
        class: "lg:w-4/12",
      },
      {
        classToUse: this.classToCall,
        key: "codice",
        controlType: ControlType.textInput,
        label: "Codice Cliente",
        order: 9,
        class: "lg:w-4/12",
      },
      {
        classToUse: this.classToCall,
        key: "descrizioneMovimento",
        controlType: ControlType.textInput,
        label: "Nome Cognome/Ragione Sociale",
        order: 5,
        class: "w-4/12",
      },
      {
        classToUse: this.classToCall,
        key: "numeroPratica",
        controlType: ControlType.textInput,
        label: "Numero Pratica",
        order: 9,
        class: "lg:w-4/12",
      },
      {
        classToUse: this.classToCall,
        key: "targa",
        controlType: ControlType.textInput,
        label: "Targa",
        order: 9,
        class: "lg:w-4/12",
      },
      {
        classToUse: this.classToCall,
        key: "telaio",
        controlType: ControlType.textInput,
        label: "Telaio",
        order: 9,
        class: "lg:w-4/12",
      },
      {
        classToUse: this.classToCall,
        key: "note",
        controlType: ControlType.textArea,
        label: "Note",
        order: 5,
        class: "w-full",
      }
    ];
  }

  private static getFieldsTypePaymentOutput() {
    return [
      {
        classToUse: this.classToCall,
        key: "descrizioneMovimento",
        controlType: ControlType.textInput,
        label: "Descrizione",
        order: 6,
        class: "w-8/12",
        required: true,
      },
    ];
  }

  private static getFieldsTypePaymentPayment() {
    return [
      {
        classToUse: this.classToCall,
        key: "bancaVersamento",
        controlType: "autocomplete-bank-payment",
        label: "Banca Versamento",
        order: 6,
        class: "lg:w-4/12",
        customAction: "updateDescrizioneMovimento",
        options: this.banksAccount,
      },
      {
        classToUse: this.classToCall,
        key: "descrizioneMovimento",
        controlType: ControlType.textInput,
        label: "Descrizione",
        order: 5,
        class: "w-full",
        disabled: true,
      },
      {
        classToUse: this.classToCall,
        key: "note",
        controlType: ControlType.textArea,
        label: "Note",
        order: 5,
        class: "w-full",
      },
    ];
  }


  private static addRowImporto() {
    this.rows.push({
      classToUse: this.classToCall,
      key: "importo",
      controlType: ControlType.number,
      label: "Importo",
      order: 5,
      class: "lg:w-4/12",
      required: true,
    });
  }

  private static addRowTipoPagamento() {
    this.rows.push({
      classToUse: this.classToCall,
      key: "tipoPagamento",
      controlType: ControlType.dropdown,
      label: "Tipo pagamento",
      order: 6,
      class: "lg:w-4/12",
      options: this.typePayments,
      customAction: "typePaymentChange",
      required: true,
    });
  }
}
