import {ControlBase} from "@components/dynamic-form/control-base";
import {FormSetting} from "@/form-settings/form-setting";
import {ControlType} from "@components/dynamic-form/control-type";
import {UploadControl} from "@components/dynamic-form/upload-input/upload-control";
import {
  FormUploadInsuranceFileComponent
} from "@pages/insurances/insurance-detail/dialog-upload-insurance-document/form-upload-file-insurance/form-upload-insurance-file.component";

export class UploadInsuranceFileFormMeta extends FormSetting {
  private static rows: ControlBase[] = [];

  public static getMeta(): Array<any> {
    this.rows = [];

    this.addRowId();
    this.addRowFile();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowId() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = 'insuranceId';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Id';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowFile() {
    const inputsComponent = {} as UploadControl;
    inputsComponent.key = 'file';
    inputsComponent.controlType = ControlType.uploadFile;
    inputsComponent.label = 'File';
    inputsComponent.class = "w-full";
    inputsComponent.required = true;
    inputsComponent.accept = 'application/pdf';
    inputsComponent.uploadButton = 'Carica File';
    inputsComponent.multiple = false;
    inputsComponent.classToUse = FormUploadInsuranceFileComponent.name;
    inputsComponent.customAction = 'onFileSelected';

    this.rows.push(inputsComponent);
  }
}
