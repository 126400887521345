export class ActionPracticesModel {
  public id: number;
  public codiceAzione: string;
  public descrizione: string;
  public marca: string;
  public dataScadenza: Date;
  public conteggiaAzione: boolean;
  public volkswagenBank: boolean;
  public enabled: boolean;
  public creator: string;
  public created: Date;
  public negativa: boolean;
  public modifier: string;
  public modified: Date;
}
