import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {FormSetting} from "@/form-settings/form-setting";
import {DateControl} from "@components/dynamic-form/date-control";

export class RentalPracticeFormMeta extends FormSetting {
  protected static classToCall: string
  private static rows: ControlBase[] = [];

  public static getMeta(): Array<any> {
    this.rows = [];

    this.addRowId();
    this.addRowTipoPratica();
    this.addRowCodicePratica();
    this.addRowCanale();
    this.addRowCategoriaVeicolo();
    this.addRowMarca();
    this.addRowModello();
    this.addRowTarga();
    this.addRowCanoneTotale();
    this.addRowDataMad();
    this.addRowDataScadenza();
    this.addRowValoreVeicolo();
    this.addRowKmContratto();
    this.addRowCostoEccedenzaKm();
    this.addRowStatoContratto();
    this.addRowTipoVetturaAziendale();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowValoreVeicolo() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'valoreVeicolo';
    inputComponent.controlType = ControlType.number;
    inputComponent.label = 'Valore veicolo';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowCanoneTotale() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'canoneTotale';
    inputComponent.controlType = ControlType.number;
    inputComponent.label = 'Canone Costo';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowCategoriaVeicolo() {
    const inputComponent = {} as DropdownControl;
    inputComponent.key = 'categoriaVeicolo';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Categoria Veicolo';
    inputComponent.class = "w-3/12";
    inputComponent.disabled = true;
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowCostoEccedenzaKm() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'costoEccedenzaKm';
    inputComponent.controlType = ControlType.number;
    inputComponent.label = 'Costo Eccedenza Km';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowDataMad() {
    const inputComponent = {} as DateControl;
    inputComponent.key = 'dataMad';
    inputComponent.controlType = ControlType.date;
    inputComponent.label = 'Data Inizio';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowDataScadenza() {
    const inputComponent = {} as DateControl;
    inputComponent.key = 'dataScadenza';
    inputComponent.controlType = ControlType.date;
    inputComponent.label = 'Data Scadenza';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowId() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'id';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.disabled = true;
    inputComponent.label = 'ID';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowKmContratto() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'kmContratto';
    inputComponent.controlType = ControlType.number;
    inputComponent.label = 'Km Contratto';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowMarca() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'marca';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Marca';
    inputComponent.class = "w-3/12";
    inputComponent.disabled = true;
    inputComponent.isObject = true;
    inputComponent.objectKeys = ['codiceMarca', 'descrizioneMarca'];
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowModello() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'modello';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Modello';
    inputComponent.class = "w-6/12";
    inputComponent.disabled = true;
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowTipoPratica() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'tipoPratica';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.disabled = true;
    inputComponent.label = 'Tipo pratica';
    inputComponent.class = "w-3/12";
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowCodicePratica() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'codicePratica';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.disabled = true;
    inputComponent.label = 'Numero pratica';
    inputComponent.class = "w-3/12";
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowCanale() {
    const inputComponent = {} as DropdownControl;
    inputComponent.key = 'canale';
    inputComponent.controlType = ControlType.dropdown;
    inputComponent.options = [
      {key: 'VENDITE', value: 'VENDITE'},
      {key: 'OFFICINA', value: 'OFFICINA'},
    ]
    inputComponent.label = 'Canale';
    inputComponent.class = "w-3/12";
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowStatoContratto() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'statoContratto';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Stato Contratto';
    inputComponent.disabled = true;
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowTipoVetturaAziendale() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'tipoVetturaAziendale';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Veicoli aziendali';
    inputComponent.disabled = true;
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowTarga() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'targa';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Targa';
    inputComponent.class = "w-3/12";
    inputComponent.disabled = true;
    this.rows.push(inputComponent);
  }
}
