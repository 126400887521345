import {OptionSelect} from "@/models/option-select.model";

export class MetaEarnWarehouse {

  public static getMeta(
    classToCall: string,
    department: string,
    departments: OptionSelect[] = [],
    isAccountingUser: boolean = false,
    isNewMovement: boolean = false,
    isAdminUser: boolean = false,
    typePayments: OptionSelect[] = [],
  ) {
    return [
      {
        "rows": [
          {
            "classToUse": classToCall,
            "key": "reparto",
            "controlType": "dropdown",
            "label": "Reparto",
            "order": 1,
            "default": department,
            "options": departments,
            "class": "w-4/12",
            "customAction": "departmentChanged",
            "required": true,
            "disabled": !isAdminUser && !isAccountingUser && !isNewMovement
          },
          {
            "classToUse": classToCall,
            "key": "tipoMovimento",
            "controlType": "textinput",
            "label": "Tipo Movimento",
            "order": 2,
            "default": "ENTRATA",
            "class": "w-4/12",
            "required": true,
            "disabled": true,
          },
          {
            "classToUse": classToCall,
            "key": "dataMovimento",
            "controlType": "date",
            "default": new Date(),
            "label": "Data",
            "maxDate": !isAdminUser && !isAccountingUser,
            "order": 1,
            "class": "w-4/12",
            "required": true,
          },
          {
            "classToUse": classToCall,
            "key": "descrizioneMovimento",
            "controlType": "textinput",
            "label": "Nome Cognome/Ragione Sociale",
            "order": 1,
            "class": "w-4/12",
            "required": true,
          },
          {
            "classToUse": classToCall,
            "key": "importo",
            "controlType": "number",
            "label": "Importo",
            "order": 1,
            "class": "w-4/12",
            "required": true,
          },
          {
            "classToUse": classToCall,
            "key": "tipoPagamento",
            "controlType": "dropdown",
            "label": "Tipo pagamento",
            "order": 2,
            "customAction": "typePaymentChange",
            "required": true,
            "class": "w-4/12",
            "options": typePayments
          },
          {
            "classToUse": classToCall,
            "key": "numeroAssegno",
            "controlType": "textinput",
            "label": "Numero assegno",
            "order": 1,
            "class": "w-4/12",
            "disabled": true
          },
          {
            "classToUse": classToCall,
            "key": "banca",
            "controlType": "textinput",
            "label": "Banca",
            "order": 1,
            "class": "w-4/12",
            "disabled": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": classToCall,
            "key": "creator",
            "controlType": !isNewMovement ? "textinput" : "hidden",
            "label": "Utente",
            "order": 1,
            "class": "w-4/12",
            "disabled": true
          },
          {
            "classToUse": classToCall,
            "key": "id",
            "controlType": !isNewMovement ? "textinput" : "hidden",
            "label": "Id",
            "order": 1,
            "class": "w-4/12",
            "disabled": true
          },
        ],
        "order": 1
      },
    ]
  }
}
