import {TableColumnsSetting} from "@/interfaces/table-setting";
import {of} from "rxjs";
import {map} from "rxjs/operators";
import {UsedVehicleService} from "@services/used-vehicle.service";
import {UsedVehicleModel} from "@/models/usedVehicleModel";

export class UsedVehiclesSettings {
  public static getColumnsSetting(usedVehicleService: UsedVehicleService): TableColumnsSetting<keyof UsedVehicleModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "btnDownloadModelRetirementSheet",
        customButton: true,
        unsortable: true,
        index: 0,
        condition: function () {
          return true;
        }
      },
      {
        name: "btnChangeStateNotRetire",
        customButton: true,
        unsortable: true,
        index: 1,
        condition: function (row: UsedVehicleModel) {
          return row.stato === 'DA_RITIRARE';
        }
      },
      {
        name: 'id',
        text: 'id',
        filter: {
          type: "input"
        }
      },
      {
        name: 'stato',
        text: 'stato',
        filter: {
          type: 'select',
          options: of([
            {
              DA_RITIRARE: 'DA_RITIRARE',
            },
            {
              RITIRATO: 'RITIRATO',
            },
            {
              NON_PIU_RITIRATO: 'NON_PIU_RITIRATO',
            }
          ])
        }
      },
      {
        name: 'tipoUsato',
        text: 'tipo ritiro',
        filter: {
          type: "select",
          options: of([
            {
              RITIRO_DA_NUOVO: 'RITIRO_DA_NUOVO',
            },
            {
              RITIRO_DA_USATO: 'RITIRO_DA_USATO',
            },
            {
              AZIENDALE: 'AZIENDALE',
            },
            {
              ACQUISTO_DIRETTO_VGI: 'ACQUISTO_DIRETTO_VGI',
            },
            {
              ACQUISTO_DIRETTO_ALTRI_CANALI: 'ACQUISTO_DIRETTO_ALTRI_CANALI'
            }
          ])
        }
      },
      {
        name: 'cliente',
        text: 'cliente',
        filter: {
          type: 'input',
        }
      },
      {
        name: 'marca',
        text: 'marca',
        filter: {
          type: "select",
          options: usedVehicleService.getBrands().pipe(map(brands => brands.map(({
                                                                                   id,
                                                                                   codiceMarca,
                                                                                   descrizioneMarca
                                                                                 }) => ({
            [id]: `${codiceMarca} - ${descrizioneMarca}`
          }))))
        }
      },
      {
        name: 'modello',
        text: 'modello',
        filter: {
          type: 'input'
        }
      },
      {
        name: "targa",
        text: 'targa',
        filter: {
          type: 'input'
        }
      },
      {
        name: "importo",
        text: 'importo',
        type: "currency"
      },
      {
        name: 'autorizzato',
        text: 'autorizzato da',
        filter: {
          type: 'input'
        }
      },
      {
        name: 'dataPresuntoRitiro',
        type: 'date',
        text: 'data presunta ritiro',
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "dataRitiro",
        type: "date",
        text: 'data ritiro',
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "speseUsato",
        text: "spese usato",
        type: "checkbox",
        filter: {
          type: "select",
          options: of([
            {
              true: 'si',
            },
            {
              false: 'no'
            }
          ])
        }
      },
      {
        name: "creator",
        text: "creato da",
        filter: {
          type: "input"
        }
      },
      {
        name: "created",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      }
    ]
  }
}
