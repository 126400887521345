import {Component, Input} from '@angular/core';
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {MatDialogRef} from "@angular/material/dialog";
import {
  UploadInsuranceFileFormMeta
} from "@pages/insurances/insurance-detail/dialog-upload-insurance-document/form-upload-file-insurance/upload-insurance-file-form-meta";
import {InsuranceService} from "@services/data-source-tables/insurances/insurance.service";
import {
  DialogUploadInsuranceDocumentComponent
} from "@pages/insurances/insurance-detail/dialog-upload-insurance-document/dialog-upload-insurance-document.component";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-form-upload-insurance-file',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class FormUploadInsuranceFileComponent extends DynamicFormComponent {
  encType: string = 'multipart/form-data'
  @Input() selectedPractices: Array<RentalPracticeModel> = [];
  @Input() dialogRef: MatDialogRef<DialogUploadInsuranceDocumentComponent>;
  private file: File;

  constructor(protected userService: UserService, protected toastr: ToastrService, protected insuranceService: InsuranceService) {
    super(toastr, userService)
    this.submitTopButton = false
    this.submitBottomButton = true
    this.submitButtonVisible = true
    this.primaryButton = `Carica Documento`
    this.secondaryBottomButton = `Annulla`

    Registry.getInstance().set(FormUploadInsuranceFileComponent.name, this);

    this.setupMeta()
  }

  onFileSelected(fileInput: any, index = null) {
    this.file = <File>fileInput.target.files[0]
  }

  setupMeta(): void {
    this.meta = UploadInsuranceFileFormMeta.getMeta()
  }

  onSubmit() {
    this.submitButtonLoading = true;

    const formData = new FormData();
    formData.append('insuranceId', this.form.get('insuranceId')?.value);
    formData.append('file', this.file);

    this.insuranceService.uploadInsuranceFile(formData).subscribe({
      next: (response) => {
        this.toastr.success('Documento caricato con successo!');

        this.bottomButtonAction()
      },
      error: (error) => {
        this.toastr.error('Errore durante il caricamento del documento!');
        this.submitButtonLoading = false;
      }
    });
  }

  bottomButtonAction() {
    this.submitButtonLoading = false;
    this.dialogRef.close();
  }
}
