import {Component} from '@angular/core';
import {InsuranceService} from "@services/data-source-tables/insurances/insurance.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {InsuranceModel} from "@/models/insurances/insurance.model";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {faEye, faFilePdf, faPause, faPlus, faToggleOn, faUser} from "@fortawesome/free-solid-svg-icons";
import {
  DataSourceAssociationInsuranceRentPracticeTable
} from "@components/new-table/data-sources/data-source-association-insurance-rent-practice-table";
import {ConnectionsInsuranceRentPractice} from "@/table-settings/insurances/connections-insurance-rent-practice";
import {CustomButtonInterface} from "@components/new-table/custom-button-interface";
import {RentalInsuranceService} from "@services/data-source-tables/rentals/rental-insurance.service";
import {AssociationsInsuranceRentPracticeModel} from "@/models/insurances/associations-insurance-rent-practice-model";
import {
  DialogLinkInsuranceToVehicleComponent
} from "@pages/insurances/insurance-detail/dialog-link-insurance-to-vehicle/dialog-link-insurance-to-vehicle.component";
import {LinkInsuranceVehicleDataModel} from "@/models/rentals/link-insurance-vehicle-data-model";
import {Registry} from "@/registry.service";
import {
  StateMovementInsuranceEnum
} from "@pages/rental-management/rent-practice-detail/rental-insurance-list/state-movement-insurance-enum";
import {DialogLoadingComponent} from "@components/dialog-loading/dialog-loading.component";
import {
  DialogUploadInsuranceDocumentComponent
} from "@pages/insurances/insurance-detail/dialog-upload-insurance-document/dialog-upload-insurance-document.component";
import {saveAs} from "file-saver";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {
  DialogModifyAssociationComponent
} from "@pages/insurances/insurance-detail/dialog-modify-association/dialog-modify-association.component";

@Component({
  selector: 'app-insurance-detail',
  templateUrl: './insurance-detail.component.html',
  styleUrls: ['./insurance-detail.component.scss']
})
export class InsuranceDetailComponent {
  displayedColumns = ConnectionsInsuranceRentPractice.getColumnsSetting()
  dataSource = null;
  reloadTrigger$ = new Subject<any>();
  protected data: InsuranceModel;
  private id: number;
  tabs: any[] = [];
  customButtons: CustomButtonInterface[];
  protected readonly faIcon = faUser;
  protected readonly faPlus = faPlus;
  private dialogLinkInsurance: MatDialogRef<DialogLinkInsuranceToVehicleComponent, any>;
  private dialogLoading: MatDialogRef<DialogLoadingComponent, any>;
  protected readonly faFilePdf = faFilePdf;
  private dialogModifyAssociation: any;

  constructor(private insuranceService: InsuranceService, private rentalInsuranceService: RentalInsuranceService, private route: ActivatedRoute, private dialog: MatDialog, private toastr: ToastrService, private router: Router) {
    this.data = {} as InsuranceModel;
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];

      this.dataSource = new DataSourceAssociationInsuranceRentPracticeTable(this.insuranceService).setInsuranceId(this.id);
      this.loadInsurance(this.id)
    });
    this.customButtons = [
      {
        btnCustomToolTip: 'Modifica associazione',
        btnCustomFaIcon: faEdit,
        btnCustomColor: 'primary',
        btnCustomShowIcon: true,
        btnCustomClickEvent: (element: AssociationsInsuranceRentPracticeModel) => this.openDialogModifyAssociation(element),
      },
      {
        btnCustomToolTip: 'Dettaglio Veicolo',
        btnCustomFaIcon: faEye,
        btnCustomColor: 'primary',
        btnCustomShowIcon: true,
        btnCustomClickEvent: (element: AssociationsInsuranceRentPracticeModel) => this.viewRentalPracticeDetail(element),
      },
      {
        btnCustomToolTip: 'Sospendi Assicurazione',
        btnCustomFaIcon: faPause,
        btnCustomColor: 'warning',
        btnCustomShowIcon: true,
        btnCustomClickEvent: (element: AssociationsInsuranceRentPracticeModel) => this.suspendInsurance(element.rentalPractice.id, element.insurance.id, element),
      },
      {
        btnCustomToolTip: 'Attiva Assicurazione',
        btnCustomFaIcon: faToggleOn,
        btnCustomColor: 'success',
        btnCustomShowIcon: true,
        btnCustomClickEvent: (element: AssociationsInsuranceRentPracticeModel) => this.activeInsurance(element.rentalPractice.id, element.insurance.id, element),
      }
    ];
  }

  protected linkInsurance() {
    this.dialogLinkInsurance = this.dialog.open(DialogLinkInsuranceToVehicleComponent, {
      data: {insuranceId: this.id, dialogRef: this.dialogLinkInsurance},
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.dialogLinkInsurance.afterClosed().subscribe({
      next: () => {
        this.reloadTrigger$.next();
      }
    });
  }

  showButtonNewAssociation() {
    return !(this.data.tipoAssicurazione === 'SINGOLA' && (Registry.getInstance().get('insuranceActive') ?? false));
  }

  private viewRentalPracticeDetail(insurance: AssociationsInsuranceRentPracticeModel) {
    this.router.navigate([`rental-practices/detail-practice/${insurance.rentalPractice.id}`])
  }

  protected loadInsurance(id: number) {
    this.insuranceService.getInsuranceById(id).subscribe({
      next: (insurance: InsuranceModel) => {
        this.data = insurance;

        if (insurance.tipoAssicurazione === 'TARGA_PROVA') {
          this.tabs = [
            {title: 'Dettaglio', faIcon: faUser},
          ];
        } else {
          this.tabs = [
            {title: 'Dettaglio', faIcon: faUser},
            {title: 'Veicoli Assicurati', faIcon: faUser},
          ];
        }
      },
      error: (error) => {
        console.error('getInsuranceById failed!');
        console.error(error);
      },
      complete: () => console.log('Request done'),
    });
  }

  private suspendInsurance(rentalPracticeId: number, insuranceId: number, associationInsurance: AssociationsInsuranceRentPracticeModel) {
    const linkData = {} as LinkInsuranceVehicleDataModel;
    linkData.dataInizio = associationInsurance.dataInizio;
    linkData.dataFine = associationInsurance.dataFine;
    linkData.premioNetto = associationInsurance.premioNetto;
    linkData.stato = StateMovementInsuranceEnum.SOSPESA;
    this.dialogLoading = this.dialog.open(DialogLoadingComponent, {
      data: 'Sospensione in corso...',
      disableClose: true,
    });

    this.rentalInsuranceService.suspendInsurance(rentalPracticeId, insuranceId, linkData).subscribe({
      next: () => {
        this.dialogLoading.close();
        this.toastr.success('Assicurazione sospesa correttamente!');
        this.reloadTrigger$.next();
      }
    });
  }

  private activeInsurance(rentalPracticeId: number, insuranceId: number, associationInsurance: AssociationsInsuranceRentPracticeModel) {
    const linkData = {} as LinkInsuranceVehicleDataModel;
    linkData.dataInizio = associationInsurance.dataInizio;
    linkData.dataFine = associationInsurance.dataFine;
    linkData.premioNetto = associationInsurance.premioNetto;
    linkData.stato = StateMovementInsuranceEnum.ATTIVA;
    this.dialogLoading = this.dialog.open(DialogLoadingComponent, {
      data: 'Attivazione in corso...',
      disableClose: true,
    });


    this.rentalInsuranceService.activeInsurance(rentalPracticeId, insuranceId, linkData).subscribe({
      next: () => {
        this.dialogLoading.close();
        this.toastr.success('Assicurazione attivata correttamente!');
        this.reloadTrigger$.next();
      }
    });
  }

  private dialogUploadFile: MatDialogRef<unknown, any>;

  showButtonUploadFile(): boolean {
    return true;
  }

  uploadFile() {
    this.dialogUploadFile = this.dialog.open(DialogUploadInsuranceDocumentComponent, {
      data: {insuranceId: this.id, dialogRef: this.dialogUploadFile},
      width: 'auto',
      panelClass: 'custom-modalbox',
    });
  }

  downloadFile() {
    this.insuranceService.downloadInsuranceFile(this.id).subscribe({
      next: (response) => {
        saveAs(response, this.data.file.name);
      }
    });
  }

  private openDialogModifyAssociation(element: AssociationsInsuranceRentPracticeModel) {
    this.dialogModifyAssociation = this.dialog.open(DialogModifyAssociationComponent, {
      data: {association: element, dialogRef: this.dialogModifyAssociation},
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.dialogModifyAssociation.afterClosed().subscribe({
      next: () => {
        this.reloadTrigger$.next();
      }
    });
  }
}
