import {FormSetting} from "@/form-settings/form-setting";
import {ControlType} from "@components/dynamic-form/control-type";
import {ControlBase} from "@components/dynamic-form/control-base";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {DateControl} from "@components/dynamic-form/date-control";
import {SectionDynamicForm} from "@components/dynamic-form/section-dynamic-form";

export class RentalMovementFormSetting extends FormSetting {
  private static isNew: boolean;
  private static rowsBPCompany: ControlBase[] = [];
  private static rowsUser: ControlBase[] = [];
  private static rowsRentData: ControlBase[] = [];
  private static rowsReturnData: ControlBase[] = [];
  private static practiceIsClosed: boolean;

  public static setPracticeIsClosed(practiceIsClosed: boolean) {
    this.practiceIsClosed = practiceIsClosed;

    return this;
  }

  public static setIsNew(isNew: boolean) {
    this.isNew = isNew;

    return this;
  }

  public static getMeta(): Array<any> {
    this.rowsBPCompany = [];
    this.rowsUser = [];
    this.rowsRentData = [];
    this.rowsReturnData = [];

    if (!this.isNew) {
      // Rows Fix Data
      this.addRowId();
      this.addStato();
      this.addCanale();
      this.addRowDataRientro();
      this.addRowKmRientro();
      this.addRowCarburanteRientro();
      this.addRowDanniRientro();
    }

    //Rows Other Rent Data
    this.addRowDataInizioNoleggio();
    this.addRowDataFineNoleggio();
    this.addRowDataContratto();
    this.addTypeRent();
    this.addRowPrezzo();
    this.addPaymentType();

    this.addRowLuogoContratto();
    this.addRowDataConsegna();
    this.addRowKmConsegna();
    this.addRowCarburanteConsegna();

    //Rows Company data
    this.addRowBpSocieta();
    this.addRowSocieta();
    this.addRowCittaSocieta();
    this.addRowIndirizzoSocieta();
    this.addRowCodiceFiscaleSocieta();
    this.addRowPivaSocieta();
    this.addRowEmailSocieta();
    this.addRowConsensoIndigSoddClientelaSocieta();
    this.addRowConsensoMarketingSocieta();
    this.addRowConsensoMarketingTerzePartiSocieta();
    this.addRowConsensoProfilazioneSocieta();
    this.addRowConsensoAssicurazioneSocieta();

    //Rows User data
    this.addRowBpUtilizzatore();
    this.addRowDenominazioneUtilizzatore();
    this.addRowCittaUtilizzatore();
    this.addRowIndirizzoUtilizzatore();
    this.addRowCodiceFiscaleUtilizzatore();
    this.addRowEmailUtilizzatore();
    this.addRowPatenteUtilizzatore();
    this.addRowPatenteRilasciataDaUtilizzatore();
    this.addRowConsensoIndigSoddClientelaUtilizzatore();
    this.addRowConsensoMarketingUtilizzatore();
    this.addRowConsensoMarketingTerzePartiUtilizzatore();
    this.addRowConsensoProfilazioneUtilizzatore();
    this.addRowConsensoAssicurazioneUtilizzatore();
    this.addRowTargaVeicoloCliente();
    this.addRowDataInizioLavorazioneVeicoloCliente();

    return [
      {
        "sections": this.getSections(),
        "order": 1
      },
    ]
  }

  private static getSections() {
    const sections = [] as SectionDynamicForm[];

    if (this.rowsRentData.length > 0) {
      const section = {
        "title": "Dati noleggio",
        "rows": this.rowsRentData,
        "class": "w-full"
      } as SectionDynamicForm;

      sections.push(section);
    }

    if (this.rowsReturnData.length > 0 && this.practiceIsClosed) {
      const section = {
        "title": "Dati Rientro",
        "rows": this.rowsReturnData,
        "class": "w-full"
      } as SectionDynamicForm;

      sections.push(section);
    }

    if (this.rowsBPCompany.length > 0) {
      const section = {
        "title": "Dati Societa",
        "rows": this.rowsBPCompany,
        "class": "w-6/12 pr-2"
      } as SectionDynamicForm;

      sections.push(section);
    }

    if (this.rowsUser.length > 0) {
      const section = {
        "title": "Dati Utilizzatore",
        "rows": this.rowsUser,
        "class": "w-6/12 pl-2"
      } as SectionDynamicForm;

      sections.push(section);
    }

    return sections;
  }

  private static addRowId() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'id';
    inputsComponent.disabled = true;
    inputsComponent.controlType = ControlType.textInput
    inputsComponent.label = 'ID';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addStato() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'stato';
    inputsComponent.disabled = true;
    inputsComponent.controlType = ControlType.textInput
    inputsComponent.label = 'Stato';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addCanale() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'canale';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Canale';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowCarburanteConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'carburanteConsegna';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Carburante consegna';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowCarburanteRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'carburanteRientro';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Carburante rientro';
    inputsComponent.class = "w-3/12";

    this.rowsReturnData.push(inputsComponent);
  }

  private static addRowDanniRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = 'danniRientro';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Danni Riconsegna';
    inputsComponent.class = "w-3/12";

    this.rowsReturnData.push(inputsComponent);
  }

  private static addRowBpSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'bpSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'BP Societa';
    inputsComponent.class = "w-4/12";

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'societa';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Societa';
    inputsComponent.class = "w-8/12";

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowCittaSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'cittaSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Città societa';
    inputsComponent.class = "w-4/12";

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowIndirizzoSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'indirizzoSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Indirizzo societa';
    inputsComponent.class = "w-8/12";

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowCodiceFiscaleSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'codiceFiscaleSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Codice fiscale societa';
    inputsComponent.class = "w-6/12";

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowPivaSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'pivaSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'PIVA societa';
    inputsComponent.class = "w-6/12";

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowEmailSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'emailSocieta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Email societa';
    inputsComponent.class = "w-12/12";

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowConsensoIndigSoddClientelaSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoIndigSoddClientelaSocieta';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Indig. Sodd. Clientela Societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowConsensoMarketingSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoMarketingSocieta';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Marketing Societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowConsensoMarketingTerzePartiSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoMarketingTerzePartiSocieta';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Marketing Terze Parti Societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowConsensoProfilazioneSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoProfilazioneSocieta';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Profilazione Societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowConsensoAssicurazioneSocieta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoAssicurazioneSocieta';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Assicurazione Societa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsBPCompany.push(inputsComponent);
  }

  private static addRowDataInizioNoleggio() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataInizioNoleggio';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data inizio noleggio';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowDataFineNoleggio() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataFineNoleggio';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data fine noleggio';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowDataConsegna() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataConsegna';
    inputsComponent.controlType = ControlType.dateTime;
    inputsComponent.label = 'Data consegna';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowDataContratto() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataContratto';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data contratto';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowDataRientro() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataRientro';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data rientro effettiva';
    inputsComponent.class = "w-3/12";

    this.rowsReturnData.push(inputsComponent);
  }

  private static addRowKmConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'kmConsegna';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Km consegna';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowKmRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'kmRientro';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Km rientro';
    inputsComponent.class = "w-3/12";

    this.rowsReturnData.push(inputsComponent);
  }

  private static addRowLuogoContratto() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'luogoContratto';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Luogo contratto';
    inputsComponent.class = "w-4/12";
    inputsComponent.default = 'LOZZA'

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowBpUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'bpUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'BP Utilizzatore';
    inputsComponent.class = "w-4/12";

    this.rowsUser.push(inputsComponent);
  }

  private static addRowDenominazioneUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'denominazioneUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Denominazione utilizzatore';
    inputsComponent.class = "w-8/12";
    inputsComponent.required = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowCittaUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'cittaUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Città utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowIndirizzoUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'indirizzoUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Indirizzo utilizzatore';
    inputsComponent.class = "w-8/12";
    inputsComponent.required = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowPatenteUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'patenteUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'N. Patente';
    inputsComponent.class = "w-6/12";
    inputsComponent.required = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowPatenteRilasciataDaUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'patenteRilasciataDaUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Patente rilasciata da';
    inputsComponent.class = "w-6/12";
    inputsComponent.required = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowCodiceFiscaleUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'codiceFiscaleUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Codice fiscale utilizzatore';
    inputsComponent.class = "w-6/12";
    inputsComponent.required = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowEmailUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'emailUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Email utilizzatore';
    inputsComponent.class = "w-6/12";
    inputsComponent.required = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowConsensoIndigSoddClientelaUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoIndigSoddClientelaUtilizzatore';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Indig. Sodd. Clientela Utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowConsensoMarketingUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoMarketingUtilizzatore';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Marketing Utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowConsensoMarketingTerzePartiUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoMarketingTerzePartiUtilizzatore';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Marketing Terze Parti Utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowConsensoProfilazioneUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoProfilazioneUtilizzatore';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Profilazione Utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowConsensoAssicurazioneUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'consensoAssicurazioneUtilizzatore';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Consenso Assicurazione Utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rowsUser.push(inputsComponent);
  }

  private static addRowPrezzo() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'prezzo';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Prezzo';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addTypeRent() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'tipoNoleggio';
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.label = 'Tipo Noleggio';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;
    inputsComponent.options = [
      {key: 'GRATUITO', value: 'GRATUITO'},
      {key: 'PAGAMENTO', value: 'PAGAMENTO'},
    ]

    this.rowsRentData.push(inputsComponent);
  }

  private static addPaymentType() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'tipoPagamento';
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.label = 'Tipo Pagamento';
    inputsComponent.class = "w-4/12";
    inputsComponent.options = [
      {key: 'GIORNALIERO', value: 'GIORNALIERO'},
      {key: 'MENSILE', value: 'MENSILE'},
    ]

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowTargaVeicoloCliente() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'targaVeicoloCliente';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Targa Comodatario';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }

  private static addRowDataInizioLavorazioneVeicoloCliente() {
    const inputsComponent = {} as DateControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataInizioLavorazioneVeicoloCliente';
    inputsComponent.controlType = ControlType.dateTime;
    inputsComponent.label = 'Data inizio lavorazioni';
    inputsComponent.class = "w-4/12";

    this.rowsRentData.push(inputsComponent);
  }
}
