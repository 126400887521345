import {map} from "rxjs/operators";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {BusinessMachinesService} from "@services/business-machines.service";
import {of} from "rxjs";
import {BusinessMachineModel} from "@/models/business-machine-model";

export class BusinessMachinesTableSettings {
  public static getColumnsSetting(businessMachinesService: BusinessMachinesService): TableColumnsSetting<keyof BusinessMachineModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: "id",
        filter: {
          type: "input",
        }
      },
      {
        name: "data",
        type: "date",
        text: "data",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "tipoPratica",
        text: "Tipo pratica",
        filter: {
          type: "select",
          options: of([
            {
              NUOVO: 'NUOVO',
            },
            {
              USATO: 'USATO'
            }
          ])
        }
      },
      {
        name: "codicePratica",
        text: "N Pratica",
        filter: {
          type: "input"
        }
      },
      {
        name: "tipoVetturaAziendale",
        text: "Veicoli aziendali",
        filter: {
          type: "select",
          options: of([
            {
              VA: 'VA',
            },
            {
              VN: 'VN'
            }
          ])
        }
      },
      {
        name: "noleggiabile",
        text: "Noleggiabile",
        type: "slideToggle",
        filter: {
          type: "select",
          options: of([
            {true: 'SI'},
            {false: 'NO'}
          ])
        }
      },
      {
        name: "marca",
        text: "marca",
        filter: {
          type: "select",
          options: businessMachinesService.getAllBrands().pipe(map(brands => brands.map(({
                                                                                           id,
                                                                                           codiceMarca,
                                                                                           descrizioneMarca
                                                                                         }) => ({
            [id]: `${codiceMarca} - ${descrizioneMarca}`
          }))))
        }
      },
      {
        name: "descrizioneFamiglia",
        text: "Descrizione famiglia",
        filter: {
          type: "input"
        }
      },
      {
        name: "descrizioneModello",
        text: "Descrizione modello",
        filter: {
          type: "input"
        }
      },
      {
        name: "targa",
        text: "targa",
        filter: {
          type: "input"
        }
      },
      {
        name: "dataPrimaImmatricolazione",
        type: "date",
        text: "data immatricolazione",
        filter: {
          type: "dateRange"
        }
      },
      {
        name: "grandiAcquirenti",
        text: "tipo vettura aziendale",
        filter: {
          type: "select",
          options: businessMachinesService.getAllBigBuyersVettureDimostrative().pipe(map(bigBuyers => bigBuyers.map(({
                                                                                                                       id,
                                                                                                                       marca,
                                                                                                                       codiceGA,
                                                                                                                       descrizione
                                                                                                                     }) => ({
            [id]: `${marca}: ${codiceGA} - ${descrizione}`
          }))))
        }
      },
      {
        name: "dataScadenzaAutoDemo",
        type: "date",
        text: "data scadenza auto demo",
        filter: {
          type: 'dateRange'
        }
      },
      {
        name: "assegnatarioAutoDemo",
        text: "destinatario auto demo",
        filter: {
          type: "input",
        }
      },
      {
        name: "numeroPraticaVenditaAutoAziendale",
        text: "num. pratica vendita usato",
        anchorLink: '/practices-management/practices/details/:venditaAutoAziendalePraticaId',
        filter: {
          type: "input",
        }
      },
      {
        name: "venditaAutoAziendaleCompletata",
        text: "Vendita usato completata",
        type: "checkbox",
        filter: {
          type: "select",
          options: of([
            {true: 'SI'},
            {false: 'NO'}
          ])
        }
      },
      {
        name: "creator",
        text: "creato da",
        filter: {
          type: "input",
        }
      },
      {
        name: "created",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      }
    ]
  }
}
