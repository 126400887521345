import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {InsuranceModel} from "@/models/insurances/insurance.model";

@Component({
  selector: 'app-dialog-create-cash-found',
  templateUrl: './dialog-create-cash-found.component.html',
})
export class DialogCreateCashFoundComponent {
  protected newInsurance: InsuranceModel;
  protected cashFound: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<DialogCreateCashFoundComponent>
  ) {
    this.newInsurance = new InsuranceModel();
    this.cashFound = data.cashFound;
  }
}
