export class RentalMovementModel {
  public id: number;
  public stato: string;
  public canale: string;
  public tipoNoleggio: string;
  public categoriaNoleggio: string;
  public prezzo: number;
  public carburanteConsegna: number;
  public carburanteRientro: number;
  public dataConsegna: Date;
  public dataRientro: Date;
  public kmConsegna: number;
  public kmRientro: number;
  public dataUltimaRilevazioneKm: Date;
  public kmRilevati: number;
  public bpSocieta: string;
  public societa: string;
  public indirizzoSocieta: string;
  public cittaSocieta: string;
  public pivaSocieta: string;
  public codiceFiscaleSocieta: string;
  public emailSocieta: string;
  public consensoAssicurazioneSocieta: boolean;
  public consensoIndigSoddClientelaSocieta: boolean;
  public consensoMarketingSocieta: boolean;
  public consensoMarketingTerzePartiSocieta: boolean;
  public consensoProfilazioneSocieta: boolean;
  public bpUtilizzatore: string;
  public denominazioneUtilizzatore: string;
  public codiceFiscaleUtilizzatore: string;
  public pivaUtilizzatore: string;
  public patenteUtilizzatore: string;
  public patenteRilasciataDaUtilizzatore: string;
  public indirizzoUtilizzatore: string;
  public cittaUtilizzatore: string;
  public consensoAssicurazioneUtilizzatore: boolean;
  public consensoIndigSoddClientelaUtilizzatore: boolean;
  public consensoMarketingUtilizzatore: boolean;
  public consensoMarketingTerzePartiUtilizzatore: boolean;
  public consensoProfilazioneUtilizzatore: boolean;
  public dataContratto: Date;
  public dataInizioNoleggio: Date;
  public dataFineNoleggio: Date;
  public luogoContratto: string;
  public targaVeicoloCliente: string;
  public dataInizioLavorazioneVeicoloCliente: Date;
}
