import {CustomColumnModelInterface, TableColumnsSetting} from "@/interfaces/table-setting";
import {RentalInsuranceModel} from "@/models/rentals/rental-insurance-model";
import {AssociationsInsuranceRentPracticeModel} from "@/models/insurances/associations-insurance-rent-practice-model";

export class RentalInsuranceTableSettings {

  public static getColumnsSetting(): TableColumnsSetting<keyof RentalInsuranceModel> {
    return [
      this.getCustomColumnViewDetail(),
      this.getCustomColumnPause(),
      this.getCustomColumnActive(),
      {
        name: "numeroPolizza",
        text: 'Numero Polizza'
      },
      {
        name: "dataInizio",
        text: 'Data Inizio',
        type: "date"
      },
      {
        name: "dataFine",
        text: 'Data Fine',
        type: "date"
      },
      {
        name: "premioNetto",
        text: 'Premio Netto',
        type: "currency"
      },
      {
        name: "stato",
        text: 'Stato',
      },
    ];
  }

  private static getCustomColumnPause(): CustomColumnModelInterface {
    return {
      name: "btnPause",
      index: 1,
      customButton: true,
      unsortable: true,
      condition: (element: RentalInsuranceModel) => element.stato === 'ATTIVA'
    };
  }

  private static getCustomColumnActive(): CustomColumnModelInterface {
    return {
      name: "btnActive",
      index: 2,
      customButton: true,
      unsortable: true,
      condition: (element: RentalInsuranceModel) => element.stato === 'SOSPESA' || element.stato === 'BOZZA'
    };
  }

  private static getCustomColumnViewDetail(): CustomColumnModelInterface {
    return {
      name: "btnViewDetail",
      index: 0,
      customButton: true,
      unsortable: true,
      condition: (element: AssociationsInsuranceRentPracticeModel) => true
    };
  }
}
