import {BrandModel} from "@/models/tables/brand.model";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {InsuranceModel} from "@/models/insurances/insurance.model";

export class RentalPracticeModel {
  id: number;
  activeRental: RentalMovementModel;
  praticaId: number;
  tipoPratica: string;
  codicePratica: string;
  targa: string;
  marca: BrandModel;
  famiglia: string | null;
  modello: string;
  statoContratto: string;
  statoVeicolo: string;
  categoriaVeicolo: string;
  kmContratto: number;
  dataMad: string;
  dataScadenza: string;
  canale: string;
  canoneFinanziario: number | null;
  canoneServizio: number | null;
  canoneTotale: number;
  percEsuberoKm: number | null;
  codiceCentroCosto: string | null;
  descCentroCosto: string | null;
  tipoGomme: string | null;
  costoEccedenzaKm: number;
  rimborsoKm: number | null;
  valoreVeicolo: number;
  tipoVetturaAziendale: string;
  insuranceId: number;
  creator: string;
  created: Date;
  activeInsurance: InsuranceModel;
}
