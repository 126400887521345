import {TableColumnsSetting} from "@/interfaces/table-setting";
import {InsuranceModel} from "@/models/insurances/insurance.model";
import {of} from "rxjs";

export class InsuranceTableSettings {
  public static getColumnsSetting(): TableColumnsSetting<keyof InsuranceModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "btnDelete",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: 'ID',
        filter: {
          type: "input"
        }
      },
      {
        name: "stato",
        text: 'Stato',
        filter: {
          type: "select",
          options: of([
            {
              ATTIVA: 'ATTIVA',
            },
            {
              SCADUTA: 'SCADUTA'
            },
            {
              SOSPESA: 'SOSPESA'
            }
          ])
        }
      },
      {
        name: "tipoAssicurazione",
        text: 'Tipo Assicurazione',
        filter: {
          type: "select",
          options: of([
            {
              SINGOLA: 'SINGOLA',
            },
            {
              MULTIPLA: 'MULTIPLA'
            },
            {
              TARGA_PROVA: 'TARGA_PROVA'
            }
          ])
        }
      },
      {
        name: "compagnia",
        text: 'Compagnia',
        filter: {
          type: "input"
        }
      },
      {
        name: "numeroPolizza",
        text: 'Numero Polizza',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataInizio",
        text: 'Data Inizio',
        type: "date",
        filter: {
          type: "date"
        }
      },
      {
        name: "dataFine",
        text: 'Data Fine',
        type: "date",
        filter: {
          type: "date"
        }
      },
      {
        name: "rc",
        text: 'RC',
        filter: {
          type: "input"
        }
      },
      {
        name: "ifk",
        text: 'IFK',
        filter: {
          type: "input"
        }
      },
      {
        name: "pai",
        text: 'PAI',
        filter: {
          type: "input"
        }
      },
      {
        name: "creator",
        text: "creato da",
        filter: {
          type: "input"
        }
      },
      {
        name: "created",
        type: "datetime",
        text: "creato il",
        filter: {
          type: 'dateRange'
        }
      },
    ];
  }
}
