import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DataSourceTable} from "@components/new-table/data-sources/data-source-table";
import {RentalClientsTableSettings} from "@/table-settings/rentals/rental-clients-table-settings";
import {CustomButtonInterface} from "@components/new-table/custom-button-interface";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-dialog-select-client-rent',
  templateUrl: './dialog-select-client-rent.component.html',
})
export class DialogSelectClientRentComponent {
  displayedColumns = RentalClientsTableSettings.getColumnsSetting()
  dataSource = new DataSourceTable(this.data.service);
  customButtons: CustomButtonInterface[];

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.customButtons = [
      {
        btnCustomShowIcon: false,
        btnCustomText: 'Seleziona',
        btnCustomClickEvent: this.btnSelectClientClickFunction,
      }
    ];
  }

  btnSelectClientClickFunction($event: any): void {
    if (Registry.getInstance().get('closeSelectClientEvent$') !== undefined) {
      Registry.getInstance().get('closeSelectClientEvent$').emit($event);
    }
  }
}
