import {Component, inject, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {MSTableSettings} from "@/table-settings/mstable-settings";
import {MSTableDataSource} from "@components/new-table/data-sources/mstable-data-source";
import {BiggerDiscountService} from "@services/bigger-discount.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogMSUpdateComponent} from "@pages/bigger-discount/table-ms/dialog-msupdate/dialog-msupdate.component";
import {MsDiscountModel} from "@/models/tables/ms-discount-model";
import {LoggerService} from "@services/logger.service";
import {BrandModel} from "@/models/tables/brand.model";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {MsDuplicationDataModel} from "@/models/tables/ms-duplication-data-model";
import {
  DialogMsDuplicateComponent
} from "@pages/bigger-discount/table-ms/dialog-msduplicate/dialog-ms-duplicate.component";

@Component({
  selector: 'app-table-ms',
  templateUrl: './table-ms.component.html',
  styleUrls: ['./table-ms.component.scss']
})
export class TableMSComponent implements OnInit {
  modalUpdateTrigger$ = new Subject<any>();
  modalDuplicateTrigger$ = new Subject<any>();
  triggerDate$ = new Subject<any>();
  monthSelected: number;
  yearSelected: number;
  brandIdSelected: number;
  triggerSelectionBrand$ = new Subject<any>();
  reloadTrigger$ = new Subject<any>();
  displayedColumns = MSTableSettings.getColumnsSetting();
  brandIdDefault: number;
  brands: BrandModel[];
  dialogMSUpdate: MatDialogRef<DialogMSUpdateComponent>
  triggerUpdateComponentSelectBrand$ = new Subject<any>();
  dataSource = new MSTableDataSource(this.biggerDiscountService);
  toastr = inject(ToastrService);
  brandsMeta = {
    label: 'Seleziona il brand',
    options: []
  };
  dialogDuplicate: MatDialogRef<DialogMsDuplicateComponent>;
  protected readonly faDownload = faDownload;

  constructor(private biggerDiscountService: BiggerDiscountService, private dialog: MatDialog) {
    this.brandIdDefault = 3

    this.biggerDiscountService.getAllBrands().subscribe(brands => {
      this.brands = brands
      this.brandsMeta.options = brands.map(brand => ({
        key: brand.id,
        value: brand.descrizioneMarca
      }))

      this.triggerUpdateComponentSelectBrand$.next();
    });
    this.triggerDate$.subscribe((date: any) => {
      this.monthSelected = date.month;
      this.yearSelected = date.year;
    });
    this.triggerSelectionBrand$.subscribe((brandId: number) => {
      this.brandIdSelected = brandId;
    });
  }

  ngOnInit(): void {
    this.modalUpdateTrigger$.subscribe({
      next: () => {
        this.dialogMSUpdate.close()
        this.reloadTrigger$.next(1)
      }
    });
  }

  btnDetailsClick($event: MsDiscountModel) {
    this.dialogMSUpdate = this.dialog.open(DialogMSUpdateComponent,
      {
        data: {
          msData: $event,
          modalTrigger: this.modalUpdateTrigger$
        }
      },
    );
  }

  btnDeleteClick({id: biggerDiscountId}: TablePracticeModel) {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deleteBiggerDiscount => {
      if (deleteBiggerDiscount) {
        this.biggerDiscountService.deleteBiggerDiscount(biggerDiscountId).subscribe({
          next: () => {
            this.reloadTrigger$.next(1);
            this.toastr.success('Maggiore sconto cancellato correttamente!');
          },
          error: (error) => {
            this.toastr.error(`Errore durante la cancellazione del maggiore sconto.`);

            LoggerService.error('deleteMS failed!')
            LoggerService.error(error);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  changeTransmission($event: any) {
    let msDiscountModel = <MsDiscountModel>$event

    this.biggerDiscountService.saveBiggerDiscount(msDiscountModel).subscribe({
      next: (response) => {
        this.toastr.success('Maggiore Sconto salvato correttamente!');
      },
      error: (error) => {
        LoggerService.error('saveBiggerDiscount failed!');
        LoggerService.error(error);
      },
      complete: () => {
        this.reloadTrigger$.next(1)
      },
    })
  }

  duplicateMonthMS() {
    const brandId = this.brandIdSelected ?? this.brandIdDefault
    const duplicationData = {
      marca: this.brands.find(brand => brand.id === brandId),
    } as MsDuplicationDataModel

    this.dialogDuplicate = this.dialog.open(DialogMsDuplicateComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
      data: {
        selection: duplicationData,
        modalTrigger: this.modalDuplicateTrigger$
      }
    });

    this.modalDuplicateTrigger$.subscribe({
      next: () => {
        this.dialogDuplicate.close()
        this.reloadTrigger$.next(1)
      }
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questo maggiore sconto?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellato il maggiore sconto non sarà più possibile recuperarlo."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
