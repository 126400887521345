import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {PracticesService} from "@services/practices.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {PracticeDocument} from "@/models/practice-document.model";

@Component({
  selector: 'app-button-document-delete',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonDeleteDocumentComponent extends ButtonComponent implements OnInit {
  faIcon = faTrashCan;
  private document: PracticeDocument;
  private rowData: any;

  constructor(protected router: Router, public dialog: MatDialog, private practiceService: PracticesService, protected toastr: ToastrService) {
    super(router, toastr);

    this.color = 'danger';
    this.additionalClass = 'py-0.5 px-2.5'
    this.showIcon = true;
    this.smallBtn = true;
  }

  ngOnInit() {
    this.document = this.rowData;
  }

  /**
   * Delete user
   */
  public actionClick(): void {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deleteDocument => {
      if (deleteDocument) {
        this.practiceService.deleteDocument(this.document.id).subscribe((res) => {
          this.toastr.success('Documento cancellata correttamente!');

          Registry.getInstance().get('PracticeDetailsComponent').refreshDocuments()
        });
      }
    });
  }


  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questo documento?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellato il documento non sarà più possibile recuperarlo."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
