<h1 class="text-dark text-xl" mat-dialog-title>{{ isNew ? 'Nuovo noleggio' : 'Modifica noleggio' }}</h1>
<h2 class="text-dark text-base" mat-dialog-title>Pratica N. {{ data.practiceCode }}
  ~ Targa: {{ data.plate }}
  ~ Brand: {{ data.brand.descrizioneMarca }}
  ~ Modello: {{ data.model }}</h2>
<div class="w-full" mat-dialog-content>
  <div class="w-full flex pr-3">
    <app-button (btnClicked)="downloadPdfVehiclePickUp()" *ngIf="!isNew"
                [color]="'download'"
                [content]="'Scarica PDF Consegna Veicolo'"
                [faIcon]="iconPdf"
                [loading]="loading" [showIcon]="true" class="mb-4 ml-auto mr-2"></app-button>
    <app-button (btnClicked)="downloadPdfVehicleDropOff()" *ngIf="!isNew && movement.stato === 'TERMINATO'"
                [color]="'download'"
                [content]="'Scarica PDF Riconsegna Veicolo'"
                [faIcon]="iconPdf"
                [loading]="loading" [showIcon]="true" class="mb-4 mr-2"></app-button>
    <app-button (btnClicked)="downloadPdfRentalB2b()" *ngIf="!isNew && movement.canale === 'VENDITE'"
                [color]="'download'"
                [content]="'Scarica PDF Noleggio B2B'"
                [faIcon]="iconPdf"
                [loading]="loading" [showIcon]="true" class="mb-4 mr-0"></app-button>
  </div>
  <app-rental-movement-form
    [closeButton]="'Annulla'"
    [closeDialogRentalMovementEvent$]="data.closeDialogRentalMovementEvent$"
    [data]="data.movement"
    [practiceId]="data.practiceId"
    [refreshRentalPracticeEvent$]="data.refreshRentalPracticeEvent$"></app-rental-movement-form>
</div>
