import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-form-rental-insurance',
  templateUrl: './dialog-form-rental-insurance.component.html',
})
export class DialogFormRentalInsuranceComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogFormRentalInsuranceComponent>
  ) {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
