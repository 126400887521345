import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";

@Component({
  selector: 'app-dialog-form-return-rental-movement-component',
  templateUrl: './dialog-form-return-rental-movement-component.component.html',
})
export class DialogFormReturnRentalMovementComponentComponent {
  protected loading: boolean = false;
  protected practiceId: number;
  protected closeDialogTrigger$: Subject<any>;
  protected refreshRentalPracticeEvent$: Subject<any>;
  protected rentalMovement: RentalMovementModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.rentalMovement = data.rentalMovement;
    this.practiceId = data.practiceId;
    this.closeDialogTrigger$ = data.closeDialogTrigger$;
    this.refreshRentalPracticeEvent$ = data.refreshRentalPracticeEvent$;
  }

}
