import {Component, Input} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {PracticesService} from "@services/practices.service";
import {ToastrService} from "ngx-toastr";
import {OptionSelect} from "@/models/option-select.model";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {UserService} from "@services/admin/user.service";
import {MultipleDocumentForm} from "@/form-settings/multiple-document-form";
import {FormArray} from "@angular/forms";
import {PracticeMultipleDocument} from "@/models/practice-multiple-document.model";
import {Subject} from "rxjs";
import {
  StaticsDataComponents
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-upload-multiple-document/statics/statics-data-components";

@Component({
  selector: 'app-multiple-document-form',
  templateUrl: '../../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class DocumentMultipleFormComponent extends DynamicFormComponent {
  @Input() public triggerProgressBar: Subject<any>;
  encType: string = 'multipart/form-data'
  protected categoryOptions: OptionSelect[] = [];
  private fileList: File[] = [];

  constructor(protected router: Router, protected userService: UserService, protected practiceService: PracticesService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Carica'
    this.secondaryBottomButton = 'Chiudi'

    this.practiceService.getDocumentCategories().subscribe({
      next: categories => {
        StaticsDataComponents.setCategories(categories);

        for (const category of categories) {
          this.categoryOptions.push({
            key: category.code,
            value: category.description,
          });
        }
      },
      error: error => {
        LoggerService.error(`getDocumentCategories failed: ${error}`);
      },
    });

    Registry.getInstance().set('DocumentMultipleFormComponent', this)
  }

  async onSubmit() {
    const formValues = <PracticeMultipleDocument>this.form.getRawValue()
    const totalFiles = formValues.documents.length;

    if (totalFiles === 0) {
      this.toastr.error('Nessun documento caricato!');

      return;
    }

    for (let index = 0; index < totalFiles; index++) {
      const control = formValues.documents[index];
      const idFile = this.generateUniqueNumericID(control.file);
      const formData = new FormData();
      formData.append('praticaId', String(this.data.praticaId));
      formData.append('file', this.fileList[idFile]);
      formData.append('tipologiaDocumentoPratica', control.tipologiaDocumentoPratica);

      try {
        await this.practiceService.uploadPracticeDocument(formData).toPromise();
        this.toastr.success('Documenti salvati correttamente!');
        LoggerService.log('Request done');
        Registry.getInstance().set('saveInProgress', false);

        const progress = Math.round(((index + 1) / totalFiles) * 100);
        this.triggerProgressBar.next({'showBar': true, 'progress': progress});

        if (progress === 100) {
          setTimeout(() => {
            Registry.getInstance().get('PracticeDetailsComponent').closeDocumentMultipleDialog();
            this.triggerProgressBar.next({'showBar': false, 'progress': progress});

            this.fileList = [];
          }, 3000);
        }
      } catch (error) {
        LoggerService.error('uploadPracticeDocument failed!');
        LoggerService.error(error);
        this.toastr.error('Errore durante il caricamento del documento.');
        Registry.getInstance().set('saveInProgress', false);
        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false);
        }, 1000);
      }
    }
  }

  onFileMultipleSelected(fileInput: any, index = null) {
    const files = <File>fileInput.target.files

    Array.prototype.forEach.call(files, (file, index) => {
      this.triggerMethodSubject.next({'fun': 'addNewRow', 'key': 'multipleFiles'});

      const fileControlsArray = this.form.controls['documents'] as FormArray;

      const nameFile = file.name;
      const lastIndex = fileControlsArray.controls.length - 1;
      const fileControlGroup = fileControlsArray.controls[lastIndex] as FormArray;

      fileControlGroup.controls['file'].setValue(nameFile);

      this.checkCategoryFileByRegex(nameFile, fileControlGroup);

      const uniqueFileName = this.generateUniqueNumericID(file.name);
      this.fileList[uniqueFileName] = file;

      this.triggerMethodSubject.next({'fun': 'resetFileName', 'key': 'files'});
    });
  }

  setupMeta(): void {
    this.meta = MultipleDocumentForm
      .resetRows()
      .setClassToCall('DocumentMultipleFormComponent')
      .setCategory(this.categoryOptions)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  bottomButtonAction() {
    Registry.getInstance().get('PracticeDetailsComponent').closeDocumentMultipleDialog()
  }

  private generateUniqueNumericID(input: string) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString();
  }

  private checkCategoryFileByRegex(nameFile, fileControlGroup) {
    Array.prototype.forEach.call(StaticsDataComponents.getCategories(), (category) => {
      const regex = new RegExp(category.pattern);

      if (regex.test(nameFile)) {
        fileControlGroup.controls['tipologiaDocumentoPratica'].setValue(category.code);
      }
    });
  }
}
