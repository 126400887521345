import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-modify-association',
  templateUrl: './dialog-modify-association.component.html',
})
export class DialogModifyAssociationComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    protected dialogRef: MatDialogRef<DialogModifyAssociationComponent>,
  ) {
  }
}
