import {Component, Input} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {
  DialogCreateCashFoundComponent
} from "@pages/cash-management/cash-movements-analisis/dialog-create-cash-found/dialog-create-cash-found.component";
import {
  CreateCashFoundFormMeta
} from "@pages/cash-management/cash-movements-analisis/dialog-create-cash-found/form/create-cash-found-form-meta";
import {CashMovementsAnalisisService} from "@services/cash-movements.analisis.service";
import {CashFoundModel} from "@/models/cash-management/cash-found-model";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-create-cash-found-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class CreateCashFoundFormComponent extends DynamicFormComponent {
  @Input() dialogRef: MatDialogRef<DialogCreateCashFoundComponent>;

  constructor(protected userService: UserService, protected toastr: ToastrService, private cashMovementsAnalysisService: CashMovementsAnalisisService) {
    super(toastr, userService)
    this.submitTopButton = false
    this.submitBottomButton = true
    this.submitButtonVisible = true
    this.primaryButton = `Crea fondo cassa`
    this.secondaryBottomButton = `Annulla`

    this.setupMeta()
  }

  setupMeta(): void {
    this.meta = CreateCashFoundFormMeta.getMeta()
  }

  onSubmit() {
    this.submitButtonLoading = true;

    let cashFound = <CashFoundModel>this.form.getRawValue()

    this.cashMovementsAnalysisService.createCashFound(cashFound).subscribe({
      next: () => {
        this.toastr.success('Fondo Cassa creata correttamente!');

        this.submitButtonLoading = false;
        this.bottomButtonAction();
      },
      error: (error) => {
        LoggerService.error('createCashFound failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante la creazione del Fondo Cassa`);

        setTimeout(() => {
          this.submitButtonLoading = false;
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  bottomButtonAction() {
    this.dialogRef.close();
  }
}
