import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {UsedVehicleModel} from "@/models/usedVehicleModel";
import {BrandModel} from "@/models/tables/brand.model";
import {TableService} from "@services/commons/table.service";

@Injectable({
  providedIn: 'root'
})
export class UsedVehicleService extends TableService {
  getBrands(): Observable<BrandModel[]> {
    return this.http.get(this.baseUrl + `/usedVehicle/brands`).pipe(map((response: any) => {
      LoggerService.log(response, 'getBrands')
      return response;
    }));
  }

  saveUsedVehicle(usedVehicle: UsedVehicleModel): Observable<UsedVehicleModel> {
    return this.http.post<UsedVehicleModel>(this.baseUrl + `/usedVehicle/save`, usedVehicle).pipe(map((response: any) => {
      LoggerService.log(response, 'saveUsedVehicle')

      return response;
    }));
  }

  getUsedVehicleById(usedVehicleId: number) {
    return this.http.get(this.baseUrl + `/usedVehicle/detail/${usedVehicleId}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'getUsedVehicleById')

      return response;
    }));
  }

  changeStateNotRetire(usedId: any) {
    return this.http.post(this.baseUrl + `/usedVehicle/${usedId}/daNonRitirare`, {}).pipe(map((response: any) => {
      LoggerService.log(response, 'changeStateNotRetire')

      return response;
    }));
  }
}
