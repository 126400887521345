import {Component, Input} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {InsuranceFormMeta} from "@pages/insurances/form/insurance-form-meta";
import {DialogNewInsuranceComponent} from "@pages/insurances/dialog-new-insurance/dialog-new-insurance.component";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {InsuranceService} from "@services/data-source-tables/insurances/insurance.service";
import {InsuranceModel} from "@/models/insurances/insurance.model";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-insurance-form',
  templateUrl: '../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../components/dynamic-form/dynamic-form.component.scss']
})
export class InsuranceFormComponent extends DynamicFormComponent {
  @Input() dialogRef: MatDialogRef<DialogNewInsuranceComponent>;
  @Input() isNewForm: boolean = false;
  @Input() data: InsuranceModel;
  private typeInsurance: string = 'SINGOLA';

  constructor(protected userService: UserService, protected toastr: ToastrService, private insuranceService: InsuranceService) {
    super(toastr, userService)
    this.submitTopButton = false
    this.submitBottomButton = true
    this.submitButtonVisible = true
    this.primaryButton = `Salva`

    if (this.isNewForm) {
      this.primaryButton = `Crea assicurazione`
      this.secondaryBottomButton = `Annulla`
    }

    this.setupMeta()
  }

  ngOnChanges() {
    super.ngOnChanges();

    if (this.data?.tipoAssicurazione === 'TARGA_PROVA') {
      this.typeInsurance = 'TARGA_PROVA';

      this.refreshForm();
    }
  }

  typeInsuranceChanged(event: string) {
    this.typeInsurance = event;
    this.data.tipoAssicurazione = event;

    this.refreshForm();
  }

  setupMeta(): void {
    this.meta = InsuranceFormMeta
      .setIsNewForm(this.isNewForm)
      .setTypeInsurance(this.typeInsurance)
      .setFormInstance(this)
      .getMeta()
  }

  onSubmit() {
    this.submitButtonLoading = true;

    let insurance = <InsuranceModel>this.form.getRawValue()

    this.insuranceService.saveInsurance(insurance).subscribe({
      next: () => {
        this.toastr.success('Assicurazione salvata correttamente!');

        this.submitButtonLoading = false;
        if (this.isNewForm) {
          this.bottomButtonAction();
        }
      },
      error: (error) => {
        LoggerService.error('saveInsurance failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio dell'assicurazione.`);

        setTimeout(() => {
          if (this.isNewForm) {
            this.bottomButtonAction();
          }
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  bottomButtonAction() {
    this.dialogRef.close();
  }
}
