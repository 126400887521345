import {Component, Input, OnInit} from '@angular/core';
import {DataSourceTable} from "@components/new-table/data-sources/data-source-table";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {ActivatedRoute, Params} from "@angular/router";
import {RentalMovementTableSettings} from "@/table-settings/rentals/rental-movement-table-settings";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";
import {
  DialogFormRentalMovementComponent
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/dialog-form-rental-movement/dialog-form-rental-movement.component";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {ButtonInterface} from "@components/button/button-interface";
import {RentalClientsService} from "@services/data-source-tables/rentals/rental-clients.service";
import {TableDataApiParams} from "@services/practices.service";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {CustomButtonInterface} from "@components/new-table/custom-button-interface";
import {saveAs} from "file-saver";
import {RentalPracticeModel} from "@/models/rental-practice.model";

@Component({
  selector: 'app-rental-movements-list',
  templateUrl: './rental-movements-list.component.html',
  styleUrls: ['./rental-movements-list.component.scss']
})
export class RentalMovementsListComponent implements OnInit {
  displayedColumns = RentalMovementTableSettings.getColumnsSetting()
  dataSource = new DataSourceTable(this.rentalMovementService);
  closeDialogRentalMovementEvent$ = new Subject<any>();
  id: any;
  newButton: ButtonInterface;
  customButtons: CustomButtonInterface[];
  @Input() refreshRentalPracticeEvent$: Subject<any>;
  @Input() rentalInProgress: boolean = true;
  @Input() rentalPractice!: RentalPracticeModel;

  constructor(private rentalMovementService: RentalMovementService, private route: ActivatedRoute, protected rentalClientsService: RentalClientsService, private dialog: MatDialog) {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
    this.customButtons = [
      {
        btnCustomFaIcon: faDownload,
        btnCustomShowIcon: true,
        btnCustomToolTip: 'Scarica Privacy',
        btnCustomClickEvent: (element: any) => this.downloadPrivacy(element),
      }
    ];
    this.newButton = {
      content: 'Nuovo Noleggio',
      icon: 'details',
      showIcon: true,
      btnClick: ($event) => {
        let dialogRef = this.dialog.open(DialogFormRentalMovementComponent, {
          data: {
            movement: new RentalMovementModel(),
            rentalClientsService: this.rentalClientsService,
            practiceId: this.id,
            refreshRentalPracticeEvent$: this.refreshRentalPracticeEvent$,
            closeDialogRentalMovementEvent$: this.closeDialogRentalMovementEvent$,
            isNew: true,
            practiceCode: this.rentalPractice.codicePratica,
            brand: this.rentalPractice.marca,
            plate: this.rentalPractice.targa,
            model: this.rentalPractice.modello,
          },
          width: 'auto',
          panelClass: 'custom-modalbox',
        });

        this.closeDialogRentalMovementEvent$.subscribe(() => {
          dialogRef.close();
        });
      }
    };
  }

  btnDetailsClickFunction(obj: RentalMovementModel) {
    let dialogRef = this.dialog.open(DialogFormRentalMovementComponent, {
      data: {
        movement: obj,
        rentalClientsService: this.rentalClientsService,
        practiceId: this.id,
        rentalMovementService: this.rentalMovementService,
        refreshRentalPracticeEvent$: this.refreshRentalPracticeEvent$,
        closeDialogRentalMovementEvent$: this.closeDialogRentalMovementEvent$,
        practiceCode: this.rentalPractice.codicePratica,
        brand: this.rentalPractice.marca,
        plate: this.rentalPractice.targa,
        model: this.rentalPractice.modello,
      },
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.closeDialogRentalMovementEvent$.subscribe(() => {
      dialogRef.close();
    });
  }

  ngOnInit(): void {
    this.refreshRentalPracticeEvent$.subscribe(() => {
      const params = {id: this.id} as TableDataApiParams;

      this.dataSource.loadData(params);
    });
  }

  private downloadPrivacy(element: RentalMovementModel) {
    this.rentalMovementService.downloadPrivacy(element.id).subscribe((res) => {
      saveAs(res, 'privacy-noleggio-' + element.id + '.pdf');
    });
  }
}
