import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  @Output() btnUpdateClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() tableTitle: string = '';
  @Output() btnNewClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() showNewButton: boolean = false;
  @Input() showUpdateButton: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
