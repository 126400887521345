import {FormSetting} from "@/form-settings/form-setting";
import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {DateControl} from "@components/dynamic-form/date-control";

export class ReturnRentalMovementFormSetting extends FormSetting {
  private static rows: ControlBase[] = [];

  public static getMeta(): Array<any> {
    this.rows = []

    this.addRowCarburanteConsegna();
    this.addRowDataConsegna();
    this.addRowKmConsegna();
    this.addRowCarburanteRientro();
    this.addRowDataRientro();
    this.addRowKmRientro();
    this.addRowDanniRientro();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowCarburanteConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = 'carburanteConsegna';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Carburante Consegna';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataConsegna() {
    const inputsComponent = {} as DateControl;
    inputsComponent.key = 'dataConsegna';
    inputsComponent.controlType = ControlType.dateTime;
    inputsComponent.label = 'Data Consegna';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowKmConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = 'kmConsegna';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'KM Consegna';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowCarburanteRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = 'carburanteRientro';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Carburante Rientro';
    inputsComponent.class = "w-2/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataRientro() {
    const inputsComponent = {} as DateControl;
    inputsComponent.key = 'dataRientro';
    inputsComponent.controlType = ControlType.dateTime;
    inputsComponent.label = 'Data Rientro';
    inputsComponent.class = "w-6/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowKmRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = 'kmRientro';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'KM Rientro';
    inputsComponent.class = "w-2/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDanniRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.key = 'danniRientro';
    inputsComponent.controlType = ControlType.slideToggle;
    inputsComponent.label = 'Danni Riconsegna';
    inputsComponent.class = "w-2/12";

    this.rows.push(inputsComponent);
  }

}
