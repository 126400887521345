import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {DefaultFilter} from "ng2-smart-table";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {MatDatepicker, MatDatepickerInputEvent} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import moment from "moment";
import {Moment} from "moment/moment";
import {Subject} from "rxjs";

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    dateA11yLabel: 'LL',
  },
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DatePickerComponent extends DefaultFilter implements OnInit, OnChanges {
  @Input() triggerDate$: Subject<any>
  @Input() meta: any

  inputControl = new FormControl('')
  today = new Date()
  date = new FormControl(moment())

  ngOnInit() {
    this.inputControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(this.delay))
      .subscribe(value => {
        this.query = value !== null ? this.inputControl.value.toString() : '';
        this.setFilter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.query = changes.query.currentValue;
      this.inputControl.setValue(this.query);
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!
    ctrlValue.month(normalizedMonthAndYear.month())
    ctrlValue.year(normalizedMonthAndYear.year())

    this.date.setValue(ctrlValue)
    this.triggerDate$.next({
        'year': normalizedMonthAndYear.year(),
        'month': normalizedMonthAndYear.month() + 1
      }
    )

    datepicker.close();
  }

  customActionInput($event: MatDatepickerInputEvent<any, any>) {

  }

  setYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!
    ctrlValue.year(normalizedMonthAndYear.year())

    this.date.setValue(ctrlValue)
    this.triggerDate$.next({
        'year': normalizedMonthAndYear.year()
      }
    )

    datepicker.close();
  }
}
