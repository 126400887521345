<div class="table-container">
  <app-button
    (btnClicked)="newButton.btnClick($event)"
    *ngIf="!rentalInProgress"
    [additionalClass]="'mb-4'"
    [content]="newButton.content"
  ></app-button>
  <app-new-table
    (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
    [additionalFilters]="{id: id}"
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns"
    [customButtons]="customButtons"
    [initialSort]="{
              active: 'id',
              direction: 'desc'
            }"
  ></app-new-table>
</div>
