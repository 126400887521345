import {Component, Input, OnInit} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {DynamicFormSubmitInterface} from "@components/dynamic-form/dynamic-form-submit-interface";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";
import {Subject} from "rxjs";
import {ReturnRentalMovementFormSetting} from "@/form-settings/rentals/return-rental-movement-form-setting";
import {ReturnRentalMovementModel} from "@/models/rentals/return-rental-movement-model";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";

@Component({
  selector: 'app-return-rental-movement-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class RentalReturnMovementFormComponent extends DynamicFormComponent implements DynamicFormPopUpInterface, DynamicFormSubmitInterface, OnInit {
  @Input() data: ReturnRentalMovementModel
  @Input() rentalMovement: RentalMovementModel
  @Input() refreshRentalPracticeEvent$: Subject<any>;
  @Input() closeDialogEvent$: Subject<any>;

  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected userService: UserService,
    protected rentalMovementService: RentalMovementService,
  ) {
    super(toastr, userService)

    this.data = new ReturnRentalMovementModel()

    this.primaryButton = `Salva riconsegna veicolo noleggio`;
    this.secondaryBottomButton = 'Annulla';
    this.setupMeta()
  }

  ngOnInit() {
    this.data.dataConsegna = this.rentalMovement.dataConsegna;
    this.data.kmConsegna = this.rentalMovement.kmConsegna;
    this.data.carburanteConsegna = this.rentalMovement.carburanteConsegna;

    this.refreshForm()
  }

  setupMeta() {
    this.meta = ReturnRentalMovementFormSetting.getMeta()
  }

  onSubmit() {
    this.submitButtonLoading = true

    let ReturnRentalMovementModel = <ReturnRentalMovementModel>this.form.getRawValue()

    this.rentalMovementService.returnRentalMovement(this.rentalMovement.id, ReturnRentalMovementModel).subscribe({
      next: () => {
        this.toastr.success('Noleggio salvato correttamente!');

        this.refreshRentalPracticeEvent$.next()
        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('updateRentalMovement failed!');
        LoggerService.error(error);

        if (error.error.key === 'CONFLICT') {
          this.toastr.error(`Errore! è già presente un noleggio in corso per questa pratica.`);
        } else {
          this.toastr.error(`Errore durante il salvataggio del noleggio.`);
        }

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
          this.submitButtonLoading = false
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)

          this.closeDialogEvent$.next()

          this.submitButtonLoading = false
        }, 1000)
      },
    })
  }

  bottomButtonAction(): void {
    this.closeDialogEvent$.next()
  }
}
