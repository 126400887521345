import {DataSourceBaseNew} from "@components/new-table/data-source-base-new.dataSource";
import {TableDataApiParams} from "@services/practices.service";
import {InsuranceService} from "@services/data-source-tables/insurances/insurance.service";
import {AssociationsInsuranceRentPracticeModel} from "@/models/insurances/associations-insurance-rent-practice-model";
import {Registry} from "@/registry.service";

export class DataSourceAssociationInsuranceRentPracticeTable extends DataSourceBaseNew<any> {
  private insuranceId: number;

  constructor(private apiService: InsuranceService) {
    super();
  }

  setInsuranceId(insuranceId: number): DataSourceAssociationInsuranceRentPracticeTable {
    this.insuranceId = insuranceId;

    return this;
  }

  loadData(params?: TableDataApiParams): void {
    this._isLoading$.next(true);

    delete params['sort'];

    this.apiService.getDataTableAssociations(this.insuranceId).subscribe(data => {
      const content = data.map(item => ({
        ...item,
        codicePratica: item.rentalPractice?.codicePratica,
        tipoPratica: item.rentalPractice?.tipoPratica,
        targa: item.rentalPractice?.targa,
        denominazioneUtilizzatore: item.rentalPractice?.activeRental?.denominazioneUtilizzatore
      })) as AssociationsInsuranceRentPracticeModel[];

      if (content.some(item => item.stato === 'ATTIVA')) {
        Registry.getInstance().set('insuranceActive', true);
      }

      this.elements$.next(content);
      this._totalElements$.next(999);
      this._isLoading$.next(false);
    })
  }
}
