import {ControlBase} from "@components/dynamic-form/control-base";
import {FormSetting} from "@/form-settings/form-setting";
import {ControlType} from "@components/dynamic-form/control-type";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {
  StateMovementInsuranceEnum
} from "@pages/rental-management/rent-practice-detail/rental-insurance-list/state-movement-insurance-enum";

export class ModifyAssociationInsuranceFormMeta extends FormSetting {
  private static rows: ControlBase[] = [];

  public static getMeta(): Array<any> {
    this.rows = [];

    this.addRowStato();
    this.addRowTipoPratica();
    this.addRowCodicePratica();
    this.addRowTarga();
    this.addRowNumApplicazione()
    this.addRowDenominazioneUtilizzatore();
    this.addRowDataInizio();
    this.addRowDataFine();
    this.addRowPremioNetto();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowStato() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'stato';
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.label = 'Stato';
    inputsComponent.class = "w-4/12";
    inputsComponent.options = [
      {key: StateMovementInsuranceEnum.ATTIVA, value: 'ATTIVA'},
      {key: StateMovementInsuranceEnum.ANNULLATA, value: 'ANNULLATA'},
      {key: StateMovementInsuranceEnum.SOSPESA, value: 'SOSPESA'},
    ];
    inputsComponent.required = true;
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowTipoPratica() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'tipoPratica';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Tipo Pratica';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowCodicePratica() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'codicePratica';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Codice Pratica';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowTarga() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'targa';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Targa';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDenominazioneUtilizzatore() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'denominazioneUtilizzatore';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Utilizzatore';
    inputsComponent.class = "w-4/12";
    inputsComponent.disabled = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataInizio() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataInizio';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Inizio';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowDataFine() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataFine';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Fine';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowPremioNetto() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'premioNetto';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Premio Netto';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowNumApplicazione() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'numApplicazione';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Num Applicazione';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }
}
