import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {Moment} from "moment";
import {MatDatepicker} from "@angular/material/datepicker";
import moment from "moment/moment";
import {YEAR_ONLY_FORMATS} from "@/date-picker-formats";
import {DateControl} from "@components/dynamic-form/date-control";

@Component({
  selector: 'app-year-date-picker-input',
  templateUrl: './year-date-picker-input.component.html',
  styleUrls: ['./year-date-picker-input.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: YEAR_ONLY_FORMATS},
  ],
})
export class YearDatePickerInputComponent extends InputFieldComponent implements OnInit {
  @Input() meta!: DateControl
  @Input() form!: FormGroup
  @Input() max: any;
  @Input() indexRow: number = null
  @Input() keyformArrayName: any

  today: Date = new Date();
  protected control: AbstractControl<any>;

  constructor(private dateAdapter: DateAdapter<Date>) {
    super()

    this.today.setDate(this.today.getDate());
  }

  get controlArray() {
    return this.form.controls[this.keyformArrayName] as FormArray;
  }

  ngOnInit(): void {
    this.setControl()

    const year = this.form.controls[this.meta.key].value;
    this.form.controls[this.meta.key].setValue(moment({year: year}));
  }

  setYear(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.form.controls[this.meta.key].setValue(normalizedYear)

    console.log('CLOSE DATE PICKER')

    datepicker.close();
  }
}
