import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-upload-insurance-document',
  templateUrl: './dialog-upload-insurance-document.component.html',
})
export class DialogUploadInsuranceDocumentComponent {
  insuranceId: number;
  protected formData: { insuranceId: any };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    protected dialogRef: MatDialogRef<DialogUploadInsuranceDocumentComponent>,
  ) {
    this.formData = {
      insuranceId: this.data.insuranceId,
    };
  }
}
