import {FormSetting} from "@/form-settings/form-setting";
import {MetaEarnGarage} from "@/form-settings/cash-movements/meta-earn-garage";
import {MetaBackOffice} from "@/form-settings/cash-movements/meta-back-office";
import {MetaEarnWarehouse} from "@/form-settings/cash-movements/meta-earn-warehouse";
import {MetaSellers} from "@/form-settings/cash-movements/meta-sellers";

export class CashMovementsForm extends FormSetting {


  protected static classToCall: string

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getMeta() {
    if (this.data['department'] === 'OFFICINA') {
      return this.getMetaEarnGarage();
    } else if (this.data['department'] === 'BACKOFFICE') {
      return this.getMetaEarnBackOffice();
    } else if (this.data['department'] === 'MAGAZZINO') {
      return this.getMetaEarnWarehouse();
    } else if (this.data['department'] === 'VENDITORE') {
      return this.getMetaSellers();
    } else {
      return []
    }
  }

  private static getMetaEarnGarage() {
    return MetaEarnGarage.getMeta(
      this.classToCall,
      this.data['department'],
      this.data['departments'],
      this.data['isAccountingUser'],
      this.data['isNewMovement'],
      this.data['isAdminUser'],
      this.data['typePayments']
    )
  }

  private static getMetaEarnBackOffice() {
    return MetaBackOffice.getMeta(
      this.classToCall,
      this.data['department'],
      this.data['departments'],
      this.data['movementTypes'],
      this.data['isAccountingUser'],
      this.data['isNewMovement'],
      this.data['isAdminUser'],
      this.data['typePayments'],
      this.data['banksAccount'],
      this.data['typeMovementSelected']
    )
  }

  private static getMetaSellers() {
    return MetaSellers.getMeta(
      this.classToCall,
      this.data['department'],
      this.data['departments'],
      this.data['isAccountingUser'],
      this.data['isNewMovement'],
      this.data['isAdminUser'],
      this.data['typePayments']
    )
  }

  private static getMetaEarnWarehouse() {
    return MetaEarnWarehouse.getMeta(
      this.classToCall,
      this.data['department'],
      this.data['departments'],
      this.data['isAccountingUser'],
      this.data['isNewMovement'],
      this.data['isAdminUser'],
      this.data['typePayments']
    )
  }
}
