<div class="container-box">
  <app-table-header tableTitle="VHM Report" (btnUpdateClick)="reloadTrigger$.next()">
  </app-table-header>
  <div class="table-container">
    <app-new-table
      [dataSource]="dataSource"
      [reloadTrigger$]="reloadTrigger$"
      [displayedColumns]="displayedColumns"
      [initialSort]="{
              active: 'id',
              direction: 'desc'
            }"
    ></app-new-table>
  </div>
</div>
