import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-rentable-practice-new',
  templateUrl: './dialog-rentable-practice-new.component.html',
})
export class DialogRentablePracticeNewComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogRentablePracticeNewComponent>
  ) {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
