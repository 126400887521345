import {of} from "rxjs";
import {map} from "rxjs/operators";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {PracticesService} from "@services/practices.service";
import {getCurrentUserRoles} from "@utils-functions";
import {Role} from "@/models/role";
import {PracticeModel} from "@/models/practice.model";
import {FilterTypesEnum} from "@/table-settings/practices/filter-types-enum";

export class PracticeTableSettings {
  static disableDeleteButton: boolean = true;

  public static setRoleDeleteButton(roleDeleteButton: boolean) {
    this.disableDeleteButton = !roleDeleteButton

    return this;
  }

  public static getColumnsSetting(practiceService: PracticesService): TableColumnsSetting<keyof PracticeModel> {
    let columns = [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "codicePratica",
        text: "N Pratica",
        filter: {
          type: FilterTypesEnum.input
        }
      },
      {
        name: "tipoPratica",
        text: "tipo pratica",
        filter: {
          type: FilterTypesEnum.select,
          options: practiceService.getPracticeTypes().pipe(map(practiceTypes => practiceTypes.map((practiceType) => ({
            [practiceType]: practiceType
          }))))
        }
      },
      {
        name: "data",
        type: "date",
        text: "data",
        filter: {
          type: FilterTypesEnum.dateRange
        }
      },
      {
        name: "statoConsegna",
        text: "Stato consegna",
        filter: {
          type: FilterTypesEnum.select,
          options: of([
            {
              NON_ABBINATO: 'NON_ABBINATO',
            },
            {
              ABBINATO: 'ABBINATO',
            },
            {
              CONSEGNATO: 'CONSEGNATO'
            }
          ])
        }
      },
      {
        name: "statoPratica",
        text: "stato pratica",
        filter: {
          type: FilterTypesEnum.select,
          options: of([
            {
              IMPORTATA: 'importata',
            },
            {
              GESTITA: 'gestita'
            }
          ])
        }
      },
      {
        name: "statoOrdineVgi",
        text: "Stato ordine Vgi",
        filter: {
          type: FilterTypesEnum.input
        }
      },
      {
        name: "marca",
        text: "marca",
        filter: {
          type: FilterTypesEnum.select,
          options: practiceService.getAllBrands().pipe(map(brands => brands.map(({
                                                                                   id,
                                                                                   codiceMarca,
                                                                                   descrizioneMarca
                                                                                 }) => ({
            [id]: `${codiceMarca} - ${descrizioneMarca}`
          }))))
        }
      },
      {
        name: "targa",
        text: "Targa",
        filter: {
          type: FilterTypesEnum.input
        }
      },
      {
        name: "cliente",
        text: "cliente",
        filter: {
          type: FilterTypesEnum.input
        }
      },
      {
        name: "abbinato",
        text: "Abbinato",
        type: "checkbox",
        filter: {
          type: FilterTypesEnum.select,
          options: of([
            {
              true: 'si',
            },
            {
              false: 'no'
            }
          ])
        }
      },
      {
        name: "tipoPagamento",
        text: "tipologia pagamento",
        filter: {
          type: FilterTypesEnum.select,
          options: practiceService.getAllPaymentTypes().pipe(map(paymentTypes => paymentTypes.map(({
                                                                                                     id,
                                                                                                     descrizione
                                                                                                   }) => ({
            [id]: `${descrizione}`
          }))))
        }
      },
      {
        name: "totPunteggioFinanziari",
        text: "T.S. Assicurativi",
        unsortable: true
      },
      {
        name: "totPunteggioLoyalty",
        text: "T.S. Loyalty",
        unsortable: true,
      },
      {
        name: "venditore",
        text: "venditore",
        filter: {
          type: FilterTypesEnum.select,
          options: practiceService.getSellers().pipe(map(sellers => sellers.map(({
                                                                                   id,
                                                                                   descrizione,
                                                                                   codiceVenditore
                                                                                 }) => ({
            [id]: `${codiceVenditore} - ${descrizione}`
          }))))
        }
      },
      {
        name: "codiceModello",
        text: "Codice modello",
        filter: {
          type: FilterTypesEnum.input
        }
      },
      {
        name: "descrizioneModello",
        text: "Descrizione modello",
        filter: {
          type: FilterTypesEnum.input
        }
      },
      {
        name: "presenzaPermute",
        text: "Permuta",
        type: "checkbox",
        filter: {
          type: FilterTypesEnum.select,
          options: of([
            {
              true: 'si',
            },
            {
              false: 'no'
            }
          ])
        }
      },
      {
        name: "presenzaAccessori",
        text: "Con accessori",
        type: "checkbox",
        filter: {
          type: FilterTypesEnum.select,
          options: of([
            {
              true: 'si',
            },
            {
              false: 'no'
            }
          ])
        }
      },
      {
        name: "creator",
        text: "creato da",
        filter: {
          type: FilterTypesEnum.input,
        }
      },
      {
        name: "created",
        type: "datetime",
        text: "creato il",
        filter: {
          type: FilterTypesEnum.dateRange
        }
      }
    ]

    if (!this.disableDeleteButton) {
      columns.splice(1, 0, {
        name: "btnDelete",
        customComponent: true,
        unsortable: true,
      });
    }

    if (!this.isUserASubAgent()) {
      columns.splice(5, 0, {
        name: "tipoVetturaAziendale",
        text: "Veicoli aziendali",
        filter: {
          type: FilterTypesEnum.select,
          options: of([
            {
              VA: 'VA',
            },
            {
              VN: 'VN'
            }
          ])
        }
      });
    }

    return columns;
  }

  private static isUserASubAgent() {
    return getCurrentUserRoles().includes(Role.Subagents)
      || getCurrentUserRoles().includes(Role.ResponsibleSubagents)
  }
}
