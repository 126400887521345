import {Component, Input} from '@angular/core';
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {LoggerService} from "@services/logger.service";
import {RentalInsuranceService} from "@services/data-source-tables/rentals/rental-insurance.service";
import {MatDialogRef} from "@angular/material/dialog";
import {LinkInsuranceVehicleDataModel} from "@/models/rentals/link-insurance-vehicle-data-model";
import {
  DialogModifyAssociationComponent
} from "@pages/insurances/insurance-detail/dialog-modify-association/dialog-modify-association.component";
import {
  ModifyAssociationInsuranceFormMeta
} from "@pages/insurances/insurance-detail/dialog-modify-association/form-modify-association-insurance/associate-insurance-vehicle-form-meta";
import {AssociationsInsuranceRentPracticeModel} from "@/models/insurances/associations-insurance-rent-practice-model";

@Component({
  selector: 'app-form-modify-association-insurance',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class FormModifyAssociationComponent extends DynamicFormComponent {
  @Input() dialogRef: MatDialogRef<DialogModifyAssociationComponent>;
  @Input() data: AssociationsInsuranceRentPracticeModel;

  constructor(protected userService: UserService, protected toastr: ToastrService, protected rentalInsuranceService: RentalInsuranceService) {
    super(toastr, userService)
    this.submitTopButton = false
    this.submitBottomButton = true
    this.submitButtonVisible = true
    this.primaryButton = `Modifica Associazione`
    this.secondaryBottomButton = `Annulla`

    this.setupMeta()
  }

  setupMeta(): void {
    this.meta = ModifyAssociationInsuranceFormMeta.getMeta()
  }

  ngOnChanges() {
    super.ngOnChanges();

    if (this.data) {
      console.log(this.data)
      this.form.controls['stato'].setValue(this.data.stato);
    }
  }

  onSubmit() {
    this.submitButtonLoading = true;

    const linkData = this.form.getRawValue() as LinkInsuranceVehicleDataModel;

    this.rentalInsuranceService.linkVehicleToInsurance(this.data.rentalPractice.id, this.data.insurance.id, linkData).subscribe({
      next: (response) => {
        this.toastr.success('Associazione veicolo con assicurazione registrata!');

        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('linkVehicleToInsurance failed!');
        LoggerService.error(error);

        this.toastr.error('Associazione fallita!');

        setTimeout(() => {
          this.submitButtonLoading = false;
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          this.bottomButtonAction();
        }, 1000)
      },
    })
  }

  bottomButtonAction() {
    this.submitButtonLoading = false;
    this.dialogRef.close();
  }
}
