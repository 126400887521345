import {BrandNewRendererComponent} from "@components/table-column-renderer/brand-new-renderer.component";
import {
  ButtonDeleteActionPracticeComponent
} from "@components/button/tables/action-practices/button-delete-action-practice.component";
import {
  ButtonDetailActionPracticeComponent
} from "@components/button/tables/action-practices/button-modify-action-practice.component";


export class ActionPracticesSettings {

  public static gridSettings() {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailActionPracticeComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteActionPracticeComponent,
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '100px'
        },
        marca: {
          title: 'Marca',
          type: 'custom',
          renderComponent: BrandNewRendererComponent,
          width: '120px',
          filter: {
            type: 'list',
            config: {
              list: [],
            }
          },
          filterFunction(cell?: any, search?: string): boolean {
            return cell == search;
          },
        },
        codiceAzione: {
          title: 'Codice Azione'
        },
        descrizione: {
          title: 'Descrizione'
        },
        conteggiaAzione: {
          width: '130px',
          title: 'Conteggia azione',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
        negativa: {
          width: '130px',
          title: 'Azione negativa',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
        volkswagenBank: {
          width: '130px',
          title: 'Azione Volkswagen Bank',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
        tipologiaAzione: {
          title: 'Tipologia Azione'
        },
        enabled: {
          width: '130px',
          title: 'Abilitato',
          valuePrepareFunction: (abilitato) => {
            if (abilitato) {
              return 'Si'
            } else {
              return 'No';
            }
          },
          filter: {
            type: 'list',
            config: {
              list: [
                {value: true, title: 'Si'},
                {value: false, title: 'No'},
              ],
            },
          },
        },
      },
      delete: null
    };
  }
}
