<div class="container-box">
  <app-table-header (btnUpdateClick)="reloadTrigger$.next()" tableTitle="Quadrature Cassa">
  </app-table-header>
  <div class="table-container">
    <app-table-group-rows (buttonClick)="downloadExcelExport($event)"
                          (secondButtonClick)="createCashFound($event)"
                          [columns]="columns"
                          [data]="data"
                          [icon]="faFileExcel"
                          [showButtonRow]="true"
                          [showSecondButtonRow]="true"
                          [subColumns]="subColumns"
                          [subRowName]="'movimenti'"></app-table-group-rows>
  </div>
</div>
