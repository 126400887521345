import {ApiService} from "@services/api.service";
import {TableDataApiParams} from "@services/practices.service";
import {Observable, throwError} from "rxjs";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {catchError, map, tap} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {Injectable} from "@angular/core";
import {RentalInsuranceModel} from "@/models/rentals/rental-insurance-model";
import {LinkInsuranceVehicleDataModel} from "@/models/rentals/link-insurance-vehicle-data-model";
import {AssociationsInsuranceRentPracticeModel} from "@/models/insurances/associations-insurance-rent-practice-model";

@Injectable({
  providedIn: 'root'
})
export class RentalInsuranceService extends ApiService {
  getDataForTable(params?: TableDataApiParams): Observable<TablePaginationRespModel<AssociationsInsuranceRentPracticeModel>> {
    const id = params.id;

    return new Observable<TablePaginationRespModel<AssociationsInsuranceRentPracticeModel>>(observer => {
      this.http.get<[any]>(
        this.baseUrl + `/rental-practices/${id}/insurances`)
        .pipe(
          tap(response => {

            const newResponse = {} as TablePaginationRespModel<AssociationsInsuranceRentPracticeModel>;
            newResponse.content = response.map(item => ({
              ...item,
              numeroPolizza: item.insurance.numeroPolizza
            }));
            newResponse.totalElements = response.length;

            observer.next(newResponse);
            observer.complete();
          }),
          catchError(error => {
            observer.error(error);

            return throwError(error);
          })
        ).subscribe();
    });
  }

  deleteRentalInsurance(practiceId: number, insuranceId: number) {
    return this.http.delete(this.baseUrl + `/rental-practices/${practiceId}/insurances/${insuranceId}`)
      .pipe(map((response: any) =>
          tap(() => {
            LoggerService.log(response, 'deleteRentalInsurance')

            this.triggerUpdate();
            this.toastr.success('Assicurazione noleggio eliminata correttamente!');
          }),
        catchError(error => {
          this.toastr.error('Cancellazione assicurazione noleggio fallita!');
          return throwError(error);
        })
      ));
  }

  async getRentalInsurance(id: number) {
    return this.http.get<RentalInsuranceModel>(this.baseUrl + `/insurances/${id}/detail`).toPromise();
  }

  updateRentalInsurance(practiceRentalId: number, insurance: RentalInsuranceModel) {
    return this.http.post<RentalInsuranceModel>(this.baseUrl + `/rental-practices/${practiceRentalId}/insurances/save/`, insurance).pipe(map((response: any) => {
      LoggerService.log(response, 'updateRentalInsurance')

      return response;
    }));
  }

  renewInsurance(practiceRentalId: number, insuranceId: number, renewData: object) {
    return this.http.post<RentalInsuranceModel>(this.baseUrl + `/rental-practices/${practiceRentalId}/insurances/${insuranceId}/renew/`, renewData).pipe(map((response: any) => {
      LoggerService.log(response, 'renewInsurance')

      return response;
    }));
  }

  linkVehicleToInsurance(practiceRentalId: number, insuranceId: number, linkData: LinkInsuranceVehicleDataModel) {
    return this.http.post<RentalInsuranceModel>(this.baseUrl + `/rental-practices/${practiceRentalId}/insurances/${insuranceId}/link`, linkData).pipe(map((response: any) => {
      LoggerService.log(response, 'linkVehicleToInsurance')

      return response;
    }));
  }

  suspendInsurance(practiceRentalId: number, insuranceId: number, linkData: LinkInsuranceVehicleDataModel) {
    return this.linkVehicleToInsurance(practiceRentalId, insuranceId, linkData);
  }

  activeInsurance(practiceRentalId: number, insuranceId: number, linkData: LinkInsuranceVehicleDataModel) {
    return this.linkVehicleToInsurance(practiceRentalId, insuranceId, linkData);
  }
}
