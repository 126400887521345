import {TableColumnsSetting} from "@/interfaces/table-setting";
import {of} from "rxjs";
import {MsDiscountModel} from "@/models/tables/ms-discount-model";

export class MSTableSettings {
  public static getColumnsSetting(): TableColumnsSetting<keyof MsDiscountModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "btnDelete",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "anno",
        text: 'anno',
        filter: {
          type: "number"
        }
      },
      {
        name: "mese",
        text: 'mese',
        filter: {
          type: "number"
        }
      },
      {
        name: "trimestre",
        text: 'Trimestre',
        filter: {
          type: "select",
          options: of([
            {'I': 'I'},
            {'II': 'II'},
            {'III': 'III'},
            {'IV': 'IV'}
          ])
        }
      },
      {
        name: "tipo",
        text: 'Tipo',
        filter: {
          type: "select",
          options: of([
            {'RETAIL': 'RETAIL'},
            {'NLT': 'NLT'}
          ])
        }
      },
      {
        name: "obiettivoRaggiunto",
        text: 'Fascia Mat.',
        type: "slideToggle",
        filter: {
          type: "select",
          options: of([
            {true: 'SI'},
            {false: 'NO'}
          ])
        }
      },
      {
        name: "posizioneRanking",
        text: 'Pos. Ranking',
        filter: {
          type: "input"
        }
      },
      {
        name: "codiceMs",
        text: 'Cod. MS',
        filter: {
          type: "input"
        }
      },
      {
        name: "descrizioneMs",
        text: 'Descr. MS',
        filter: {
          type: "input"
        }
      },
      {
        name: "fascia",
        text: 'Fascia',
        filter: {
          type: "input"
        }
      },
      {
        name: "descRaggiungimentoObiettivo",
        text: 'Desc. Ragg. Obj.',
        filter: {
          type: "input"
        }
      },
      {
        name: "percMsPrevista",
        text: '% MS Prevista',
        filter: {
          type: "input"
        }
      },
      {
        name: "percObiettivo",
        text: '% Obj.',
        filter: {
          type: "input"
        }
      },
      {
        name: "classificazioneMs",
        text: 'Class. MS',
        filter: {
          type: "select",
          options: of([
            {'MENSILE': 'MENS.'},
            {'TRIMESTRALE': 'TRIM.'},
            {'SEMESTRALE': 'SEM.'},
            {'EXTRA': 'EXTRA'}
          ])
        }
      },
      {
        name: "tipologiaMs",
        text: 'Tipologia MS',
        filter: {
          type: "select",
          options: of([
            {'Qualitativo': 'Qualitativo.'},
            {'Quantitativo': 'Quantitativo.'}
          ])
        }
      },
    ];
  }
}
