import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RentalPracticeService} from "@services/data-source-tables/rentals/rental-practice.service";
import {RentalPracticeModel} from "@/models/rental-practice.model";

@Component({
  selector: 'app-dialog-link-insurance-to-vehicle',
  templateUrl: './dialog-link-insurance-to-vehicle.component.html',
})
export class DialogLinkInsuranceToVehicleComponent {
  protected listRentalPractices: Array<RentalPracticeModel>;
  protected selectedPractices: Array<RentalPracticeModel> = [];
  protected rowColumns: (keyof RentalPracticeModel)[] = ['id', "praticaId", "modello", 'targa', 'tipoVetturaAziendale'];
  protected displayedColumns: string[] = ['select'].concat(this.rowColumns);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    protected dialogRef: MatDialogRef<DialogLinkInsuranceToVehicleComponent>,
    private rentalPracticeService: RentalPracticeService
  ) {
    this.rentalPracticeService.getDataForTable().subscribe(data => {
      this.listRentalPractices = data.content;
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
