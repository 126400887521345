import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {UserService} from "@services/admin/user.service";
import {Observable} from 'rxjs';
import {LoggerService} from '@services/logger.service';
import {Role} from '@/models/role';
import {environment} from "../../../../environments/environment";

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
  providers: [UserService],
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public user;
  public menu = [];
  environment: string = environment.env;

  constructor(
    public appService: UserService,
    private store: Store<AppState>,
    private logger: LoggerService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
    this.user = this.appService.user;

    this.filterMenuByRoles();
  }

  protected getMenu(): Array<any> {
    return [
      {
        name: 'Home',
        iconClasses: 'fas fa-home',
        path: ['/'],
      },
      {
        name: 'Cassa',
        iconClasses: 'fas fa-archive',
        roles: [
          Role.Admin,
          Role.ResponsibleSellers,
          Role.ResponsibleWarehouse,
          Role.ResponsibleGarage,
          Role.Sellers,
          Role.Backoffice,
          Role.Warehouse,
          Role.Garage,
          Role.Accounting
        ],
        children: [
          {
            name: 'Movimenti cassa',
            iconClasses: 'fas fa-euro-sign',
            path: ['/cash-management/cash-movements'],
            roles: [
              Role.Admin,
              Role.ResponsibleSellers,
              Role.ResponsibleWarehouse,
              Role.ResponsibleGarage,
              Role.Sellers,
              Role.Backoffice,
              Role.Warehouse,
              Role.Garage,
              Role.Accounting
            ],
          },
          {
            name: 'Quadrature cassa',
            iconClasses: 'fas fa-table',
            path: ['/cash-management/cash-movements-analisis'],
            roles: [
              Role.Admin,
              Role.Accounting
            ],
          }
        ]
      },
      {
        name: 'Pratiche',
        iconClasses: 'fas fa-archive',
        roles: [
          Role.Admin,
          Role.ResponsibleSellers,
          Role.ResponsibleSubagents,
          Role.ResponsibleGarage,
          Role.Sellers,
          Role.Subagents,
          Role.Accounting,
          Role.Backoffice
        ],
        children: [
          {
            name: 'Gestione pratiche',
            iconClasses: 'fas fa-calendar',
            path: ['/practices-management/practices'],
            roles: [
              Role.Admin,
              Role.ResponsibleSellers,
              Role.ResponsibleSubagents,
              Role.ResponsibleGarage,
              Role.Sellers,
              Role.Subagents,
              Role.Accounting,
              Role.Backoffice
            ],
          },
          {
            name: 'Vetture aziendali Paolo Crespi',
            iconClasses: 'fas fa-calendar',
            path: ['/practices-management/business-machines'],
            roles: [
              Role.Admin,
              Role.Accounting,
              Role.Backoffice,
              Role.ResponsibleSellers
            ],
          },
          {
            name: 'Numeratori consegne',
            iconClasses: 'fas fa-calendar',
            path: ['/practices-management/delivery-cardboard'],
            roles: [
              Role.Admin,
              Role.Accounting,
              Role.Backoffice,
              Role.ResponsibleSellers
            ],
          },
          {
            name: 'Obiettivi marca',
            iconClasses: 'fas fa-calendar',
            path: ['/practices-management/brand-goals'],
            roles: [
              Role.Admin,
              Role.Accounting,
              Role.Backoffice,
              Role.ResponsibleSellers
            ],
          }
        ]
      },
      {
        name: 'Gestione V.A. & Noleggi',
        iconClasses: 'fas fa-car',
        path: ['/rental-practices'],
        roles: [
          Role.Admin
        ],
      },
      {
        name: 'Gestione assicurazioni',
        iconClasses: 'fas fa-car',
        path: ['/insurances'],
        roles: [
          Role.Admin
        ],
      },
      {
        name: 'Gestione MMSS',
        iconClasses: 'fas fa-archive',
        roles: [
          Role.Admin,
          Role.ResponsibleSellers,
          Role.Accounting
        ],
        children: [
          {
            name: 'Simulatore MS',
            iconClasses: 'fas fa-percent',
            path: ['/mmss-management/simulator-ms'],
            roles: [
              Role.Admin,
              Role.ResponsibleSellers,
              Role.Accounting
            ],
          },
          {
            name: 'Tabella MS',
            iconClasses: 'fas fa-table',
            path: ['/mmss-management/table-ms'],
            roles: [
              Role.Admin,
              Role.ResponsibleSellers,
              Role.Accounting
            ],
          }
        ]
      },
      {
        name: 'Gestione usato',
        iconClasses: 'fas fa-cart-plus',
        path: ['/used-vehicle-management'],
        roles: [
          Role.Admin,
          Role.ResponsibleSellers,
          Role.Sellers,
          Role.Accounting,
          Role.Backoffice
        ],
      },
      {
        name: 'Gestione stock',
        iconClasses: 'fas fa-cart-plus',
        path: ['/stock-management'],
        roles: [
          Role.Admin,
          Role.ResponsibleSellers,
          Role.Sellers,
          Role.Accounting,
          Role.Backoffice,
          Role.Subagents,
          Role.ResponsibleSubagents
        ],
      },
      {
        name: 'Importazioni',
        iconClasses: 'fas fa-table',
        roles: [
          Role.Admin,
          Role.Import
        ],
        children: [
          {
            name: 'Cross F7',
            iconClasses: 'fas fa-table',
            path: ['/imports/crossf7'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          ,
          {
            name: 'Cross F7 Cancellate',
            iconClasses: 'fas fa-list',
            path: ['/imports/practice-deleted'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          {
            name: 'Cross F8',
            iconClasses: 'fas fa-table',
            path: ['/imports/crossf8'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          {
            name: 'Loyalty',
            iconClasses: 'fas fa-table',
            path: ['/imports/loyalty'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          {
            name: 'FT Provvigioni VW Bank',
            iconClasses: 'fas fa-table',
            path: ['/imports/vwbankCommission'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          {
            name: 'VWFS Cruscotti',
            iconClasses: 'fas fa-table',
            path: ['/imports/vwfsDashboard'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          {
            name: 'VWFS Provvigioni',
            iconClasses: 'fas fa-table',
            path: ['/imports/vwfsCommission'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          {
            name: 'VHM ProForma',
            iconClasses: 'fas fa-table',
            path: ['/imports/vhmProForma'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          {
            name: 'VHM Report',
            iconClasses: 'fas fa-table',
            path: ['/imports/vhmReport'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
          {
            name: 'Registro Importazioni',
            iconClasses: 'fas fa-table',
            path: ['/imports/registry'],
            roles: [
              Role.Admin,
              Role.Import
            ]
          },
        ]
      },
      {
        name: 'Tabelle',
        iconClasses: 'fas fa-table',
        roles: [
          Role.Admin,
          Role.TableManager,
          Role.Accounting
        ],
        children: [
          {
            name: 'Marche',
            iconClasses: 'fas fa-table',
            path: ['/tables/brand']
          },
          {
            name: 'Noleggio pratiche',
            iconClasses: 'fas fa-table',
            path: ['/tables/rental-practices']
          },
          {
            name: 'Azioni pratiche',
            iconClasses: 'fas fa-table',
            path: ['/tables/action-practices']
          },
          {
            name: 'Servizi pratiche',
            iconClasses: 'fas fa-table',
            path: ['/tables/service-practices']
          },
          {
            name: 'Tipo servizi assicurativi',
            iconClasses: 'fas fa-table',
            path: ['/tables/insurance-service-type']
          },
          {
            name: 'Agenzie subagenti',
            iconClasses: 'fas fa-table',
            path: ['/tables/subagent-agency']
          },
          {
            name: 'Gestione venditori',
            iconClasses: 'fas fa-table',
            path: ['/tables/seller']
          },
          {
            name: '% Provvigioni',
            iconClasses: 'fas fa-table',
            path: ['tables/commissions']
          },
          {
            name: 'Grandi acquirenti',
            iconClasses: 'fas fa-table',
            path: ['/tables/big-buyers']
          },
          {
            name: 'Agenzie noleggio',
            iconClasses: 'fas fa-table',
            path: ['/tables/rental-agency']
          },
          {
            name: 'Accessori veicoli',
            iconClasses: 'fas fa-table',
            path: ['/tables/vehicle-accessory']
          },
          {
            name: 'Modelli',
            iconClasses: 'fas fa-table',
            path: ['/tables/models']
          },
          {
            name: 'Tipo pagamento',
            iconClasses: 'fas fa-table',
            path: ['/tables/payment-type']
          },
          {
            name: 'Origine contatto',
            iconClasses: 'fas fa-table',
            path: ['/tables/contact-source']
          },
          {
            name: 'Garanzia',
            iconClasses: 'fas fa-table',
            path: ['/tables/warranty']
          },
          {
            name: 'Banche',
            iconClasses: 'fas fa-table',
            path: ['/tables/bank-account']
          },
          {
            name: 'Gestione menu BI',
            iconClasses: 'fas fa-table',
            path: ['/tables/menu-bi-config']
          },
        ]
      },
      {
        name: 'Amministrazione',
        iconClasses: 'fas fa-cogs',
        roles: [
          Role.Admin
        ],
        children: [
          {
            name: 'Utenti',
            iconClasses: 'far fa-user',
            path: ['/admin/users'],
          },
          {
            name: 'Aziende',
            iconClasses: 'far fa-building',
            path: ['/admin/company'],
          },
        ],
      },
      {
        name: 'Menu BI',
        iconClasses: 'fas fa-cart-plus',
        path: ['/menu-bi'],
        roles: [
          Role.Admin,
          Role.ResponsibleSellers,
          Role.ResponsibleWarehouse,
          Role.ResponsibleGarage,
          Role.Backoffice,
          Role.Accounting,
          Role.Warehouse
        ],
      },
    ]
  }

  private filterMenuByRoles() {
    const menuAuthorized = [];
    const rolesUser = this.userService.getUserRoles();

    this.getMenu().forEach(function (mainVoice) {
      let childrenMenus = mainVoice.children ?? [];
      let childrenMenuAuthorized = [];
      mainVoice.children = [];
      let mainIsAuthorized = true;

      if (mainVoice.roles) {
        mainIsAuthorized = mainVoice.roles.some(element => {
          return rolesUser.includes(element);
        });
      }

      childrenMenus.forEach(function (childrenMenu) {
        let childrenIsAuthorized = true;

        if (childrenMenu["roles"]) {
          LoggerService.log(childrenMenu["roles"])
          childrenIsAuthorized = childrenMenu["roles"].some(element => {
            return rolesUser.includes(element);
          });
        }

        if (childrenIsAuthorized) {
          childrenMenuAuthorized.push(childrenMenu)
        }
      });

      if (mainIsAuthorized) {
        mainVoice.children = childrenMenuAuthorized;
        menuAuthorized.push(mainVoice)
      }
    });

    this.menu = menuAuthorized;
  }
}
