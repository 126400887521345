<div class="flex justify-between items-center mb-3">
  <app-button (btnClicked)="onSubmitForm()"
              *ngIf="submitButtonVisible && submitTopButton"
              [color]="'primary'"
              [content]="primaryButton"
              [faIcon]="iconAddClient" [loading]="submitButtonLoading" class="mr-auto "></app-button>
  <div class="flex space-x-2">
    <button (click)="secondButtonAction()" *ngIf="secondButton" class="btn btn-secondary btn-space">
      {{ secondButton }}
    </button>
    <button (click)="thirdButtonAction()" *ngIf="thirdButton" class="btn btn-secondary ml-2">
      {{ thirdButton }}
    </button>
  </div>
</div>
<ng-container *ngIf="form !== null">
  <form [enctype]="encType" [formGroup]="form" autocomplete="off" class="dynamic-form" id="dynamic-form">
    <!-- Div rows divided by tabs -->
    <mat-tab-group *ngIf="tabs.length !== 0" animationDuration="0ms">
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label>
          <fa-icon [icon]="faIcon"></fa-icon>
          {{ tab.title }}
        </ng-template>
        <div *ngIf="tab.meta.length !== 0" class="content">
          <div *ngFor="let obj of tab.meta" class="form-row w-full">
            <app-control *ngFor="let control of obj.rows" [class]="control.class" [control]="control"
                         [form]="form" [id]="control.key" [triggerMethodSubject]="triggerMethodSubject"></app-control>
          </div>
          <div class="form-row float-right">
            <app-button (btnClicked)="onSubmitForm()"
                        *ngIf="submitButtonVisible && submitBottomButton"
                        [color]="'primary'"
                        [content]="primaryButton"
                        [faIcon]="iconAddClient" [loading]="submitButtonLoading"
                        additionalClass="mr-0"></app-button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <!-- Div classic rows -->
    <div *ngIf="tabs.length === 0 && meta.length !== 0 && meta[0]?.rows">
      <div *ngFor="let obj of meta" class="form-row w-full">
        <app-control *ngFor="let control of obj.rows" [class]="control.class" [control]="control"
                     [form]="form" [triggerMethodSubject]="triggerMethodSubject"></app-control>
      </div>
      <div class="form-row float-right">
        <app-button (btnClicked)="onSubmitForm()"
                    *ngIf="submitButtonVisible && submitBottomButton"
                    [color]="'primary'"
                    [content]="primaryButton"
                    [faIcon]="iconAddClient" [loading]="submitButtonLoading"></app-button>
        <button (click)="bottomButtonAction()" *ngIf="secondaryBottomButton"
                class="btn btn-secondary ml-3">{{ secondaryBottomButton }}
        </button>
      </div>
    </div>
    <!-- Form rows with sections -->
    <div *ngIf="tabs.length === 0 && meta.length !== 0 && !meta[0]?.rows" class="form-with-sections">
      <div class="flex flex-wrap">
        <div *ngFor="let section of meta[0].sections" class="{{ section.class }}">
          <div class="col-md-12 mt-3 p-1 text-center title-repeater w-full">
            <p class="m-auto font-bold text-base">{{ section.title }}</p>
          </div>
          <div class="w-full flex flex-wrap  border-2 div-sections p-2 border-top-0">
            <app-control *ngFor="let control of section.rows" [class]="control.class" [control]="control"
                         [form]="form" [triggerMethodSubject]="triggerMethodSubject"></app-control>
          </div>
        </div>
        <div *ngFor="let obj of meta" class="form-row w-full">
          <app-control *ngFor="let control of obj.rows" [class]="control.class" [control]="control"
                       [form]="form" [triggerMethodSubject]="triggerMethodSubject"></app-control>
        </div>
      </div>
      <div class="form-row float-right">
        <app-button (btnClicked)="onSubmitForm()"
                    *ngIf="submitButtonVisible && submitBottomButton"
                    [color]="'primary'"
                    [content]="primaryButton"
                    [faIcon]="iconAddClient" [loading]="submitButtonLoading"></app-button>
        <button (click)="bottomButtonAction()" *ngIf="secondaryBottomButton"
                class="btn btn-secondary  ml-3">{{ secondaryBottomButton }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
