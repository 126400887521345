import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from '@services/api.service';
import {catchError, map,} from 'rxjs/operators';
import {LoggerService} from '@services/logger.service';
import {PracticePageResp} from '@/models/practice.model';
import {BrandModel} from "@/models/tables/brand.model";
import {TablePracticeModel} from "@/models/tables/table-practices.model";
import {BigBuyersModel} from "@/models/tables/big-buyers.model";

export interface TableDataApiParams {
  sort?: keyof TablePracticeModel | '';
  orderBy?: 'DESC' | 'ASC';
  page?: number;
  size?: number;
}

type TempCustomBusinessMachinesResp = Omit<PracticePageResp, 'content'> & {
  content: TablePracticeModel[];
}

@Injectable({
  providedIn: 'root'
})
export class BusinessMachinesService extends ApiService {
  getDataForTable(
    params: TableDataApiParams = {
      sort: '',
      orderBy: 'DESC',
      page: 1,
      size: 15
    }
  ): Observable<TempCustomBusinessMachinesResp> {
    return this.http.get<PracticePageResp>(this.baseUrl + '/businessMachines/page', {params: {...params}}).pipe(
      map(({content, ...other}) => ({
        ...other,
        content: content.map(
          ({
             id,
             tipoPratica,
             data,
             codicePratica,
             tipoVetturaAziendale,
             marca,
             descrizioneFamiglia,
             descrizioneModello,
             targa,
             dataPrimaImmatricolazione,
             grandiAcquirenti,
             dataScadenzaAutoDemo,
             assegnatarioAutoDemo,
             numeroPraticaVenditaAutoAziendale,
             venditaAutoAziendaleCompletata,
             venditaAutoAziendalePraticaId,
             noleggiabile,
             creator,
             created
           }) => ({
            id,
            tipoPratica,
            data,
            codicePratica,
            tipoVetturaAziendale,
            marca: marca?.descrizioneMarca,
            descrizioneFamiglia,
            descrizioneModello,
            targa,
            dataPrimaImmatricolazione,
            grandiAcquirenti: grandiAcquirenti?.descrizione,
            dataScadenzaAutoDemo,
            assegnatarioAutoDemo,
            numeroPraticaVenditaAutoAziendale,
            venditaAutoAziendaleCompletata,
            venditaAutoAziendalePraticaId,
            noleggiabile,
            creator,
            created
          })
        )
      })),
      catchError(() => of(null))
    );
  }

  getAllBrands(): Observable<BrandModel[]> {
    return this.http.get<any>(this.baseUrl + `/businessMachines/brands`).pipe(map((response: any) => {
      LoggerService.log(response, 'getAllBrands')
      return response;
    }));
  }

  getAllBigBuyersVettureDimostrative(): Observable<BigBuyersModel[]> {
    return this.http.get<any>(this.baseUrl + `/businessMachines/bigBuyers`).pipe(map((response: any) => {
      LoggerService.log(response, 'getAllBigBuyersVettureDimostrative')
      return response;
    }));
  }
}
