import {Component, Input, OnInit} from '@angular/core';
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {UserInfo} from "@/models/sub-agent";


type OptionsParamsType = UserInfo | string;

@Component({
  selector: 'app-dropdown-form',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends InputFieldComponent implements OnInit {

  @Input() meta!: DropdownControl
  @Input() multi: boolean = false;

  selected: any = '';

  ngOnInit(): void {
    this.setControl()

    this.selected = this.control.getRawValue() ?? null;
  }

  protected customActionInput(event, indexRow = null) {
    LoggerService.log('Input Changed!', event.source.value)

    if (this.meta.classToUse && this.meta.customAction) {
      Registry.getInstance().get(this.meta.classToUse)[this.meta.customAction](event.source.value, indexRow)
    } else if (this.meta.formComponent) {
      this.meta.formComponent[this.meta.customAction](event.source.value)
    }
  }

  compareFunction(o1: any, o2: any): boolean {
    return o1 === o2;
  }
}
