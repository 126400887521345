<div [formGroup]="form">
  <div *ngIf="indexRow === null">
    <mat-label class="label-form">{{ meta.label }}:</mat-label>
    <mat-form-field [ngClass]="control.invalid && submitted ? 'invalid-field' : ''" appearance="fill"
                    class="w-full date-time-picker">
      <mat-label>Scegli una data</mat-label>
      <input [formControlName]="meta.key" [ngxMatDatetimePicker]="picker" matInput>
      <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
      <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
    </mat-form-field>
  </div>
  <app-box-errors [control]="control" [meta]="meta" [submitted]="submitted"></app-box-errors>
</div>
