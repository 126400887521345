import {Component, Input} from '@angular/core';
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {
  AssociateInsuranceVehicleFormMeta
} from "@pages/insurances/insurance-detail/dialog-link-insurance-to-vehicle/form-associate-insurance-to-vehicle/associate-insurance-vehicle-form-meta";
import {LoggerService} from "@services/logger.service";
import {RentalInsuranceService} from "@services/data-source-tables/rentals/rental-insurance.service";
import {MatDialogRef} from "@angular/material/dialog";
import {
  DialogLinkInsuranceToVehicleComponent
} from "@pages/insurances/insurance-detail/dialog-link-insurance-to-vehicle/dialog-link-insurance-to-vehicle.component";
import {LinkInsuranceVehicleDataModel} from "@/models/rentals/link-insurance-vehicle-data-model";
import {RentalPracticeService} from "@services/data-source-tables/rentals/rental-practice.service";

@Component({
  selector: 'app-form-associate-insurance-to-vehicle',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class FormAssociateInsuranceToVehicleComponent extends DynamicFormComponent {
  @Input() selectedPractices: Array<RentalPracticeModel> = [];
  @Input() dialogRef: MatDialogRef<DialogLinkInsuranceToVehicleComponent>;
  @Input() insuranceId: number;
  private rentalPracticeList: RentalPracticeModel[] = [];

  constructor(protected userService: UserService, protected toastr: ToastrService, protected rentalInsuranceService: RentalInsuranceService, protected rentalPracticeService: RentalPracticeService) {
    super(toastr, userService)
    this.submitTopButton = false
    this.submitBottomButton = true
    this.submitButtonVisible = true
    this.primaryButton = `Associa Veicoli`
    this.secondaryBottomButton = `Annulla`

    this.setupMeta()

    this.rentalPracticeService.getRentalPracticeWithoutInsurance().subscribe({
      next: (response) => {
        this.rentalPracticeList = response;
        this.setupMeta();
      },
      error: (error) => {
        LoggerService.error('getRentalPracticeWithoutInsurance failed!');
        LoggerService.error(error);
      }
    });
  }

  setupMeta(): void {
    this.meta = AssociateInsuranceVehicleFormMeta
      .setRentalPracticeList(this.rentalPracticeList)
      .getMeta()
  }

  onSubmit() {
    this.submitButtonLoading = true;

    const linkData = this.form.getRawValue() as LinkInsuranceVehicleDataModel;
    const practiceId = linkData.rentalPractice.id;
    delete linkData.rentalPractice;
    
    this.rentalInsuranceService.linkVehicleToInsurance(practiceId, this.data.insuranceId, linkData).subscribe({
      next: (response) => {
        this.toastr.success('Associazione veicolo con assicurazione registrata!');

        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('linkVehicleToInsurance failed!');
        LoggerService.error(error);

        this.toastr.error('Associazione fallita!');

        setTimeout(() => {
          this.submitButtonLoading = false;
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          this.bottomButtonAction();
        }, 1000)
      },
    })
  }

  bottomButtonAction() {
    this.submitButtonLoading = false;
    this.dialogRef.close();
  }
}
