<div class="buttons-over-form mb-3">
  <app-back-button></app-back-button>
</div>
<div class="container-box mb-4 w-full">
  <h2 class="text-dark text-lg mx-1 my-2" mat-dialog-title>
    <fa-icon [icon]="faFile"></fa-icon>
    Dettaglio Pratica Noleggio - Pratica {{ data.tipoPratica }} Nr. {{ data.codicePratica }}
  </h2>
  <table class="ml-5 mb-3 w-full">
    <tr>
      <td>Targa: {{ data.targa ?? '-' }}</td>
      <td>Marca: {{ getMarca() ?? '-' }}</td>
      <td>Modello: {{ data.modello ?? '-' }}</td>
    </tr>
    <tr>
      <td>Stato Contratto: {{ data.statoContratto ?? '-' }}</td>
    </tr>
  </table>
</div>
<div class="container-box">
  <mat-tab-group *ngIf="tabs.length !== 0" animationDuration="0ms">
    <mat-tab *ngFor="let tab of tabs">
      <ng-template mat-tab-label>
                    <span *ngIf="tab.faIcon">
                        <fa-icon [icon]="tab.faIcon"></fa-icon>
                    </span>
        {{ tab.title }}
      </ng-template>
      <div [ngSwitch]="tab.title" class="content">
        <div *ngSwitchCase="'Anagrafica Noleggio VWL'">
          <app-rent-practice-form
            [data]="data"
          ></app-rent-practice-form>
        </div>
        <div *ngSwitchCase="'Noleggio in corso'">
          <div class="w-full flex pr-3">
            <app-button (btnClicked)="downloadFile()"
                        *ngIf="data?.activeInsurance?.file ?? false"
                        [color]="'download'"
                        [content]="'Scarica PDF Polizza'"
                        [faIcon]="faFilePdf"
                        [showIcon]="true"
                        class="text-right"></app-button>
            <app-button (btnClicked)="returnVehicle()"
                        *ngIf="this.data.activeRental"
                        [color]="'primary'"
                        [content]="'Riconsegna Veicolo'"
                        [faIcon]="iconFaUndo"
                        [showIcon]="true" class="mb-4 mr-2"></app-button>
            <app-button (btnClicked)="downloadPdfVehiclePickUp()"
                        *ngIf="this.data.activeRental"
                        [color]="'download'"
                        [content]="'Scarica PDF Consegna Veicolo'"
                        [faIcon]="iconPdf"
                        [loading]="loading" [showIcon]="true" class="mb-4 mr-2"></app-button>
            <app-button (btnClicked)="downloadPdfRentalB2b()"
                        *ngIf="this.data.activeRental && this.data.activeRental.canale === 'VENDITE'"
                        [color]="'download'"
                        [content]="'Scarica PDF Noleggio B2B'"
                        [faIcon]="iconPdf"
                        [loading]="loading" [showIcon]="true" class="mb-4"></app-button>
          </div>
          <app-rental-movement-form *ngIf="data?.activeRental"
                                    [data]="data?.activeRental"
                                    [practiceId]="id"
                                    [refreshRentalPracticeEvent$]="refreshRentalPracticeEvent$"
          ></app-rental-movement-form>
          <div *ngIf="!this.data.activeRental" class="w-full text-center">
            <h2>Nessun Noleggio in corso!</h2>
            <app-button (btnClicked)="newRentalMovement()"
                        [color]="'primary'"
                        [content]="'Nuovo Noleggio'"
                        [faIcon]="iconAddClient" [loading]="loading" class="mb-4"></app-button>
          </div>
        </div>
        <div *ngSwitchCase="'Movimenti noleggio'">
          <app-button (btnClicked)="downloadFile()"
                      *ngIf="data?.activeInsurance?.file ?? false"
                      [color]="'download'"
                      [content]="'Scarica PDF Polizza'"
                      [faIcon]="faFilePdf"
                      [showIcon]="true"
                      class="text-right"></app-button>
          <app-rental-movements-list
            [refreshRentalPracticeEvent$]="refreshRentalPracticeEvent$"
            [rentalPractice]="data"
            [rentalInProgress]="rentalInProgress"></app-rental-movements-list>
        </div>
        <div *ngSwitchCase="'Assicurazioni'">
          <app-rental-insurance-list></app-rental-insurance-list>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
