<div class="container-box relative">
  <div class="w-full block overflow-x-scroll example-container mat-elevation-z8">
    <div *ngIf="dataSource?.isLoading$ | async"
         class="absolute flex items-center justify-center inset-0 backdrop-blur-sm self-center justify-self-center">
      <mat-spinner></mat-spinner>
    </div>
    <table [dataSource]="dataSource" class="table-auto w-full border-collapse mat-elevation-z8" mat-table matSort>
      <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col.name">
        <th *matHeaderCellDef [disabled]="col?.unsortable" class="bg-[#3a3e5b] px-2" mat-header-cell
            mat-sort-header>
          <ng-container *ngIf="!['btnDetails', 'btnDelete', 'btnCustom'].includes(col.name)">
            <label class="uppercase whitespace-nowrap select-none m-0 text-left font-bold text-slate-200">
              {{ col.text }}
            </label>
          </ng-container>
        </th>
        <td *matCellDef="let row" class="px-2 text-slate-300 text-left bg-[#2e304a] whitespace-nowrap" mat-cell>
          <ng-container [ngTemplateOutletContext]="{row, col}"
                        [ngTemplateOutlet]="cellTypeTemplate"></ng-container>
        </td>
      </ng-container>
      <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col.name + 'Filtro'">
        <th *matHeaderCellDef class="relative z-10 bg-[#3a3e5b] px-2" mat-header-cell>
          <ng-container *ngIf="col?.filter?.type && filtersForm" [ngTemplateOutletContext]="{col}"
                        [ngTemplateOutlet]="filtersTemplate"></ng-container>
        </th>
      </ng-container>
      <ng-container *ngIf="newItemInlineForm">
        <ng-container matColumnDef="btnSubmitForm">
          <th *matHeaderCellDef class="bg-blue-900 px-2 text-slate-300 text-left" mat-header-cell>
            <button (click)="newItemFormSubmit.emit(newItemInlineForm.getRawValue())"
                    [ngClass]="{'cursor-not-allowed pointer-events-none opacity-50': newItemInlineForm.invalid}">
              <fa-icon [icon]="faCirclePlus"
                       class="!m-0 text-lg p-1 rounded-md bg-green-600 hover:bg-green-800 transition-colors"></fa-icon>
            </button>
          </th>
        </ng-container>
        <ng-container matColumnDef="btnClearForm">
          <th *matHeaderCellDef class="bg-blue-900 px-2 text-slate-300 text-left" mat-header-cell>
            <fa-icon (click)="newItemInlineForm.reset()" [icon]="faCircleMinus"
                     class="z-10 !m-0 text-lg p-1 rounded-md bg-gray-400 hover:bg-gray-600"
                     role="button"></fa-icon>
          </th>
        </ng-container>
        <ng-container *ngFor="let col of newItemInlineColumnsSetting" [matColumnDef]="col.name + 'FormField'">
          <th *matHeaderCellDef class="relative z-10 bg-[#3a3e5b] px-2" mat-header-cell>
            <ng-container *ngIf="col?.type"
                          [ngTemplateOutletContext]="{col, formControl: newItemInlineForm.get(col.name)}"
                          [ngTemplateOutlet]="dataFieldTemplate"></ng-container>
          </th>
        </ng-container>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns | columnsName:'name'; sticky: true" class="header-rows"
          mat-header-row></tr>
      <span *ngIf="!hideFilterHeaders">
              <tr *matHeaderRowDef="displayedColumns | columnsName: 'name':'filter'; sticky: true"
                  class="filter-rows"
                  mat-header-row></tr>
      </span>
      <ng-container *ngIf="newItemInlineForm">
        <tr *matHeaderRowDef="newItemInlineColumnsSetting | columnsName: 'name':'form'; sticky: true"
            class="relative z-10 inline-insert-data-header"
            mat-header-row></tr>
      </ng-container>
      <tr *matRowDef="let row; columns: displayedColumns | columnsName: 'name'" mat-row></tr>
    </table>
  </div>
  <!--  [length]="$any((dataSource.totalElements$ | async)) / paginator.pageSize | decimalInt:0:'UP'"-->
  <mat-paginator #paginator (page)="this.paginationChanging = true"
                 [length]="dataSource.totalElements$ | async"
                 [pageSizeOptions]="showPaginate ? [25, 50, 100, 250] : []"
                 [pageSize]="showPaginate ? 25 : 999"
                 aria-label="seleziona pagina"
                 class="relative z-10 mat-paginator-sticky"></mat-paginator>
</div>


<!-- TEMPLATES SECTION ⬇️ -->
<ng-template #cellTypeTemplate class="new-table" let-col="col" let-row="row">
  <ng-container *ngIf="editingRow !== row" [ngSwitch]="col.name">
    <app-button-details
      (btnClicked)="btnDetailsClicked(row)"
      *ngSwitchCase="'btnDetails'"
      [disabled]="dataSource.isLoading$ | async"

    >
    </app-button-details>
    <app-button
      (btnClicked)="customButtons[col.index].btnCustomClickEvent(row)"
      *ngIf="col.customButton"
      [additionalClass]="'px-2 button-custom'"
      [color]="customButtons[col.index].btnCustomColor ?? 'primary'"
      [disabled]="!col.condition(row)"
      [faIcon]="customButtons[col.index].btnCustomFaIcon"
      [showIcon]="customButtons[col.index].btnCustomShowIcon ?? false"
      [smallBtn]="true"
      [toolTip]="customButtons[col.index].btnCustomToolTip"
    >
      {{ customButtons[col.index].btnCustomText }}
    </app-button>
    <app-button-delete
      (btnClick)="btnDeleteClickEvent.emit(row)"
      *ngSwitchCase="'btnDelete'"
      [disabled]="dataSource.isLoading$ | async"
    >
    </app-button-delete>
    <ng-container
      *ngSwitchDefault
    >
      <ng-container *ngIf="col.anchorLink else simpleCol">
        <a [routerLink]="getFormattedString(col.anchorLink, row)"
           class="underline text-base">{{ row[col.name] }}</a>
      </ng-container>
      <ng-template #simpleCol>
        <ng-container
          *ngIf="col.type !== 'checkbox' &&
          col.type !== 'object' &&
          col.type !== 'date' &&
          col.type !== 'datetime' &&
          col.type !== 'slideToggle'
          && col.type !== 'currency'">
          {{ row[col.name] }}
        </ng-container>
        <ng-container *ngIf="col.type === 'slideToggle'">
          <mat-slide-toggle (change)="customActionSlideToggle.emit(row)" [(ngModel)]="row[col.name]"
                            class="w-full">
          </mat-slide-toggle>
        </ng-container>
        <ng-container *ngIf="col.type === 'object'">
          {{ row[col.name][col.propertyToShow] }}
        </ng-container>
        <ng-container *ngIf="col.type === 'checkbox'">
          {{ row[col.name] | parseCheckbox }}
        </ng-container>
        <ng-container *ngIf="col.type === 'date'">
          {{ row[col.name] || '' | date:'dd/MM/yyyy' }}
        </ng-container>
        <ng-container *ngIf="col.type === 'datetime'">
          {{ row[col.name] || '' | date:'dd/MM/yyyy HH:mm:ss' }}
        </ng-container>
        <ng-container *ngIf="col.type === 'currency'">
          {{ row[col.name] | currency: 'EUR' }}
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="editingRow === row && inlineForm">
    <div *ngIf="col.name === 'btnDetails'">
      <button (click)="editingRow = null">
        Annulla
      </button>
      <button
        (click)="editingRow = null; btnDoneFormEditClickEvent.emit(this.inlineForm.form.getRawValue())"
        mat-button
      >Salva
      </button>
    </div>
    <ng-container
      *ngIf="col.name !== 'btnDetails'"
      [ngTemplateOutletContext]="{col, formControl: inlineForm.form.get(col.name)}"
      [ngTemplateOutlet]="dataFieldTemplate"
    ></ng-container>
  </ng-container>
</ng-template>
<ng-template #dataFieldTemplate let-col="col" let-formControl="formControl">
  <ng-container *ngIf="col.type && col.name !== 'id'" [ngSwitch]="col.type">
    <mat-slide-toggle *ngSwitchCase="'checkbox'" [formControl]="formControl"></mat-slide-toggle>
    <input *ngSwitchCase="'hidden'" [formControl]="formControl" type="hidden"/>
    <mat-form-field *ngSwitchCase="'autocomplete'">
      <mat-label><span *ngIf="formControl.enabled">Inserire </span>{{ col.text }}</mat-label>
      <input [formControl]="formControl"
             [matAutocomplete]="auto"
             [ngClass]="{'cursor-not-allowed': isControlDisabled(formControl)}"
             [style.color]="formControl.disabled ? 'gray' : 'inherit'"
             matInput
             type="text"
      >
      <mat-autocomplete #auto="matAutocomplete" #autoComplete
                        [displayWith]="getControlMeta(col.name).displayFunction">
        <mat-option *ngFor="let option of getControlMeta(col.name).options" [value]="option">
          {{ getControlMeta(col.name).displayFunction(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field
      *ngSwitchDefault
      [ngClass]="getControlMeta(col.name).class"
      class="text-white focus-within:w-full focus-within:max-w-full"
    >
      <input
        [formControl]="formControl"
        [ngClass]="{'cursor-not-allowed': isControlDisabled(formControl)}"
        [placeholder]="formControl.enabled ? 'Inserire ' + col.text : col.text"
        [style.color]="formControl.disabled ? 'gray' : 'inherit'"
        [type]="col.type"
        matInput
      />
    </mat-form-field>
  </ng-container>
</ng-template>
<ng-template #filtersTemplate let-col='col'>
  <ng-container [ngSwitch]="col.filter.type">

    <mat-form-field *ngSwitchCase="'date'" appearance="fill" class="date-input">
      <mat-label>Scegli una data</mat-label>
      <input [formControl]="$any(filtersForm.get(col.name))" [matDatepicker]="picker" matInput
             placeholder="DD/MM/YYYY">
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'dateRange'" appearance="fill" class="date-input">
      <mat-label>Da - A</mat-label>
      <mat-date-range-input [formGroup]="filtersForm" [rangePicker]="picker" class="mr-1">
        <input [formControl]="$any(filtersForm.get(col.name + 'From'))" matStartDate placeholder="Da">
        <input [formControl]="$any(filtersForm.get(col.name+ 'To'))" matEndDate placeholder="A">
      </mat-date-range-input>
      <fa-icon (click)="clearDate($any(filtersForm.get(col.name + 'From')), $any(filtersForm.get(col.name+ 'To')))"
               [icon]="faXmark"
               class="!m-0 text-lg absolute"></fa-icon>
      <mat-datepicker-toggle [for]="picker" class="mat-da" matIconSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <select
      *ngSwitchCase="'select'"
      [formControl]="$any(filtersForm.get(col.name))"
      class="bg-gray-50 border text-sm rounded-lg block w-full p-2.5 !bg-dark border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
    >
      <option selected value="">seleziona</option>
      <option *ngFor="let op of col.filter.options | async" [value]="getSelectOption('KEY', op)">
        {{ getSelectOption('VALUE', op) }}
      </option>
    </select>

    <input
      *ngSwitchCase="'input'"
      [formControl]="$any(filtersForm.get(col.name))"
      [placeholder]="col.text"
      class="bg-gray-50 border text-sm rounded-lg block w-full p-2.5 !bg-dark border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
    >

    <input *ngSwitchCase="'number'"
           [formControl]="$any(filtersForm.get(col.name))"
           [placeholder]="col.text"
           class="bg-gray-50 border text-sm rounded-lg block w-full p-2.5 !bg-dark border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
           type="number"
    >

  </ng-container>
</ng-template>
<!-- TEMPLATES SECTION ⬆️ -->
