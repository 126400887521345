import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {FormSetting} from "@/form-settings/form-setting";

export class CreateCashFoundFormMeta extends FormSetting {
  private static rows: ControlBase[] = [];

  public static getMeta(): Array<any> {
    this.rows = [];

    this.addRowData();
    this.addRowImportoAssegni();
    this.addRowImportoContanti();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowData() {
    const dataComponent = {} as ControlBase;
    dataComponent.classToUse = this.classToCall;
    dataComponent.key = 'data';
    dataComponent.controlType = ControlType.date;
    dataComponent.label = 'Data';
    dataComponent.class = "w-4/12";
    dataComponent.disabled = true;

    this.rows.push(dataComponent);
  }

  private static addRowImportoAssegni() {
    const importoAssegniComponent = {} as ControlBase;
    importoAssegniComponent.classToUse = this.classToCall;
    importoAssegniComponent.key = 'importoAssegni';
    importoAssegniComponent.controlType = ControlType.number;
    importoAssegniComponent.label = 'Importo Assegni';
    importoAssegniComponent.class = "w-4/12";

    this.rows.push(importoAssegniComponent);
  }

  private static addRowImportoContanti() {
    const importoContantiComponent = {} as ControlBase;
    importoContantiComponent.classToUse = this.classToCall;
    importoContantiComponent.key = 'importoContanti';
    importoContantiComponent.controlType = ControlType.number;
    importoContantiComponent.label = 'Importo Contanti';
    importoContantiComponent.class = "w-4/12";

    this.rows.push(importoContantiComponent);
  }

}
