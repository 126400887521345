import {Component} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";

@Component({
  selector: 'app-auto-complete-model',
  templateUrl: '../../auto-complete.component.html',
  styleUrls: ['../../auto-complete.component.scss']
})
export class AutoCompleteModelComponent extends AutoCompleteComponent {

  displayFn(selectedOption: any): string {
    return selectedOption && selectedOption.descrizioneModello ?
      selectedOption.descrizioneModello :
      '';
  }

  protected getValue(obj: any) {
    return obj?.descrizioneModello ?? '';
  }
}
