import {Component} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";
import {RentalPracticeModel} from "@/models/rental-practice.model";

@Component({
  selector: 'app-auto-complete-rental-practice',
  templateUrl: '../../auto-complete.component.html',
  styleUrls: ['../../auto-complete.component.scss']
})
export class AutoCompleteRentalPracticeComponent extends AutoCompleteComponent {
  protected getValue(selectedOption: RentalPracticeModel): string {
    return selectedOption ?
      `${selectedOption.codicePratica} - ${selectedOption.targa} - ${selectedOption.modello}` :
      '';
  }
}
