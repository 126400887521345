<div class="container-box">
  <app-table-header (btnUpdateClick)="reloadTrigger$.next()" tableTitle="Gestione V.A. & Noleggi">
  </app-table-header>
  <div>
    <div class="table-container">
      <app-new-table
        (btnDeleteClickEvent)="btnDeleteFunction($event)"
        (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns"
        [initialSort]="{
              active: 'id',
              direction: 'desc'
            }"
        [reloadTrigger$]="reloadTrigger$"
      ></app-new-table>
    </div>
  </div>
</div>

