import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {FormSetting} from "@/form-settings/form-setting";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {AutocompleteControl} from "@components/dynamic-form/auto-complete/autocomplete-control";

export class AssociateInsuranceVehicleFormMeta extends FormSetting {
  private static rows: ControlBase[] = [];
  private static reentalPracticeList: RentalPracticeModel[];

  public static getMeta(): Array<any> {
    this.rows = [];

    this.addRowRentalPractice();
    this.addRowNumApplicazione();
    this.addRowDataInizio();
    this.addRowDataFine();
    this.addRowPremioNetto();
    this.addRowStato();

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowNumApplicazione() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'numApplicazione';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Num. applicazione';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowDataInizio() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataInizio';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Inizio';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowDataFine() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataFine';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Fine';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowPremioNetto() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'premioNetto';
    inputsComponent.controlType = ControlType.number;
    inputsComponent.label = 'Premio Netto';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  private static addRowStato() {
    const inputsComponent = {} as DropdownControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'stato';
    inputsComponent.controlType = ControlType.dropdown;
    inputsComponent.label = 'Stato';
    inputsComponent.options = [
      {key: 'ATTIVA', value: 'ATTIVA'},
      {key: 'SOSPESA', value: 'SOSPESA'},
      {key: 'SCADUTA', value: 'SCADUTA'}
    ];
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;

    this.rows.push(inputsComponent);
  }

  static setRentalPracticeList(rentalPracticeList: RentalPracticeModel[]) {
    this.reentalPracticeList = rentalPracticeList;

    return this;
  }

  private static addRowRentalPractice() {
    const inputsComponent = {} as AutocompleteControl;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'rentalPractice';
    inputsComponent.controlType = ControlType.autocompleteRentalPractice;
    inputsComponent.label = 'Pratica Noleggio';
    inputsComponent.class = "w-4/12";
    inputsComponent.required = true;
    inputsComponent.options = this.reentalPracticeList;

    this.rows.push(inputsComponent);
  }
}
