import {Component} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";

@Component({
  selector: 'app-auto-complete-type-insurance-service',
  templateUrl: '../../auto-complete.component.html',
  styleUrls: ['../../auto-complete.component.scss']
})
export class AutoCompleteTypeInsuranceServiceComponent extends AutoCompleteComponent {

  protected getValue(obj: any) {
    return obj?.descrizione ?? '';
  }

  displayFn(selectedoption: any): string {
    return selectedoption && selectedoption.descrizione ? selectedoption.descrizione : '';
  }

}
