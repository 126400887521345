<div class="container-box">
  <div class="flex">
    <div class="dynamic-form m-4 lg:w-3/12 w-10/12">
      <app-date-picker [meta]="datePickerData" [triggerDate$]="triggerDate$"></app-date-picker>
    </div>
    <div class="m-4 lg:w-9/12 w-10/12 flex items-center justify-end">
      <app-button-recalculate-progression-month [reloadTrigger$]="reloadTrigger$" [triggerDate$]="triggerDate$"
                                                class="mr-3">
      </app-button-recalculate-progression-month>
      <app-button-export-excel-delivery-card-board
        [triggerDate$]="triggerDate$">
      </app-button-export-excel-delivery-card-board>
    </div>
  </div>
  <app-table-delivery-carton [triggerDate$]="triggerDate$"></app-table-delivery-carton>
  <app-table-header (btnUpdateClick)="btnUpdateClick()" tableTitle="Numeratori consegne">
  </app-table-header>
  <div class="table-container">
    <app-new-table
      (btnDeleteClickEvent)="btnDeleteClick($event)"
      (btnDetailsClickEvent)="btnDetailsClick($event)"
      (btnDoneFormEditClickEvent)="storeCardboard($event)"
      (customActionSlideToggle)="changeTransmission($event)"
      (newItemFormSubmit)="storeCardboard($event)"
      [additionalFilters]="{
          anno: yearFilter,
          mese: monthFilter
      }"
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [initialSort]="{
        active: 'dataConsegna',
        direction: 'desc'
      }"
      [inlineForm]="{form, meta}"
      [newItemInlineColumnsSetting]="newItemInlineColumnsSetting"
      [newItemInlineForm]="newItemForm"
      [reloadTrigger$]="reloadTrigger$"
      [triggerSelectorBrand$]="triggerDate$"
    ></app-new-table>
  </div>
</div>
