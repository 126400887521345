import {ApiService} from "@services/api.service";
import {TableDataApiParams} from "@services/practices.service";
import {Observable, throwError} from "rxjs";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {catchError, map, tap} from "rxjs/operators";
import {InsuranceModel} from "@/models/insurances/insurance.model";
import {Injectable} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {AssociationsInsuranceRentPracticeModel} from "@/models/insurances/associations-insurance-rent-practice-model";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class InsuranceService extends ApiService {
  getDataForTable(params?: TableDataApiParams): Observable<TablePaginationRespModel<InsuranceModel>> {
    return new Observable<TablePaginationRespModel<InsuranceModel>>(observer => {
      this.http.get<TablePaginationRespModel<InsuranceModel>>(
        this.baseUrl + '/insurances/page',
        {params: {...params}})
        .pipe(
          tap(response => {
            observer.next(response);
            observer.complete();
          }),
          catchError(error => {
            observer.error(error);

            return throwError(error);
          })
        ).subscribe();
    });
  }

  deleteInsurance(id: number) {
    return this.http.delete(this.baseUrl + `/insurances/${id}/`).pipe(map((response: any) => {
      LoggerService.log(response, 'deleteInsurance')
      return response;
    }));
  }

  saveInsurance(insuranceModel: InsuranceModel) {
    return this.http.post(this.baseUrl + `/insurances/save`, insuranceModel).pipe(map((response: any) => {
      LoggerService.log(response, 'saveInsurance')
      return response;
    }));
  }

  getInsuranceById(id: number) {
    return this.http.get(this.baseUrl + `/insurances/${id}/detail`).pipe(map((response: any) => {
      LoggerService.log(response, 'getInsuranceById')

      return response;
    }));
  }

  getDataTableAssociations(insuranceId: number): Observable<AssociationsInsuranceRentPracticeModel[]> {
    return this.http.get(this.baseUrl + `/insurances/${insuranceId}/rental-practices`).pipe(map((response: any) => {
      LoggerService.log(response, 'getInsuranceById')

      return response;
    }));
  }

  uploadInsuranceFile(document: FormData) {
    return this.http.post(this.baseUrl + '/insurances/uploadFile', document).pipe(map((response: any) => {
      LoggerService.log(response, 'uploadInsuranceFile');
      return response;
    }));

  }

  downloadInsuranceFile(id: number) {
    return this.http.get(this.baseUrl + `/insurances/${id}/document/download`, {
      headers: new HttpHeaders({'Content-Type': 'application/octet-stream'}),
      responseType: 'blob'
    });
  }
}
