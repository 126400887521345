import {Component} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";
import {PaymentTypeModel} from "@/models/tables/payment-type.model";

@Component({
  selector: 'app-auto-complete-payment-type',
  templateUrl: '../../auto-complete.component.html',
  styleUrls: ['../../auto-complete.component.scss']
})
export class AutoCompletePaymentTypeComponent extends AutoCompleteComponent {

  displayFn(selectedOption: any): string {
    return selectedOption && selectedOption.descrizione ? selectedOption.descrizione : '';
  }

  protected getValue(obj: PaymentTypeModel) {
    return obj?.descrizione ?? '';
  }
}
