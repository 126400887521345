import {Component, Input, OnInit} from '@angular/core';
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {RepeaterControl} from "@components/dynamic-form/repeater-inputs/repeater-control";
import {Registry} from "@/registry.service";
import {Subject} from "rxjs";
import {ControlType} from "@components/dynamic-form/control-type";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {LoggerService} from "@services/logger.service";

@Component({
  selector: 'app-repeater-inputs',
  templateUrl: './repeater-inputs.component.html',
  styleUrls: ['./repeater-inputs.component.scss']
})
export class RepeaterInputsComponent extends InputFieldComponent implements OnInit {
  @Input() meta!: RepeaterControl
  @Input() form!: FormGroup
  @Input() triggerMethodSubject!: Subject<any>
  indexRow: number
  deleteButtons: { index: number, end: number, start: number }[] = [];
  rows = <any>[];
  indexControl: number
  protected readonly ControlType = ControlType;
  protected readonly faTrashCan = faTrashCan;

  constructor() {
    super()
  }

  get controlArray() {
    return this.form.controls[this.meta.key] as FormArray;
  }

  ngOnInit(): void {
    this.indexRow = -1
    this.indexControl = 0

    this.triggerMethodSubject.subscribe({
      next: (obj) => {
        if (obj.key === 'multipleFiles') {
          this[obj.fun]()
        }
      }
    });

    if (this.controlArray.controls.length > 0) {
      this.addRowsBaseOnControl()
    }
  }

  protected addRowsBaseOnControl() {
    const rowControls = [];
    const start = this.meta.repeaterRows.length
    let row = {}

    for (const i in this.controlArray.controls) {
      this.indexRow++

      const formGroupRow = this.controlArray.controls[i] as FormArray;

      for (const key in formGroupRow.controls) {
        for (const rowMeta of this.meta.metaArray[0].rows) {
          if (rowMeta.key === key) {
            row = rowMeta
          }
        }

        this.meta.repeaterRows.push({'row': row, 'index': this.indexRow});
      }

      rowControls.push({index: this.indexRow})

      this.indexControl++

      const end = this.meta.repeaterRows.length

      this.rows.push(rowControls)
      this.deleteButtons.push({index: this.indexRow, 'end': end, 'start': start})
    }
  }

  protected addNewRowsBaseOnControl() {
    this.indexRow++

    const rowControls = [];
    const start = this.meta.repeaterRows.length
    let row = {}
    const lastRow = this.controlArray.controls.length - 1
    const formGroupRow = this.controlArray.controls[lastRow] as FormArray;

    for (const key in formGroupRow.controls) {
      for (const rowMeta of this.meta.metaArray[0].rows) {
        if (rowMeta.key === key) {
          row = rowMeta
        }
      }

      this.meta.repeaterRows.push({'row': row, 'index': this.indexRow});
    }

    rowControls.push({index: this.indexRow})

    this.indexControl++

    const end = this.meta.repeaterRows.length

    this.rows.push(rowControls)
    this.deleteButtons.push({index: this.indexRow, 'end': end, 'start': start})
  }

  protected addNewRow() {
    LoggerService.log('Add New Row!');
    this.addNewControlToArray()
    this.addNewRowsBaseOnControl()
  }

  protected deleteRow(indexRow, start, end) {
    const keyMainRow = this.meta.key;

    this.controlArray.removeAt(indexRow);

    this.meta.repeaterRows = this.meta.repeaterRows.filter(item => item.index !== indexRow);
    this.deleteButtons = this.deleteButtons.filter(item => item.index !== indexRow);
    this.rows = this.rows.filter(subArray =>
      !subArray.some(item => item.index === indexRow)
    );

    const elements = document.getElementsByClassName(`row-${keyMainRow}-${indexRow}`)

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0])
    }

    if (this.meta.formClass) {
      const instanceForm = Registry.getInstance().get(this.meta.formClass)

      if (instanceForm && this.meta.deleteAction !== undefined) {
        instanceForm.setupMeta()
        instanceForm[this.meta.deleteAction](null, indexRow)
      }
    }

    this.indexRow--
  }

  private addNewControlToArray() {
    const metaArray = this.meta.metaArray;
    this.controlArray.push(this.toFormGroup(metaArray));
  }

  private toFormGroup(meta) {
    return this.createFormGroup(meta);
  }

  private createFormGroup(meta: Array<any>,) {
    const formGroup = new FormGroup({});

    for (let i = 0; i < meta.length; i++) {
      meta[i].rows.forEach((control) => {
        let formControlOptions = []
        let controlKey = control.key
        let disabled = false;

        if (control.required) {
          formControlOptions.push(Validators.required)
        }

        if (control.disabled) {
          disabled = true
        }

        if (control.additionalValidators) {
          control.additionalValidators.forEach((option) => {
            formControlOptions.push(option)
          })
        }

        formGroup.addControl(controlKey, new FormControl({
          value: null,
          disabled: disabled
        }, formControlOptions));
      })
    }

    return formGroup;
  }
}
