import {Component} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";
import {BankPaymentModel} from "@/models/cash-movements.model";

@Component({
  selector: 'app-auto-complete-bank-payment',
  templateUrl: '../../auto-complete.component.html',
  styleUrls: ['../../auto-complete.component.scss']
})
export class AutoCompleteBankPaymentComponent extends AutoCompleteComponent {
  faIcon: any;
  
  getValue(bank: BankPaymentModel): string {
    return bank && bank.descrizione && bank.contoContabile ? `${bank.contoContabile} - ${bank.descrizione}` : ''
  }

}
