import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {
  RentalMovementFormComponent
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/form/rental-movement-form.component";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {
  DataDialogRentalMovement
} from "@pages/rental-management/rent-practice-detail/rental-movements-list/dialog-form-rental-movement/data-dialog-rental-movement";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";
import {saveAs} from "file-saver";
import {RentalMovementService} from "@services/data-source-tables/rentals/rental-movement.service";

@Component({
  selector: 'app-dialog-form-rental-movement',
  templateUrl: './dialog-form-rental-movement.component.html',
})
export class DialogFormRentalMovementComponent {
  @ViewChild(RentalMovementFormComponent) formComponent!: DynamicFormComponent;
  protected loading: boolean = false;
  protected movement: RentalMovementModel;
  protected isNew: boolean = false;
  private rentalMovementService: RentalMovementService;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DataDialogRentalMovement) {
    this.rentalMovementService = data.rentalMovementService;
    this.movement = data.movement;
    this.isNew = data.isNew;
  }

  downloadPdfVehiclePickUp() {
    this.loading = true

    this.rentalMovementService.downloadPdfVehiclePickUp(this.movement.id).subscribe({
      next: (res) => {
        saveAs(res, 'ritiro-veicolo-' + this.movement.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  downloadPdfVehicleDropOff() {
    this.loading = true

    this.rentalMovementService.downloadPdfVehicleDropOff(this.movement.id).subscribe({
      next: (res) => {
        saveAs(res, 'riconsegna-veicolo-' + this.movement.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  downloadPdfRentalB2b() {
    this.loading = true

    this.rentalMovementService.downloadPdfRentalB2b(this.movement.id).subscribe({
      next: (res) => {
        saveAs(res, 'contratto-noleggio-' + this.movement.id + '.pdf');
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  protected readonly iconPdf = faFilePdf;
}
