import {Component} from '@angular/core';
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {ButtonComponent} from "@components/button/button.component";

@Component({
  selector: 'app-button-details',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss']
})
export class ButtonDetailsComponent extends ButtonComponent {
  faIcon = faPenToSquare;
  showIcon = true;
  additionalClass = 'buttons-action-table py-2 px-1';
  smallBtn = true;
}

