<div>
  <div class="buttons-over-form mb-3">
    <app-back-button></app-back-button>
    <button (click)="downloadPracticePdf()"
            *ngIf="practiceIsMatched()"
            class="btn btn-secondary ml-0 w-full mb-3 md:w-auto"
            id="btn-practice-download">
      <fa-icon [icon]="iconDownloadPdf"></fa-icon>
      {{ downloadPdfText }}
    </button>
    <button (click)="downloadSummaryCVPdf()"
            *ngIf="!isUserASubSgent() && practiceIsMatched()"
            class="btn btn-secondary ml-3 w-full mb-3 md:w-auto"
            id="btn-practice-resume">
      <fa-icon [icon]="iconDownloadPdf"></fa-icon>
      PDF Riepilogo C.V.
    </button>
    <button (click)="downloadDetailVeicoloPdf()"
            class="btn btn-secondary ml-3 w-full mb-3 md:w-auto"
            id="btn-vehicle-resume">
      <fa-icon [icon]="iconDownloadPdf"></fa-icon>
      PDF Dettaglio Veicolo
    </button>
    <button (click)="matchedVehicle()"
            *ngIf="!matchingVehicle && !practiceIsMatched()"
            class="btn btn-secondary w-full ml-3 mb-3 md:w-auto"
            id="btn-matched-veichle">
      <fa-icon [icon]="faPuzzlePiece"></fa-icon>
      Abbina veicolo
    </button>
    <button (click)="migrateDataPractice()"
            *ngIf="data.statoPratica === 'GESTITA'"
            class="btn btn-secondary w-full ml-3 mb-3 md:w-auto"
            id="btn-migrate-data">
      <fa-icon [icon]="faArrowRightArrowLeft"></fa-icon>
      Migrazione dati pratica
    </button>
    <button (click)="createRentablePractice()" *ngIf="data.noleggiabile && !data.praticaNoleggioId"
            class="btn btn-primary mb-3 float-right">
      Crea Pratica di Noleggio
    </button>
    <!--
    <button *ngIf="data.tipoPratica === 'NUOVO'"
              class="btn btn-secondary float-none ml-0 w-full mb-3 md:ml-3 md:w-auto md:float-right"
              id="btn-practice-duplicate"
              (click)="duplicatePractice()">
          <fa-icon [icon]="iconDuplicate"></fa-icon>
          Duplica Pratica
      </button>
      -->
  </div>
  <div class="container-box mb-4 w-full">
    <h2 class="text-dark text-lg mx-1 my-2" mat-dialog-title>
      <fa-icon [icon]="faFile"></fa-icon>
      Dettaglio Pratica {{ data.tipoPratica }} - Nr. Pratica {{ data.codicePratica }}
    </h2>
    <table class="ml-5 mb-3 w-full">
      <tr>
        <td>CL prop. d'acq.: {{ data.cliente ?? '-' }}</td>
        <td>Nr BP Proprietario prop. d'acq.: {{ data.nrBpProprPropAcq ?? '-' }}</td>
        <td>Proprietario prop. d'acq.: {{ data.descrProprPropAcq ?? '-' }}</td>
      </tr>
      <tr>
        <td>Codice venditore: {{ data.venditore?.codiceVenditore ?? '-' }}</td>
        <td>Descrizione venditore: {{ data.venditore?.descrizione ?? '-' }}</td>
      </tr>
      <tr>
        <td>Telaio: {{ data.telaio ?? '-' }}</td>
        <td>Targa: {{ data.targa ?? '-' }}</td>
        <td>Data Immatricolazione: {{ data.dataPrimaImmatricolazione ?? '-' }}</td>
      </tr>
      <tr>
        <td>Codice BP (Business Partner): {{ data.codiceClienteContabilita ?? '-' }}</td>
        <td>Codice Contabilità cliente: {{ data.codiceContabilitaCliente ?? '-' }}</td>
        <td>Codice Contabilità proprietario: {{ data.codiceContabilitaProprietario ?? '-' }}</td>
      </tr>
      <tr>
        <td>Codice Contabilità fornitore: {{ data.codiceContabilitaFornitore ?? '-' }}</td>
        <td *ngIf="data.numeroPraticaVenditaAutoAziendale">Numero pratica
          USATO: {{ data.numeroPraticaVenditaAutoAziendale }}
        </td>
      </tr>
    </table>
  </div>
  <div class="container-box">
    <mat-tab-group *ngIf="tabs.length !== 0" animationDuration="0ms">
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label>
                    <span *ngIf="tab.faIcon">
                        <fa-icon [icon]="tab.faIcon"></fa-icon>
                    </span>
          {{ tab.title }}
        </ng-template>
        <div [ngSwitch]="tab.title" class="content">
          <app-detail-practice-form *ngSwitchCase="'Dettaglio pratica'"
                                    [data]="data"
                                    [eventUpdateProvvigioneAccessori]="eventUpdateProvvigioneAccessori"
          ></app-detail-practice-form>
          <div *ngSwitchCase="'Dettaglio veicolo'">
            <app-data-vehicle-not-matched-form [data]="data"></app-data-vehicle-not-matched-form>
            <app-detail-practice-vehicle-form
              [data]="data"></app-detail-practice-vehicle-form>
            <h2>Equipaggiamenti</h2>
            <br>
            <ng2-smart-table [settings]="settingsEquipments" [source]="sourceEquipmentsTable" class="table-content"
                             id="EquipmentsTable"></ng2-smart-table>
          </div>
          <div *ngSwitchCase="'Permute'">
            <div *ngIf="data.venditore === null"
                 class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-2" role="alert">
              <strong class="font-bold">Alert!</strong>
              <span class="block sm:inline"> Attenzione venditore mancante nella pratica.</span>
            </div>
            <app-button (btnClicked)="newExchange()"
                        [additionalClass]="'mb-4'"
                        [content]="newExchangeText"
                        [disabled]="data.venditore === null"
            ></app-button>
            <ng2-smart-table [settings]="settingsExchange" [source]="sourceExchangesTable" class="table-content"
                             id="exchangesTable"></ng2-smart-table>
          </div>
          <div *ngSwitchCase="'Documenti'">
            <button (click)="uploadDocument()" class="btn btn-primary mb-3">
              {{ uploadDocumentText }}
            </button>
            <button (click)="uploadMultipleDocument()" class="btn btn-primary mb-3 ml-2">
              {{ uploadMultipleDocumentsText }}
            </button>
            <button (click)="completeDocument()" *ngIf="data.statoDocumentiPratica === 'INCOMPLETO'"
                    class="btn btn-success mb-3 ml-3">
              <fa-icon [icon]="iconCompleteDocument"></fa-icon>
              {{ completeDocumentText }}
            </button>
            <ng2-smart-table [settings]="settingsDocuments" [source]="sourceDocumentsTable" class="table-content"
                             id="documentsTable"></ng2-smart-table>
          </div>
          <div *ngSwitchCase="'Accessori'">
            <div *ngIf="modelSelected">
              <button (click)="newAccessory()" class="btn btn-primary mb-3">Aggiungi accessorio</button>
              <ng2-smart-table [settings]="settingsAccessories" [source]="sourceAccessoriesTable"
                               class="table-content"
                               id="accessoriesListTable"></ng2-smart-table>
            </div>
            <h2 *ngIf="!modelSelected">Attenzione, per visualizzare questa sezione è obbligatorio popolare
              il campo
              Modello nel dettaglio pratica.</h2>
          </div>
          <div *ngSwitchCase="'Checklist documenti'">
            <ng2-smart-table [settings]="settingsChecklistDocuments" [source]="sourceChecklistDocumentsTable"
                             class="table-content"
                             id="checklistDocumentsTable"></ng2-smart-table>
          </div>
          <div *ngSwitchCase="'Comunicazioni subagenti'">
            <div> <!-- TODO FIX IT -->
              <button (click)="newCommunication()" class="btn btn-primary mb-3">Aggiungi comunicazione
              </button>
              <ng2-smart-table [settings]="settingsCommunications" [source]="sourceCommunicationsTable"
                               class="table-content"
                               id="communicationListTable"></ng2-smart-table>
            </div>
          </div>
          <app-accounting-tab-component *ngSwitchCase="'Dati contabili'"
                                        [formData]="data"></app-accounting-tab-component>
          <app-financial-data-analysis *ngSwitchCase="'Analisi dati finanziari'"
                                       [practiceData]="data"></app-financial-data-analysis>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
