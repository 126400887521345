<div class="buttons-over-form mb-3">
  <app-back-button></app-back-button>
</div>
<div class="container-box">
  <app-table-header [showUpdateButton]="false"
                    tableTitle="Gestione polizza assicurativa - TIPO {{ data?.tipoAssicurazione}} - N. {{ data?.numeroPolizza }}">
  </app-table-header>
  <mat-tab-group animationDuration="0ms">
    <mat-tab *ngFor="let tab of tabs">
      <ng-template mat-tab-label>
        <fa-icon [icon]="faIcon"></fa-icon>
        {{ tab.title }}
      </ng-template>
      <div *ngIf="tab.title === 'Dettaglio'" class="content flex flex-wrap">
        <app-button (btnClicked)="uploadFile()"
                    *ngIf="showButtonUploadFile()"
                    [color]="'primary'"
                    [content]="'Allega PDF Polizza'"
                    [faIcon]="faFilePdf"
                    [showIcon]="true" class="text-right mr-3"></app-button>
        <app-button (btnClicked)="downloadFile()"
                    *ngIf="data.file"
                    [color]="'download'"
                    [content]="'Scarica PDF Polizza'"
                    [faIcon]="faFilePdf"
                    [showIcon]="true"
                    class="text-right"></app-button>
        <app-insurance-form
          [data]="data"
        ></app-insurance-form>
      </div>
      <div *ngIf="tab.title === 'Veicoli Assicurati'"
           class="table-container">
        <div class="content flex justify-between">
          <h2 class="m-2 w-2/3">Lista Veicoli Assicurati</h2>
          <app-button (btnClicked)="linkInsurance()"
                      *ngIf="showButtonNewAssociation()"
                      [color]="'primary'"
                      [content]="'Associa veicolo'"
                      [faIcon]="faPlus"
                      [showIcon]="true"
                      [toolTip]="'Associa veicolo da assicurare'" class="w-1/3 text-right"></app-button>
        </div>
        <app-new-table *ngIf="dataSource !== null"
                       [customButtons]="customButtons"
                       [dataSource]="dataSource"
                       [displayedColumns]="displayedColumns"
                       [initialSort]="{active: 'id',direction: 'desc'}"
                       [reloadTrigger$]="reloadTrigger$"
                       [showElementsPerPage]="false"
                       [showPaginate]="false"
        ></app-new-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
