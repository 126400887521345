export class CashMovementsModel {
  public id: number;
  public reparto: [];
  public dataMovimento: Date;
  public tipoMovimento: string;
  public codice: string;
  public descrizioneMovimento: string;
  public importo: number;
  public tipoPagamento: string;
  public banca: string;
  public numeroAssegno: string;
  public abbuono: number;
  public targa: string;
  public telaio: string;
  public fatturaRicevuta: string;
  public note: string;
  public enabled: boolean;
  public numeroPratica: string;
  public numeroContrattoFinanziamento: string;
  public ordineLavoro: string;
  public subordineLavoro: string;
  public creator: string;
  public created: Date;
  public modifier: string;
  public modified: Date;
}
