<div [formGroup]="form">
  <div *ngIf="indexRow !== null" [formArrayName]="keyformArrayName">
    <div [formGroupName]="indexRow"
         [ngClass]="meta.disabled ? 'disabled-dropdown' : 'active'">
      <mat-label class="label-form">{{ meta.label }}:</mat-label>
      <mat-form-field class="w-full additional-border-input mt-1" appearance="fill"
                      [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
        <mat-label>{{ meta.label }}</mat-label>
        <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"
               (change)="customActionChange($event)">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getValue" autoActiveFirstOption
                          (optionSelected)="customActionInput($event, indexRow)">
          <mat-option *ngFor="let obj of filteredOptions | async" [value]="obj">
            {{ getValue(obj) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="indexRow === null" [ngClass]="meta.disabled ? 'disabled-dropdown' : 'active'">
    <mat-label class="label-form">{{ meta.label }}:</mat-label>
    <mat-form-field class="w-full additional-border-input mt-1" appearance="fill"
                    [ngClass]="control.invalid && submitted ? 'invalid-field' : ''">
      <mat-label>{{ meta.label }}</mat-label>
      <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"
             (change)="customActionChange($event)">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="getValue" autoActiveFirstOption
                        (optionSelected)="customActionInput($event)">
        <mat-option *ngFor="let obj of filteredOptions | async" [value]="obj">
          {{ getValue(obj) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <app-box-errors [meta]="meta" [control]="control" [submitted]="submitted"></app-box-errors>
</div>
